<template>
  <div data-app>
    <v-app-bar style="background-color: #53513b" dense dark>
      <v-btn icon @click="$router.push('/').catch(() => {})">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>Voltar</v-toolbar-title>
    </v-app-bar>
    <v-form
      style="display: inline-block; margin-top: 120px; width: 300px; border: 1px solid #b6a1d1; padding: 20px; border-radius: 20px"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-container>
        <v-img
          width="100"
          :src="require(`@/assets/logo.png`)"
          style="display: inline-block; margin-bottom: 0px;"
        ></v-img>

        <v-row class="rows-form">
          <v-col class="rows-form">
            <v-text-field
              v-model="form.DsSenha"
              :rules="senhaRules"
              label="Nova senha"
              type="password"
              required
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.confirmaSenha"
              :rules="confirmaSenhaRules"
              label="Confirma senha"
              type="password"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          :disabled="!valid"
          style="background-color: #b6a1d1; color: white; width: 100%"
          @click="submit"
        >
          Enviar
        </v-btn>
      </v-container>
    </v-form>

    <notifications group="user" />
  </div>
</template>

<script>
import axios from "@/services/api";
export default {
  components: {},

  data() {
    return {
      valid: true,

      form: {
        DsChave: "",
        DsSenha: "",
        confirmaSenha: ""
      },

      senhaRules: [
        v => !!v || "Senha é obrigatório",
        v => (v && v.length >= 6) || "Senha deve ser maior que 6 caracteres"
      ],

      confirmaSenhaRules: [
        v => !!v || "Confirmação da senha é obrigatório",
        v =>
          (v && v.length >= 2) ||
          "Confirmação da senha deve conter 6 caracteres"
      ]
    };
  },

  props: {
    DsChave: { type: String, required: true }
  },

  methods: {
    close() {
      this.$emit("CLOSE");
    },

    validarSenha() {
      if (this.form.DsSenha !== this.form.confirmaSenha) {
        this.$notify({
          type: "error",
          duration: 1000,
          speed: 1000,
          group: "user",
          title:
            '<p style="font-size: 14pt">' +
            this.$store.state.aplicationName +
            "</p>",
          text:
            '<p style="font-size: 12pt">As senhas digitadas estão divergentes</p>'
        });

        return false;
      } else {
        return true;
      }
    },

    submit() {
      if (this.validarSenha()) {
        this.form.DsChave = this.DsChave;
        if (this.$refs.form.validate()) {
          axios
            .post("usuario/alterarsenha", this.form)
            .then(resp => {
              this.$notify({
                type: "success",
                duration: 1000,
                speed: 1000,
                group: "user",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text: '<p style="font-size: 12pt">' + resp.data.message + "</p>"
              });

              this.$router.push('/').catch(() => {});
            })
            .catch(error => {
              this.$notify({
                type: "error",
                duration: 1000,
                speed: 1000,
                group: "user",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text:
                  '<p style="font-size: 12pt">' +
                  error.response.data.message +
                  "</p>"
              });
            });
        }
      }
    }
  }
};
</script>

<style>
body {
  background: white;
}
.rows-form {
  padding: 0px;
  margin-bottom: 0px;
}
.termos a {
  text-decoration: none;
  color: #5c2b97;
  font-size: 14pt;
  font-weight: bold;
}
</style>