<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-card>
        <v-tabs fixed-tabs v-model="vtab">
          <v-tab>
            <v-icon left>
              mdi-checkbox-marked-circle
            </v-icon>
            Ativos
          </v-tab>

          <v-tab>
            <v-icon left>
              mdi-minus-circle
            </v-icon>
            Desativados
          </v-tab>

          <v-tabs-items v-model="vtab">
            <v-tab-item>
              <Table :BtAtivo="vtab === 0 ? true : false" />
            </v-tab-item>
            <v-tab-item v-if="vtab === 1">
              <Table :BtAtivo="false" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Table from "./Table";
export default {
  components: {
    Table
  },

  data() {
    return {
      vtab: 0,
      items: [
        {
          text: "Usuários",
          disabled: true,
          href: "/usuarios"
        }
      ]
    };
  }
};
</script>