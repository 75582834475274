<template>
  <div>
    <v-card>
      <v-toolbar flat color="#53513b" dark>
        <v-row>
          <v-spacer> </v-spacer>
          <v-col cols="12" sm="6" md="4" lg="1">
            <el-tooltip
              class="item"
              effect="light"
              content="Cadastrar novo marcador"
              placement="top"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#95926a"
                @click="modalFormData.isOpen = true"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </el-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <el-table
        :data="listaMarcadores"
        stripe
        style="width: 100%"
        v-if="listaMarcadores.length"
        v-loading="loading"
      >
        <el-table-column label="Nome">
          <template slot-scope="scope">
            {{ scope.row.DsNome }}
          </template>
        </el-table-column>

        <el-table-column label="Cor">
          <template slot-scope="scope">
            <v-icon icon="mmdi-tag-multiple" :color="scope.row.DsCor">
              mdi-tag-multiple
            </v-icon>
          </template>
        </el-table-column>

        <el-table-column label="Ações">
          <template slot-scope="scope">
            <el-button
              @click="editar(scope.row)"
              type="primary"
              icon="el-icon-edit"
              size="small"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listarMarcadores"
        ></v-pagination>
      </div>
    </v-card>

    <Modal
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :type="modalFormData.type"
      @CLOSE="
        modalFormData.isOpen = false;
        listarMarcadores();
      "
    />
  </div>
</template>

<script>
import Modal from "./Modal";
import api from "@/services/api";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalFormData: { isOpen: false, data: {}, type: "cadastrar" },
      listaMarcadores: [],
      loading: false,
      form: {
        DsBusca: "",
        MdFiltroData: {
          DtInicio: null,
          DtFim: null,
        },
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
        MdModel: {},
      },
    };
  },

  methods: {
    editar(data) {
      this.modalFormData.isOpen = true;
      this.modalFormData.data = data;
      this.modalFormData.type = "Editar";
    },
    listarMarcadores() {
      scroll(0, 0);
      this.loading = true;
      api
        .post("marcador/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listaMarcadores = response.data.TbVendaMarcadores;
            this.form.MdPaginacao = response.data.MdPaginacao;
            this.loading = false;
          }.bind(this)
        )
        .catch(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    inputBusca() {
      return this.form.DsBusca;
    },
  },
  watch: {
    inputBusca() {
      if (this.inputBusca.length !== "" && this.inputBusca.length > 3) {
        this.form.MdPaginacao.ItensPorPagina = 999999;
        this.listarMarcadores();
      }
    },
  },

  mounted() {
    this.listarMarcadores();
  },
};
</script>

<style scoped>
/*Definido cor das linhas pares*/
.full_table_list tr:nth-child(even) {
  background: #84825e;
}

/*Definindo cor das Linhas impáres*/
.full_table_list tr:nth-child(odd) {
  background: #a6a376;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53513b;
}
</style>