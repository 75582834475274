<template>
  <div class="text-center">
    <v-dialog v-model="visible" persistent width="800">
      <v-card>
        <v-toolbar color="#53513b" dark>
          <v-row>
            <v-col cols="10" style="text-align: left; margin-top: 5px">
              Empresa
            </v-col>

            <v-col cols="2">
              <v-btn text @click="close">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text
          v-if="TbEmpresa != null && TbEmpresa.TbEnderecoEmpresa != null"
        >


          <br />
          <!-- INICIO LOGO DA EMPRESA -->
          <strong v-if="TbEmpresa.FkTbEmpresaMae == null">LOG DA EMPRESA</strong>
          <v-banner two-line v-if="TbEmpresa.FkTbEmpresaMae == null">
            <v-avatar slot="icon" color="#53513b" size="40">
              <v-icon icon="mdi-camera-iris" color="white">
                mdi-camera-iris
              </v-icon>
            </v-avatar>

            <div style="text-align: left">
              <v-img height="200"
                :src=" this.$store.state.wwwroot +'/' +TbEmpresa.DsNomeFantasia.toLowerCase().replace(' ', '') +'/logo.png'"
              ></v-img>
            </div>
          </v-banner>
          <!-- FIM LOGO DA EMPRESA -->




          <!-- INICIO DADOS DA EMPRESA -->
          <strong>DADOS DA EMPRESA</strong>
          <v-banner two-line>
            <v-avatar slot="icon" color="#53513b" size="40">
              <v-icon icon="mdi-office-building" color="white">
                mdi-office-building
              </v-icon>
            </v-avatar>

            <div style="text-align: left">
              <p>
                <strong> Nome Fantasia: </strong> {{ TbEmpresa.DsNomeFantasia }}
              </p>
              <p style="margin-top: -12px">
                <strong> RazÃo Social: </strong> {{ TbEmpresa.DsRazaoSocial }}
              </p>
              <p style="margin-top: -12px">
                <strong> CPF/CNPJ: </strong>
                {{ TbEmpresa.DsCpfCnpj }}
              </p>
              <p style="margin-top: -12px">
                <strong> Email: </strong> {{ TbEmpresa.DsEmail }}
              </p>
              <p style="margin-top: -12px">
                <strong> Telefone: </strong> {{ TbEmpresa.DsContato }}
              </p>
            </div>
          </v-banner>
          <!-- FIM DADOS DA EMPRESA -->


          <!-- INICIO ENDERECO -->
          <strong>ENDEREÇO</strong>
          <v-banner two-line>
            <v-avatar slot="icon" color="#53513b" size="40">
              <v-icon icon="mdi-map-marker-radius" color="white">
                mdi-map-marker-radius
              </v-icon>
            </v-avatar>

            <div style="text-align: left">
              <p>
                <strong> Logradouro: </strong>
                {{ TbEmpresa.TbEnderecoEmpresa.DsLogradouro }}, Nº.
                {{ TbEmpresa.TbEnderecoEmpresa.NrLogradouro }}
              </p>
              <p style="margin-top: -12px">
                <strong> CEP: </strong>
                {{ TbEmpresa.TbEnderecoEmpresa.NrCep }}
              </p>
              <p style="margin-top: -12px">
                <strong> Cidade: </strong>
                {{ TbEmpresa.TbEnderecoEmpresa.DsCidade }}
              </p>
              <p style="margin-top: -12px">
                <strong> Bairro: </strong>
                {{ TbEmpresa.TbEnderecoEmpresa.DsBairro }}
              </p>
              <p style="margin-top: -12px">
                <strong> Estado: </strong>
                {{
                  $PesquisaEstado(TbEmpresa.TbEnderecoEmpresa.FkTbEstado, false)
                }}
              </p>
              <p style="margin-top: -12px">
                <strong> UF: </strong>
                {{
                  $PesquisaEstado(TbEmpresa.TbEnderecoEmpresa.FkTbEstado, true)
                }}
              </p>
              <p style="margin-top: -12px">
                <strong> Complemento: </strong>
                {{ TbEmpresa.TbEnderecoEmpresa.DsComplemento }}
              </p>
            </div>
          </v-banner>
          <!-- FIM ENDERECO -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    visible: { type: Boolean },
    TbEmpresa: { type: Object }
  },
  methods: {
    close() {
      this.visible = !this.visible;
      this.$emit("CLOSE");
    }
  }
};
</script>

<style>
</style>