import Vue from 'vue';

Vue.prototype.$TabelasInicializacao = function() {
    return (localStorage.getItem("uTbi") !== null || localStorage.getItem("uTbi") !== '') ? JSON.parse(this.$Decodificar(localStorage.getItem("uTbi"))) : null;
}

Vue.prototype.$ListarPerfis = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbPerfil : null;
}

Vue.prototype.$ListarUsarioPermissoes = function() {
    return (localStorage.getItem("uDhp") !== null || localStorage.getItem("uDhp") !== '') ? JSON.parse(this.$Decodificar(localStorage.getItem("uDhp"))) : null;
}

Vue.prototype.$ListarTodasPermissoes = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbPermissaoCategoria : null;
}

Vue.prototype.$ListarEstadoCivil = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbEstadoCivil : null;
}

Vue.prototype.$ListarNacionalidade = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbNacionalidade : null;
}

Vue.prototype.$ListarEstado = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbEstado : null;
}

Vue.prototype.$ListarFilial = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbFilial : null;
}


Vue.prototype.$ListarPeriodo = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbPeriodo : null;
}

Vue.prototype.$ListarStatus = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbVendaStatus : null;
}

Vue.prototype.$ListarProdutos = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbProduto : null;
}

Vue.prototype.$ListarProdutosTipo = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbTipoProduto : null;
}

Vue.prototype.$ListarEmpresas = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbEmpresa : null;
}

Vue.prototype.$ListarStatusNetSales = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbVendaStatusNetSales : null;
}

Vue.prototype.$ListarTipoVenda = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbTipoVenda : null;
}

Vue.prototype.$ListarMarcadores = function() {
    return (this.$TabelasInicializacao() !== null || this.$TabelasInicializacao() !== '') ? this.$TabelasInicializacao().TbVendaMarcadores : null;
}