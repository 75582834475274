<template>
  <div>
    <v-dialog v-model="visible" persistent width="400">
      <v-card>
        <v-toolbar color="#636146" dark>
          <v-row>
            <v-col cols="8" style="text-align: left; margin-top: 5px">Campanhas</v-col>

            <v-col cols="2">
              <v-btn text @click="close">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <br />
          <el-collapse accordion>
            <el-collapse-item
              v-for="metaDinamica in listaMetaDinamica"
              :key="metaDinamica.data.IdTbMetaDinamica"
              :name="metaDinamica.data.DsNome"
            >
              <template slot="title">
                {{metaDinamica.data.DsNome}}
                <v-spacer></v-spacer>
                <el-tag effect="dark">{{metaDinamica.Valor}}</el-tag>
              </template>
              <div
                v-for="produto in metaDinamica.data.TbMetaDinamicaMetaDinamicaProdutoes"
                :key="produto.FkTbProduto"
              >
                <v-chip class="ma-2" label outlined>{{$PesquisaProduto(produto.FkTbProduto)}}</v-chip>
              </div>
            </el-collapse-item>
          </el-collapse>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="meta" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    metasDinamicas: { type: Object }
  },

  methods: {
    initialState() {
      return {
        listaMetaDinamica: []
      };
    },

    listarMetasDinamicas() {
      api
        .get("metadinamica/listar", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            let respMetas = response.data;
            this.listaMetaDinamica = [];
            this.metasDinamicas.forEach(element => {
              this.listaMetaDinamica.push({
                data: respMetas.filter(
                  el => el.IdTbMetaDinamica === element.FkTbMetaDinamica
                )[0],
                Valor: element.NrValor
              });
            });

          }.bind(this)
        )
        .catch(() => {});
    },

    close() {
      this.$emit("CLOSE");
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        this.listarMetasDinamicas();
      }
    }
  }
};
</script>

<style>
</style>