<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" align="left">Status</th>
            <th class="text-left" align="left">Qtd. HC</th>
            <th class="text-left" align="left">Qtd. N/D</th>
            <th class="text-left" align="left">Receita N/D</th>
            <th class="text-left" align="left">Qtd. Base</th>
            <th class="text-left" align="left">Receita. Base</th>
            <th class="text-left" align="left">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td align="left">
              <v-chip class="ma-2" label text-color="white" :color="item.TbVendaStatus.DsCor"></v-chip>
              {{ item.TbVendaStatus.DsNome }}
            </td>
            <td align="left">{{ item.NrQuantidadeHc }}</td>
            <td align="left">{{ item.NrQuantidadeNd }}</td>
            <td align="left">{{ item.VlReceitaNd }}</td>
            <td align="left">{{ item.NrQuantidadeBase }}</td>
            <td align="left">{{ item.VlReceitaBase }}</td>
            <td align="left">{{ item.VlReceitaTotal }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsCards: []
    };
  },

  props: {
    items: { type: Array }
  }
};
</script>

<style>
</style>