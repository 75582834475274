<template>
  <div>
    <br>
    <div style="text-align: left">
      <el-descriptions
        title="Dados da venda"
        direction="vertical"
        :column="5"
        border
      >
        <el-descriptions-item label="Contrato">
          {{ TbVenda.NrContrato }}
        </el-descriptions-item>

        <el-descriptions-item label="Proposta">
          {{ TbVenda.NrProposta }}
        </el-descriptions-item>

        <el-descriptions-item label="Tipo de venda">
          {{ $PesquisaTipoVenda(TbVenda.FkTbTipoVenda) }}
        </el-descriptions-item>

        <el-descriptions-item label="Status Din">
          {{ $PesquisaStatus(TbVenda.FkTbVendaStatus) }}
        </el-descriptions-item>

        <el-descriptions-item label="Status NetSales">
          {{ $PesquisaStatusNetSales(TbVenda.FkTbVendaStatusNetSales) }}
        </el-descriptions-item>

        <el-descriptions-item label="Empresa">
          {{ $PesquisaEmpresa(TbVenda.FkTbEmpresa) }}
        </el-descriptions-item>

        <el-descriptions-item label="Filial">
          {{ $PesquisaFilial(TbVenda.FkTbFilial) }}
        </el-descriptions-item>

        <el-descriptions-item label="Consultor">
          {{ TbVenda.TbUsuarioVendaVenda.DsNome }}
        </el-descriptions-item>

        <el-descriptions-item label="Data de agendamento">
          {{ TbVenda.DtAgendamento | moment("DD/MM/YYYY") }}
        </el-descriptions-item>

        <el-descriptions-item label="Data prevista instalação">
          {{ TbVenda.DtPrevistaInstalacao | moment("DD/MM/YYYY") }}
        </el-descriptions-item>

        <el-descriptions-item label="Data de instalação">
          {{ TbVenda.DtInstalacao | moment("DD/MM/YYYY") }}
        </el-descriptions-item>

        <el-descriptions-item label=" Data de desconexão">
          {{ TbVenda.DtDesconexao | moment("DD/MM/YYYY") }}
        </el-descriptions-item>

        <el-descriptions-item label="Produtos">
          <div
            v-for="(produto, index) in TbVenda.TbVendaVendaProdutoes"
            :key="index"
          >
            {{ produto.TbProdutoVendaProduto.DsNome }}
          </div>
        </el-descriptions-item>

        <el-descriptions-item label="Receita">
          {{ TbVenda.VlVenda }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TbVenda: { type: Object },
  },
};
</script>

<style>
</style>