<template>
  <div>
    <v-container>
      <v-card>
        <v-app-bar color="#53513b" dense dark>
          <v-toolbar-title>Churn</v-toolbar-title>
        </v-app-bar>
       <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Empresa
          </th>
          <th class="text-left">
            Filial
          </th>
           <th class="text-left">
            Total vendas
          </th>
           <th class="text-left">
            Em analise
          </th>
           <th class="text-left">
            Canceladas
          </th>
           <th class="text-left">
            Possivel cancelamento
          </th>
           <th class="text-left">
            Percentual
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in dados" :key="item.FkTbFilial" class="text-left">
          <td nowrap>{{ $PesquisaEmpresa(item.FkTbEmpresa) }}</td>
          <td nowrap>{{ $PesquisaFilial(item.FkTbFilial) }}</td>
          <td nowrap>{{ item.TotalVendas }}</td>
          <td nowrap>{{ item.PropostasAnalise }}</td>
          <td nowrap>{{ item.PropostasCanceladas }}</td>
          <td nowrap>{{ item.PropostasAferir }}</td>
          <td nowrap>{{ item.PorcentagemCanceladas.toFixed(2) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    dados: { type: Array }
  },

  methods: {
    
  },

};
</script>

<style>
</style>