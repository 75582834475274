import axios from 'axios'

var api = null;

if (process.env.NODE_ENV === 'development') {
    api = axios.create({
        // baseURL: 'http://localhost:52769/api'
        baseURL: 'https://dinservice.com.br/api'
    })
}else{
    api = axios.create({
        baseURL: 'https://dinservice.com.br/api'
    })
}

export default api