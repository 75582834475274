<template>
  <div>
    <v-dialog v-model="visible" persistent width="400">
      <v-card>
        <v-toolbar color="#636146" dark>
          <v-row>
            <v-col cols="8" style="text-align: left; margin-top: 5px">
              Nova meta
            </v-col>

            <v-col cols="2">
              <v-btn text @click="close">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" style="margin-top: -25px;">
                  <v-select
                    style="margin-top: 25px;"
                    :items="$ListarEmpresas()"
                    v-model="form.FkTbEmpresa"
                    item-value="IdTbEmpresa"
                    item-text="DsNomeFantasia"
                    label="Empresa"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row style="margin-top: -35px; text-align: left">
                <v-col cols="12" sm="12">
                 <el-select style="width:100%" v-model="form.FkTbFilial" placeholder="Filial">
                      <el-option
                      v-for="item in $ListarFilialPorEmpresa(form.FkTbEmpresa)"
                      :key="item.IdTbFilial"
                      :label="item.DsSigla"
                      :value="item.IdTbFilial">
                      <span style="float: left">{{ item.DsSigla }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.DsCidade }}</span>
                      </el-option>
                  </el-select>
                </v-col>
              </v-row>
              <v-row style="margin-top: -10px; text-align: left">
                <v-col cols="12" sm="12">
                   <v-select
                      background-color="white"
                      style="width: 100%"
                      :items="meses"
                      v-model="mesSelecionado"
                      item-value="DsValor"
                      item-text="DsNome"
                      label="Mês"
                      outlined
                      dense
                    >
                    </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" style="margin-top: -35px;">
                  <v-text-field
                    style="width: 100%"
                    v-model="form.NrHc"
                    type="text"
                    :rules="rules"
                    label="HC"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
               <v-row>
                <v-col cols="12" sm="12" style="margin-top: -35px;">
                  <v-text-field
                    style="width: 100%"
                    v-model="form.NrMeta"
                    type="text"
                    :rules="rules"
                    label="Meta"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions style="margin-top: -30px">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close">
                Cancelar
              </v-btn>
              <v-btn style="background: #95926a; color: white;" @click="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="meta" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    type: { type: String, required: true },
    data: { type: Object, required: true },
  },

  methods: {
    initialState() {
      return {
      mesSelecionado: '',
      meses:[
        { DsNome: 'Janeiro', DsValor: '01' },
        { DsNome: 'Fevereiro', DsValor: '02' },
        { DsNome: 'Março', DsValor: '03' },
        { DsNome: 'Abril', DsValor: '04' },
        { DsNome: 'Maio', DsValor: '05' },
        { DsNome: 'Junho', DsValor: '06' },
        { DsNome: 'Julho', DsValor: '07' },
        { DsNome: 'Agosto', DsValor: '08' },
        { DsNome: 'Setembro', DsValor: '09' },
        { DsNome: 'Outubro', DsValor: '10' },
        { DsNome: 'Novembro', DsValor: '11' },
        { DsNome: 'Dezembro', DsValor: '12' }
      ],

        form:{
          FkTbEmpresa: '',
          FkTbFilial: '',
          NrMeta: "",
          NrHc: "",
          DtInicio: "",
          DtFim: ""
        },
      
        rules: [v => !!v || "Valor é obrigatório"]
      };
    },

    validarDatas() {
      let dataInicial = this.formatarData(this.rangerDate[0].split("-"));
      let dataFinal = this.formatarData(this.rangerDate[1].split("-"));
      let quatidadeDeDiasNoMes = new Date(
        dataInicial.getFullYear(),
        dataInicial.getMonth(),
        0
      ).getDate();
      let timeDiff = Math.abs(dataFinal.getTime() - dataInicial.getTime()) + 1;
      let quantidadeDeDiasSelecionado = Math.ceil(
        timeDiff / (1000 * 3600 * 24)
      );

      if (quantidadeDeDiasSelecionado <= quatidadeDeDiasNoMes) return true;
      else return false;
    },
    formatarData(date) {
      return new Date(date[0], date[1], date[2]);
    },
    getDate() {
      const today = new Date();
      const date = new Date(today.getFullYear(), this.mesSelecionado, 0);
      const ano = today.getFullYear();
      const ultimoDiaDoMes = date.getDate();
      const dateInicial = ano + "-" + this.mesSelecionado + "-01";
      const dateFinal = ano + "-" + this.mesSelecionado + "-" + ultimoDiaDoMes;
      this.dataAtual = today;
      this.form.DtInicio = dateInicial
      this.form.DtFim = dateFinal;
    },

    close() {
      this.$emit("CLOSE");
    },
    submit() {
        this.getDate();

        if(this.form.FkTbEmpresa === ''){
          this.form.FkTbEmpresa = 0;
        }
        if(this.form.FkTbFilial === ''){
          this.form.FkTbFilial = 0;
        }
        this.formatarCampos();
        if (this.type !== "cadastrar") {
          this.alterar();
        } else {
          this.cadastrar();
        }
      
    },

    formatarCampos(){
      this.form.NrMeta = parseInt(this.form.NrMeta);
      this.form.NrHc = parseInt(this.form.NrHc);
    },

    cadastrar() {
      api
        .post("meta/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "meta",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "meta",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },

    alterar() {
      api
        .put("meta/atualizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "meta",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "meta",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
        if (this.type !== "cadastrar") {
          this.form = this.data;
        }
      }
    }
  },
};
</script>

<style>
</style>