<template>
  <div>
    <v-container>
      <v-card>
        <v-app-bar color="#53513b" dense dark>
          <v-toolbar-title>Ranking de vendas</v-toolbar-title>
        </v-app-bar>
        <v-data-table
          :headers="headers"
          :items="conteudo"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:item.CURVA="{ item }">
            <v-chip :color="setarCor(item.CURVA)" text-color="white" dark>
             {{ item.CURVA }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [],
      conteudo: []
    };
  },
  props: {
    dados: { type: Object }
  },

  methods: {
     setarCor (curva) {
        if (curva === 'A') return 'green'
        else if (curva === 'B') return 'orange'
        else return 'red'
      },
    montarGrid() {
      this.headers = [];
      this.conteudo = [];
      if (this.dados != null) {
        this.dados.MdVendaPorVendedoresCabecalho.forEach(element => {
          this.headers.push({
            text: element,
            align: "start",
            sortable: true,
            value: element
          });
        });

        this.dados.MdVendaPorVendedoresConteudo.forEach(element => {
          let obj = {};
          for (let i = 0; i < this.dados.MdVendaPorVendedoresCabecalho.length; i++) {
            obj[this.dados.MdVendaPorVendedoresCabecalho[i]] = element[i];
          }
          this.conteudo.push(obj);
        });
      }
    }
  },
  watch: {
    dados() {
      this.montarGrid();
    }
  }
};
</script>

<style>
</style>