<template>
  <div>
    <br>
    <div style="text-align: left">
      <el-descriptions title="Endereço" direction="vertical" :column="6" border>
        <el-descriptions-item label="Logradouro">
          {{ TbEnderecoInstalacaoVenda.DsLogradouro }}, Nº.
          {{ TbEnderecoInstalacaoVenda.NrLogradouro }}
        </el-descriptions-item>

        <el-descriptions-item label="CEP">
          {{ TbEnderecoInstalacaoVenda.NrCep }}
        </el-descriptions-item>

        <el-descriptions-item label="Cidade">
          {{ TbEnderecoInstalacaoVenda.DsCidade }}
        </el-descriptions-item>

        <el-descriptions-item label="Bairro">
          {{ TbEnderecoInstalacaoVenda.DsBairro }}
        </el-descriptions-item>

        <el-descriptions-item label="Estado">
          {{ $PesquisaEstado(TbEnderecoInstalacaoVenda.FkTbEstado) }}
        </el-descriptions-item>

        <el-descriptions-item label="UF">
          {{ $PesquisaEstado(TbEnderecoInstalacaoVenda.FkTbEstado, true) }}
        </el-descriptions-item>

        <el-descriptions-item label="Complemento" v-if="TbEnderecoInstalacaoVenda.DsComplemento">
          {{ TbEnderecoInstalacaoVenda.DsComplemento }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TbEnderecoInstalacaoVenda: { type: Object },
  },
};
</script>

<style>
</style>