<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3">
            <v-file-input
              label="Selecione o arquivo"
              outlined
              dense
              v-model="fileContent"
              @change="addFile"
            ></v-file-input>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3">
            <el-select
              style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              v-model="form.FkTbUsuarioVenda"
              filterable
              remote
              reserve-keyword
              placeholder="Supervisor"
              :remote-method="buscarUsuarios"
              :loading="pesquisaVendedorloading"
            >
              <el-option
                v-for="item in listarUsuarios"
                :key="item.IdTbUsuario"
                :label="item.DsNome"
                :value="item.IdTbUsuario"
              ></el-option>
            </el-select>
          </v-col>

          <v-col cols="12" sm="2" md="2" lg="2">
            <el-date-picker
              style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              v-model="form.MdFiltroData.DtInicio"
              format="dd/MM/yyyy"
              type="date"
              placeholder="Data inicial"
            >
            </el-date-picker>
          </v-col>

          <v-col cols="12" sm="2" md="2" lg="2">
            <el-date-picker
              style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              v-model="form.MdFiltroData.DtFim"
              format="dd/MM/yyyy"
              type="date"
              placeholder="Data Fim"
            >
            </el-date-picker>
          </v-col>

          <v-col cols="12" sm="1" md="1" lg="1">
            <v-btn
              color="#95926a"
              style="color: white; width: 100%"
              @click="listar"
            >
              <v-icon dark> mdi-map-search </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="1" md="1" lg="1">
            <download-csv
              style="width: 100%; margin-top: 0px"
              v-if="conteudo.length > 0"
              class="btn"
              :data="conteudo"
              name="mapa-de-calor.csv"
              delimiter=";"
            >
              CSV
            </download-csv>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        v-loading="loading"
        :headers="headers"
        :items="conteudo"
        :items-per-page="10"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      fileContent: null,
      pesquisaVendedorloading: false,
      listaComissaoSupervisores: [],
      listarUsuarios: [],
      rangerDate: [],
      headers: [],
      conteudo: [],
      loading: false,
      formbuscaUsuario: {
        DsBusca: "",
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
        MdModel: {
          BtAtivo: true,
        },
      },
       formData: new FormData(),
      form: {
        FkTbEmpresa: 0,
        Busca: "",
        BtAtivo: true,
        FkTbUsuarioVenda: 0,
        TbVendas: [],
        MdFiltroData: {
          DtInicio: "",
          DtFim: "",
        },
       
      },
    };
  },
  methods: {
    addFile() {
      this.formData.append("file", this.fileContent, this.fileContent.name);
    },
    montarGrid() {
      this.headers = [];
      this.conteudo = [];
      this.listaComissaoSupervisores.MdComissionamentoConsultoresCabecalho.forEach(
        (element) => {
          this.headers.push({
            text: element,
            align: "start",
            sortable: true,
            value: element,
          });
        }
      );

      this.listaComissaoSupervisores.MdComissionamentoConsultoresConteudo.forEach(
        (element) => {
          let obj = {};
          for (
            let i = 0;
            i <
            this.listaComissaoSupervisores.MdComissionamentoConsultoresCabecalho
              .length;
            i++
          ) {
            obj[
              this.listaComissaoSupervisores.MdComissionamentoConsultoresCabecalho[
                i
              ]
            ] = element[i];
          }
          this.conteudo.push(obj);
        }
      );
    },
    buscarUsuarios(query) {
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      this.formbuscaUsuario.DsBusca = query;
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => {});
    },
    listar() {
      this.formData.append("MdRelatorio", JSON.stringify(this.form));
      this.loading = true;
      api
        .post("relatorio/comissionamento-supervisores", this.formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: this.$GetToken(),
          },
        })
        .then((resp) => {
          this.listaComissaoSupervisores = resp.data;
          this.loading = false;
          this.montarGrid();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.buscarUsuarios(" ");
  },
};
</script>

<style scoped>
.btn {
  height: 35px;
  position: relative;

  display: block;
  margin: 30px auto;
  padding: 8px;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);

  background-color: #95926a;
  color: #ecf0f1;

  transition: background-color 0.3s;
}

.btn:hover,
.btn:focus {
  background-color: #a6a376;
  cursor: pointer;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.btn:before {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 0;
  padding-top: 0;

  border-radius: 100%;

  background-color: rgba(236, 240, 241, 0.3);

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;

  transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}

/* Styles, not important */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.btn.orange {
  background-color: #e67e22;
}

.btn.orange:hover,
.btn.orange:focus {
  background-color: #d35400;
}

.btn.red {
  background-color: #e74c3c;
}

.btn.red:hover,
.btn.red:focus {
  background-color: #c0392b;
}
</style>