<template>
  <div>
    <v-dialog v-model="visible" persistent width="750">
      <v-card>
        <v-toolbar color="#53513b" dark>
          <v-row>
            <v-col cols="10" style="text-align: left; margin-top: 5px">{{ title }}</v-col>

            <v-col cols="2">
              <v-btn text @click="close">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-form ref="formUsuario" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="8" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="$ListarEmpresas()"
                        v-model="form.FkTbEmpresa"
                        item-value="IdTbEmpresa"
                        item-text="DsNomeFantasia"
                        label="Empresa"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -35px">
                    <v-col cols="12" sm="12">
                      <el-select style="width:100%" v-model="form.FkTbFilial" placeholder="Filial">
                        <el-option
                          v-for="item in $ListarFilialPorEmpresa(form.FkTbEmpresa)"
                          :key="item.IdTbFilial"
                          :label="item.DsSigla"
                          :value="item.IdTbFilial"
                        >
                          <span style="float: left">{{ item.DsSigla }}</span>
                          <span
                            style="float: right; color: #8492a6; font-size: 13px"
                          >{{ item.DsCidade }}</span>
                        </el-option>
                      </el-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -5px">
                    <v-col cols="12" sm="12">
                      <v-select
                        v-model="form.FkTbPerfil"
                        :items="$ListarPerfis()"
                        label="Selecione um perfil"
                        item-value="IdTbPerfil"
                        item-text="DsNome"
                        :rules="rulesFkPersil"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row
                    style="margin-top: -35px"
                    v-if="form.FkTbPerfil === 4 || form.FkTbPerfil === 5"
                  >
                    <v-col cols="12" sm="12">
                      <el-select
                        style="width: 100%; border: 1px solid rgb(118, 118, 118); border-radius: 4px"
                        v-model="form.FkTbUsuarioSuperior"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Supervisor"
                        :remote-method="buscarUsuarios"
                        :loading="loadingBusca"
                      >
                        <el-option
                          v-for="item in listaSelectUsuario"
                          :key="item.IdTbUsuario"
                          :label="item.DsNome"
                          :value="item.IdTbUsuario"
                        ></el-option>
                      </el-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -15px">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.DsNome"
                        type="text"
                        max="14"
                        label="Nome"
                        :rules="rulesDsNome"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -35px">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.DsEmail"
                        type="text"
                        max="14"
                        label="E-mail"
                        :rules="rulesDsEmail"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -35px" v-if="exibirCadastroSenha">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.DsSenha"
                        type="password"
                        autocomplete="new-password"
                        max="14"
                        label="Senha"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -35px" v-if="exibirCadastroSenha">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.DsConfimaPassword"
                        type="password"
                        autocomplete="new-password"
                        max="14"
                        label="Confirma senha"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- PERMISSOES DE ACESSO -->
                <v-col cols="4" sm="4" style="margin-top: 10px">
                  <v-row justify="center">
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="item in listaTodasPermissoes"
                        :key="item.IdTbPermissaoCategoria"
                      >
                        <v-expansion-panel-header
                          color="#95926a"
                          style="color: white"
                        >{{ item.DsNome }}</v-expansion-panel-header>
                        <v-expansion-panel-content style="text-align: left">
                          <v-switch
                            v-for="permissao in item.TbPermissaoCategoriaPermissaos"
                            :key="permissao.IdTbPermissao"
                            v-model="permissao.BtAtivo"
                            color="success"
                            :label="permissao.DsNome"
                          ></v-switch>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-col>
                <!-- PERMISSOES DE ACESSO -->
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="background: #95926a; color: white;" @click="submit">Salvar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="usuario" />
  </div>
</template>

<script>
import api from "../../services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    tipo: { type: String, required: true },
    data: { type: Object, required: true }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },

  methods: {
    initialState() {
      return {
        cadastrar: true,
        exibirCadastroSenha: false,
        title: "Novo Usuário",
        url: "usuario/cadastrar",
        valid: true,
        buscaUsario: "",
        listaSelectUsuario: [],
        listaTodasPermissoes: this.$ListarTodasPermissoes(),
        form: {
          FkTbEmpresa: null,
          FkTbFilial: null,
          FkTbUsuarioSuperior: null,
          FkTbPerfil: 0,
          DsNome: "",
          DsEmail: "",
          DsSenha: "",
          DsConfimaPassword: "",
          TbUsuarioUsuarioPermissaos: []
        },
        formbuscaUsuario: {
          DsBusca: "",
          MdFiltroData: {},
          MdPaginacao: {
            PaginaAtual: 1,
            ItensPorPagina: 0,
            TotalPaginas: 0,
            TotalItens: 0
          },
          MdModel: { BtAtivo: true }
        },
        rulesDsSenha: [
          v => !!v || "Senha é obrigatória",
          v => (v && v.length >= 6) || "Senha deve ser maior que 6 caracteres"
        ],
        rulesDsConfimaPassword: [
          v => !!v || "Confirma senha é obrigatória",
          v =>
            (v && v.length >= 6) ||
            "Confirma senha deve ser maior que 6 caracteres"
        ],
        rulesDsNome: [v => !!v || "Nome é obrigatório"],
        rulesDsEmail: [v => !!v || "E-mail é obrigatório"],
        rulesFkPersil: [v => !!v || "Perfil é obrigatório"]
      };
    },
    close() {
      this.$emit("CLOSE");
    },

    formatarPermissoesCadastro() {
      this.form.TbUsuarioUsuarioPermissaos = [];
      this.listaTodasPermissoes.forEach(categoria => {
        categoria.TbPermissaoCategoriaPermissaos.forEach(permissao => {
          this.form.TbUsuarioUsuarioPermissaos.push({
            FkTbPermissao: permissao.IdTbPermissao,
            BtAtivo: permissao.BtAtivo
          });
        });
      });
    },

    montarListaAtualizarPermissoes() {
      this.data.TbUsuarioUsuarioPermissaos.forEach(usuarioPermissao => {
        this.listaTodasPermissoes.forEach(categoria => {
          categoria.TbPermissaoCategoriaPermissaos.forEach(permissao => {
            if (permissao.IdTbPermissao == usuarioPermissao.FkTbPermissao) {
              permissao.BtAtivo = usuarioPermissao.BtAtivo;
            }
          });
        });
      });
    },

    buscarUsuarios(query) {
      this.formbuscaUsuario.DsBusca = query;
      if (this.formbuscaUsuario.DsBusca !== "")
        this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      else this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 6;
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listaSelectUsuario = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => {});
    },

    validarSenha() {
      if (this.form.DsSenha !== this.form.DsConfimaPassword) {
        this.$notify({
          type: "error",
          duration: 1000,
          speed: 1000,
          group: "usuario",
          title:
            '<p style="font-size: 14pt">' +
            this.$store.state.aplicationName +
            "</p>",
          text:
            '<p style="font-size: 12pt">As senhas digitadas estão divergentes</p>'
        });
        return false;
      } else {
        return true;
      }
    },

    submit() {
      this.formatarPermissoesCadastro();
      if (this.cadastrar) {
        if (this.$refs.formUsuario.validate() && this.validarSenha()) {
          api
            .post(this.url, this.form, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: this.$GetToken()
              }
            })
            .then(
              function(response) {
                this.$notify({
                  type: "success",
                  duration: 1000,
                  speed: 1000,
                  group: "usuario",
                  title:
                    '<p style="font-size: 14pt">' +
                    this.$store.state.aplicationName +
                    "</p>",
                  text:
                    '<p style="font-size: 12pt">' +
                    response.data.message +
                    "</p>"
                });
                this.close();
              }.bind(this)
            )
            .catch(error => {
              this.$notify({
                type: "error",
                duration: 1000,
                speed: 1000,
                group: "usuario",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text:
                  '<p style="font-size: 12pt">' +
                  error.response.data.message +
                  "</p>"
              });
            });
        }
      } else {
        api
          .put(this.url, this.form, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.$GetToken()
            }
          })
          .then(
            function(response) {
              this.$notify({
                type: "success",
                duration: 1000,
                speed: 1000,
                group: "usuario",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text:
                  '<p style="font-size: 12pt">' + response.data.message + "</p>"
              });

              this.close();
            }.bind(this)
          )
          .catch(error => {
            this.$notify({
              type: "error",
              duration: 1000,
              speed: 1000,
              group: "usuario",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' +
                error.response.data.message +
                "</p>"
            });
          });
      }
    }
  },

  watch: {
    buscaUsario() {
      if (this.buscaUsario.length > 3 && this.buscaUsario !== null) {
        this.buscarUsuarios();
      }
    },
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
        this.buscarUsuarios(" ");
        if (this.tipo === "cadastrar") {
          this.title = "Novo Usuário";
          this.url = "usuario/cadastrar";
          this.cadastrar = true;
          this.exibirCadastroSenha = true;
        } else {
          this.title = "Atualizar Usuário";
          this.form = this.data;
          this.form.FkTbPerfil = this.data.TbPerfilUsuario.IdTbPerfil;
          this.url = "usuario/atualizar";
          this.cadastrar = false;
          this.exibirCadastroSenha = false;
          this.montarListaAtualizarPermissoes();
        }
      }
    }
  }
};
</script>

<style>
</style>