<template>
  <div>
    <v-dialog v-model="visible" persistent width="600">
      <v-card>
        <v-toolbar color="#53513b" dark>
          <v-row>
            <v-col cols="9" style="text-align: left; margin-top: 5px">
              Novo produto
            </v-col>

            <v-col cols="3">
              <v-btn text @click="close">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    :items="$ListarProdutosTipo()"
                    v-model="form.FkTbTipoProduto"
                    item-value="IdTbTipoProduto"
                    item-text="DsNome"
                    label="Tipo de produto"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" style="margin-top: -30px">
                  <v-text-field
                    v-model="form.DsNome"
                    type="text"
                    :rules="rules"
                    label="Nome"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions style="margin-top: -30px">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close">
                Cancelar
              </v-btn>
              <v-btn style="background: #95926a; color: white;" @click="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="produto" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    type: { type: String, required: true },
    data: { type: Object, required: true }
  },




  methods: {
    initialState() {
      return {
        form: {
          FkTbTipoProduto: 0,
          DsNome: ""
        },
        rules: [v => !!v || "Nome é obrigatória"]
      };
    },
    close() {
      this.$emit("CLOSE");
    },
    submit() {
      if (this.type !== "cadastrar") {
        this.alteraProduto();
      } else {
        this.cadastrarProduto();
      }
    },

    cadastrarProduto() {
      api
        .post("produto/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "produto",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "produto",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },

    alteraProduto() {
      api
        .put("produto/atualizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "produto",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "produto",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
        if (this.type !== "cadastrar") {
          this.form = this.data;
        }
      }
    }
  }
};
</script>

<style>
</style>