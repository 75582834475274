import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vfiltros: false,
    notify: [],
    btnImportacaoConexao: {
      loading: false,
      texto: 'Consultar'
    },
    importacaoConexao: {
      NrTotalImportacoes: 0,
      NrPercentual: 0
    },
    btnImportacaoNetSales: {
      loading: false,
      texto: 'Sincronizar'
    },
    importacaoNetSales: {
      NrTotalImportacoes: 0,
      NrPercentual: 0
    },
    apiKeyMaps: 'ptUI9pAAw8NBURdGJVub1mRc_Y_YlQeonN_QF3IKv2Y',
    aplicationName: 'DIN SERVICE',
    inputBusca: '',
    wwwroot: 'https://dinservices.omnigestor.com',
    user: JSON.parse(localStorage.getItem("uDhs")),
    loading: false,
  },
  mutations: {
    
    setVfiltro: (state, payload) =>{
      state.vfiltros = payload;
    },

    setLoading: (state, payload) =>{
      state.loading = payload;
    },

    setImportacoesConexao: (state, payload) => {
      state.importacaoConexao = payload;
    },

    setBtnImportacoesConexao: (state, payload) => {
      state.btnImportacaoConexao = payload;
    },

    setImportacoesNetSales: (state, payload) => {
      state.importacaoNetSales = payload;
    },

    setBtnImportacoesNetSales: (state, payload) => {
      state.btnImportacaoNetSales = payload;
    },

    setUser: (state, payload) => {
      if (payload === null) {
        let str = localStorage.getItem("uDhs")
        let userObj = JSON.parse(str)
        state.user = userObj;
      } else {
        state.user = payload.user;
      }
    },

  },
  actions: {
  },
  modules: {
  }
})
