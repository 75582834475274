<template>
  <div>
    <v-card>
      <v-toolbar flat color="#53513b" dark>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select
              background-color="#95926a"
              style="margin-top: 25px; margin-left: 10px;"
              :items="$ListarEmpresas()"
              v-model="form.MdModel.FkTbEmpresa"
              item-value="IdTbEmpresa"
              item-text="DsNomeFantasia"
              label="Selecionar Empresa"
              outlined
              dense
              @change="listarMetas"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-btn
              style="margin-top: 25px"
              class="mx-2"
              dark
              color="#95926a"
              @click="
                    form.MdModel.FkTbEmpresa = 0;
                    listarMetas();
                  "
            >Listar tudo</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3" lg="1">
            <el-tooltip class="item" effect="light" content="Cadastrar nova meta" placement="top">
              <v-btn
                style="margin-top: 25px"
                class="mx-2"
                fab
                dark
                small
                color="#95926a"
                @click="modalFormData.isOpen = true"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </el-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-simple-table dark style="text-align:left" class="full_table_list" v-loading="loading">
        <template v-slot:default>
          <thead>
            <tr style="background: #747252">
              <th class="text-left" nowrap>Empresa</th>
              <th class="text-left" nowrap>Filial</th>
              <th class="text-left" nowrap>Meta</th>
              <th class="text-left" nowrap>HC</th>
              <th class="text-left" nowrap>Data inicio</th>
              <th class="text-left" nowrap>Data fim</th>

              <th nowrap></th>
              <th nowrap></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listaMetas" :key="item.IdTbMeta">
              <td nowrap>{{ $PesquisaEmpresa(item.FkTbEmpresa) }}</td>
              <td nowrap>{{ $PesquisaFilial(item.FkTbFilial) }}</td>
              <td nowrap>{{ item.NrMeta }}</td>
              <td nowrap>{{ item.NrHc }}</td>
              <td nowrap>{{ item.DtInicio | moment("DD/MM/YYYY") }}</td>
              <td nowrap>{{ item.DtFim | moment("DD/MM/YYYY") }}</td>

              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    modalFormData.isOpen = true;
                    modalFormData.data = item;
                    modalFormData.type = 'atualizar';
                  "
                >
                  <v-icon>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    deletar(item);
                    listarMetas();
                  "
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <notifications group="meta" />
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listarMetas"
        ></v-pagination>
      </div>
    </v-card>

    <Modal
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :type="modalFormData.type"
      @CLOSE="
        modalFormData.isOpen = false;
        listarMetas();
      "
    />
  </div>
</template>

<script>
import Modal from "./Modal";
import api from "@/services/api";
export default {
  components: {
    Modal
  },
  data() {
    return {
      modalFormData: { isOpen: false, data: {}, type: "cadastrar" },
      listaMetas: [],
      loading: false,
      form: {
        DsBusca: "",
        MdFiltroData: {
          DtInicio: null,
          DtFim: null
        },
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: {
          FkTbEmpresa: 0,
          FkTbFilial: 0
        }
      }
    };
  },

  methods: {
    deletar(item) {
      api
        .delete("meta/deletar", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          },
          data: {
            IdTbMeta: item.IdTbMeta
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "meta",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "meta",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },

    listarMetas() {
      scroll(0, 0);
      this.loading = true;
      api
        .post("meta/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listaMetas = response.data.TbMeta;
            this.form.MdPaginacao = response.data.MdPaginacao;
            this.loading = false;
          }.bind(this)
        )
        .catch(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.listarMetas();
  }
};
</script>

<style scoped>
/*Definido cor das linhas pares*/
.full_table_list tr:nth-child(even) {
  background: #84825e;
}

/*Definindo cor das Linhas impáres*/
.full_table_list tr:nth-child(odd) {
  background: #a6a376;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53513b;
}
</style>