import Vue from 'vue';

Vue.prototype.$JsonDecode = function (valor) {
    return JSON.stringify(valor, null, 2);
}

Vue.prototype.$AwaitLoading = function () {
    this.$store.commit("setLoading", true);
    
    let interval = setInterval(() => {
        this.$store.commit("setLoading", false);
        clearInterval(interval);
      }, 600)
}


Vue.prototype.$VerificaPermissaoUsuario = function (FkTbPermissao) {
    let BtAtivo = false;
    this.$ListarUsarioPermissoes().forEach(item => {
        if (item.FkTbPermissao == FkTbPermissao) {
            BtAtivo = item.BtAtivo;
        }
    });
    return BtAtivo
}

Vue.prototype.$UsuarioPerfil = function () {
    let DsPerfil = '';
    this.$ListarPerfis().forEach(item => {
        if (item.IdTbPerfil == this.$Decodificar(this.$store.state.user.Us3)) {
            DsPerfil = item.DsNome;
        }
    });
    return DsPerfil
}

Vue.prototype.$PesquisaPerfil = function (FkTbPerfil) {
    let DsPerfil = '';
    this.$ListarPerfis().forEach(item => {
        if (item.IdTbPerfil == FkTbPerfil) {
            DsPerfil = item.DsNome;
        }
    });
    return DsPerfil
}

Vue.prototype.$PesquisaEmpresa = function (FkTbEmpresa) {
    let DsNomeFantasia = '';
    this.$ListarEmpresas().forEach(item => {
        if (item.IdTbEmpresa == FkTbEmpresa) {
            DsNomeFantasia = item.DsNomeFantasia;
        }
    });
    return DsNomeFantasia
}

Vue.prototype.$PesquisaFilial = function (FkTbFilial) {
    let DsSigla = '';
    this.$ListarFilial().forEach(item => {
        if (item.IdTbFilial == FkTbFilial) {
            DsSigla = item.DsSigla + ' - ' +item.DsCidade;
        }
    });
    return DsSigla
}


Vue.prototype.$ListarFilialPorEmpresa = function (FkTbEmpresa) {
    return this.$ListarFilial().filter(el => el.FkTbEmpresa === FkTbEmpresa);
}

Vue.prototype.$PesquisaStatus = function (FkTbVendaStatus) {
    let DsNome = '';
    this.$ListarStatus().forEach(item => {
        if (item.IdTbVendaStatus == FkTbVendaStatus) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaStatusNetSales = function (FkTbVendaStatusNetSales) {
    let DsNome = 'Não informado';
    this.$ListarStatusNetSales().forEach(item => {
        if (item.IdTbVendaStatusNetSales == FkTbVendaStatusNetSales) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaPeriodo = function (FkTbPeriodo) {
    let DsNome = '';
    this.$ListarPeriodo().forEach(item => {
        if (item.IdTbPeriodo == FkTbPeriodo) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaTipoVenda = function (FkTbTipoVenda) {
    let DsNome = '';
    this.$ListarTipoVenda().forEach(item => {
        if (item.IdTbTipoVenda == FkTbTipoVenda) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaProdutoTipo = function (FkTbTipoProduto) {
    let DsNome = '';
    this.$ListarProdutosTipo().forEach(item => {
        if (item.IdTbTipoProduto == FkTbTipoProduto) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaProduto = function (FkTbProduto) {
    let DsNome = '';
    this.$ListarProdutos().forEach(item => {
        if (item.IdTbProduto == FkTbProduto) {
            DsNome = item.DsNome;
        }
    });
    return DsNome
}

Vue.prototype.$PesquisaEstado = function (FkTbEstado, uf = false) {
    let DsNome = '';
    this.$ListarEstado().forEach(item => {
        if(uf == true){
            if (item.IdTbEstado == FkTbEstado) {
                DsNome = item.DsUF;
            } 
        }else{
            if (item.IdTbEstado == FkTbEstado) {
                DsNome = item.DsNome;
            }
        }
    });
    return DsNome
}

Vue.prototype.$GetToken = function () {
    if (localStorage.getItem("uDht") === null || localStorage.getItem("uDht") === '') return localStorage.getItem("uDht")
    let cptToken = localStorage.getItem("uDht");
    let posicao1 = cptToken.substring(0, 55);
    let posicao2 = cptToken.substring(cptToken.length - 20, cptToken.length)
    return atob(localStorage.getItem("uDht").replace(posicao1, '').replace(posicao2, ''));
}

Vue.prototype.$HasAuthenticated = function () {
    const token = this.$GetToken()
    if (token !== null && token !== '') {
        return true;
    } else {
        return false;
    }
}

Vue.prototype.$gatePermission = function (FkTbPermissaoCategoria, FkTbPermissao) {
    let isTrue = false;
    this.$ListarUsarioPermissoes().forEach(item => {
        if (item.FkTbPermissaoCategoria == FkTbPermissaoCategoria && item.FkTbPermissao == FkTbPermissao) {
            isTrue = true;
        }
    });
    return isTrue;
}


