<template>
  <div data-app >
    <v-app-bar style="background-color: #53513b" dense dark>
      <v-btn icon @click="$router.push('/').catch(()=>{})">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>Voltar</v-toolbar-title>
    </v-app-bar>
    <v-form
      style="display: inline-block; margin-top: 120px; width: 300px; border: 1px solid #636146; padding: 20px; border-radius: 20px"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-container>
        <v-img
          width="100"
          :src="require(`@/assets/logo.png`)"
          style="display: inline-block; margin-bottom: 0px;"
        ></v-img>

        <v-row class="rows-form">
          <v-col class="rows-form">
            <v-text-field
              v-model="form.DsEmail"
              :rules="emailRules"
              label="Email"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          :disabled="!valid"
          style="background-color: #636146; color: white; width: 100%"
          @click="submit"
        >
          Enviar
        </v-btn>
      </v-container>
    </v-form>

    <notifications group="user" />
  </div>
</template>

<script>
import axios from "@/services/api";
export default {
  components: {},

  data() {
    return {
      loading: false,
      selection: 1,
      valid: true,

      form: {
        DsEmail: ""
      },

      emailRules: [
        v => !!v || "E-mail é obrigatório",
        v => /.+@.+\..+/.test(v) || "E-mail invalido"
      ]
    };
  },

  methods: {
    close() {
      this.$emit("CLOSE");
    },
    submit() {
      if (this.$refs.form.validate()) {
        axios
          .post("usuario/resetarsenha", this.form)
          .then(resp => {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "user",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text: '<p style="font-size: 12pt">' + resp.data.message + "</p>"
            });
          })
          .catch(error => {
            this.$notify({
              type: "error",
              duration: 1000,
              speed: 1000,
              group: "user",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' +
                error.response.data.message +
                "</p>"
            });
          });
      }
    }
  }
};
</script>

<style>
body {
  background: white;
}
.rows-form {
  padding: 0px;
  margin-bottom: 0px;
}
.termos a {
  text-decoration: none;
  color: #5c2b97;
  font-size: 14pt;
  font-weight: bold;
}
</style>