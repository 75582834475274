<template>
  <div>
    <div style="text-align: left">
      <el-descriptions
        title="Dados do cliente"
        direction="vertical"
        :column="5"
        border
      >
        <el-descriptions-item label="Nome">
          {{ TbClienteVenda.DsNome }}
        </el-descriptions-item>

        <el-descriptions-item label="Data de Nascimento">
          {{ TbClienteVenda.DtNascimento | moment("DD/MM/YYYY") }}
        </el-descriptions-item>

        <el-descriptions-item label="CPF">
          {{ TbClienteVenda.DsCpfCnpj }}
        </el-descriptions-item>

        <el-descriptions-item label="Sexo" :span="2">
          {{ TbClienteVenda.BtSexo ? "Masculino" : "Feminino" }}
        </el-descriptions-item>

        <el-descriptions-item label="Email">
          {{ TbClienteVenda.DsEmail }}
        </el-descriptions-item>

        <el-descriptions-item label="Profissão">
          {{ TbClienteVenda.DsProfissao }}
        </el-descriptions-item>

        <el-descriptions-item label="Telefones">
          <div
            v-for="contato in TbClienteVenda.TbClienteContatoes"
            :key="contato.IdTbContato"
          >
            {{
              contato.DsContato.replace(/\D/g, "")
                .replace(/(\d{2})(\d)/, "($1) $2")
                .replace(/(\d{4})(\d)/, "$1-$2")
                .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
                .replace(/(-\d{4})\d+?$/, "$1")
            }}
          </div>
        </el-descriptions-item>

        <el-descriptions-item label="Nome da mãe">
          {{ TbClienteVenda.DsNomeMae }}
        </el-descriptions-item>

        <el-descriptions-item label="Nome do pai">
          {{
            TbClienteVenda.DsNomePai
              ? TbClienteVenda.DsNomePai
              : "Não informado"
          }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TbClienteVenda: { type: Object },
  },
};
</script>

<style>
</style>