<template>
  <div>
    <v-card>
      <v-toolbar flat color="#53513b" dark>
        <v-row style="margin-top: 25px">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="form.DsBusca"
              @keyup.enter="listarUsuarios"
              label="Pesquisar"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="3" sm="3" md="3" lg="1">
            <el-tooltip
              class="item"
              effect="light"
              content="Cadastrar novo usuário"
              placement="top"
            >
            <download-csv
              style="width: 100%; margin-top:0px"
              v-if="conteudo.length > 0"
              class="btn"
              :data="conteudo"
              name="relatorio-de-usuarios.csv"
              delimiter=";"
            >
             BAIXAR CSV
            </download-csv>
            </el-tooltip>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="1">
            <el-tooltip
              class="item"
              effect="light"
              content="Cadastrar novo usuário"
              placement="top"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#95926a"
                @click="modalFormData.isOpen = true; modalFormData.tipo = 'cadastrar'">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </el-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-simple-table dark style="text-align:left" class="full_table_list">
        <template v-slot:default>
          <thead>
            <tr style="background: #747252">
              <th class="text-left">
                Nome
              </th>
              <th class="text-left">
                E-mail
              </th>
              <th class="text-left">
                Perfil
              </th>
              <th class="text-left">
                Data de Criação
              </th>
              <th class="text-left">
                Data de Alteração
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody v-loading="loading">
            <tr
              v-for="item in listaUsuarios"
              :key="item.IdTbUsuario"
              style="cursor: pointer"
            >
              <td>{{ item.DsNome }}</td>
              <td>{{ item.DsEmail }}</td>
              <td>{{ item.TbPerfilUsuario.DsNome }}</td>
              <td>{{ item.DtCriacao | moment("DD/MM/YYYY") }}</td>
              <td>{{ item.DtAlteracao | moment("DD/MM/YYYY") }}</td>
              <td>

                  <v-btn
                    icon
                    color="white"
                    @click="alterarSenha(item)">
                    <v-icon>mdi-key</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    color="white"
                    @click="
                      modalFormData.isOpen = true;
                      modalFormData.data = item;
                      modalFormData.tipo = 'alterar';">
                    <v-icon>mdi-account-edit</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    color="white"
                    v-if="!item.BtAtivo"
                    @click="exibirMensagem(item, true)">
                    <v-icon>mdi-account-check</v-icon>
                  </v-btn>


                  <v-btn
                    icon
                    color="white"
                    v-if="item.BtAtivo"
                    @click="exibirMensagem(item, false)">
                    <v-icon>mdi-account-minus</v-icon>
                  </v-btn>

              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listarUsuarios"
        >
        </v-pagination>
      </div>
    </v-card>

    <AdminModalAlterarSenha 
      :visible="modalAlterarSenha.visible"
      :usuario="modalAlterarSenha.data"
      @CLOSE="
        modalAlterarSenha.visible = false;
        listarUsuarios();
      "
      @CONFIRMAR="
        atualizarUsuario();
        modalAlterarSenha.visible = false;
      "/>

    <CaixaConfirmacao
      :visible="modalConfirmacao.visible"
      :message="modalConfirmacao.message"
      @CLOSE="
        modalConfirmacao.visible = false;
        listarUsuarios();
      "
      @CONFIRMAR="
        atualizarUsuario();
        modalConfirmacao.visible = false;
      "
    />

    <Modal
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :tipo="modalFormData.tipo"
      @CLOSE="
        modalFormData.isOpen = false;
        listarUsuarios();
      "
    />
  </div>
</template>

<script>
import CaixaConfirmacao from "./CaixaConfirmacao";
import AdminModalAlterarSenha from "./AdminModalAlterarSenha";
import Modal from "./Modal";
import api from "@/services/api";
export default {
  components: {
    Modal,
    CaixaConfirmacao,
    AdminModalAlterarSenha
  },
  data() {
    return {
      modalDetalhes: { isOpen: false, dados: {} },
      modalFormData: { isOpen: false, data: {}, tipo: "cadastrar" },
      modalConfirmacao: { visible: false, message: "" },
      modalAlterarSenha: { visible: false, data: {} },
      loading: false,
      todosUsuarios: [],
      conteudo: [],
      listaUsuarios: [],
      TbUsuario: {},
       form: {
        DsBusca: "",
        MdFiltroData:{},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: { BtAtivo: true}
      }
    };
  },

  props: {
    BtAtivo: { type: Boolean, required: true }
  },

  methods: {
    alterarSenha(item){
      this.modalAlterarSenha.visible = true;
      this.modalAlterarSenha.data = item;
    },
    exibirMensagem(item, BtAtivo) {
      this.TbUsuario = item;
      this.TbUsuario.BtAtivo = BtAtivo;
      if (BtAtivo)
        this.modalConfirmacao.message =
          "Tem certeza que deseja ativar o usario " +
          this.TbUsuario.DsNome +
          "?";
      else
        this.modalConfirmacao.message =
          "Tem certeza que deseja desativar o usario " +
          this.TbUsuario.DsNome +
          "?";
      this.modalConfirmacao.visible = true;
    },
    atualizarUsuario() {
      api
        .put("usuario/atualizar", this.TbUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "cep",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.listarUsuarios();
          }.bind(this)
        )
        .catch(() => {});
    },

    listarUsuarios() {
      scroll(0,0);
      this.loading = true;
      this.form.MdModel.BtAtivo = this.BtAtivo;
      this.form.DsBusca = this.inputBusca;
      if(this.form.DsBusca !== '') this.form.MdPaginacao.ItensPorPagina = 999999;
      else this.form.MdPaginacao.ItensPorPagina = 6;
      api
        .post("usuario/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            if (!response.data.message) {
              this.listaUsuarios = response.data.TbUsuario;
              this.form.MdPaginacao = response.data.MdPaginacao;
            }
             this.loading = false;
          }.bind(this)
        )
        .catch(() => { this.loading = false;});
    },

    montarConteudoCSV(){
      this.todosUsuarios.forEach(user => {
        const nUser = {
          "Empresa": this.$PesquisaEmpresa(user.FkTbEmpresa),
          "Filial": this.$PesquisaFilial(user.FkTbFilial),
          "Matricula": user.DsMatricula, 
          "Perfil": user.TbPerfilUsuario.DsNome,
          "Nome": user.DsNome,
          "Email":  user.DsEmail,
          "Ativo": user.BtAtivo ? "Sim": "Não"
        }
        this.conteudo.push(nUser);
      })
    },
    
  listarTodosUsuarios() {
      this.form.MdModel.BtAtivo = this.BtAtivo;
      this.form.MdPaginacao.ItensPorPagina = 999999;
      api
        .post("usuario/listar-todos", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            if (!response.data.message) {
              this.todosUsuarios = response.data.TbUsuario;
              this.montarConteudoCSV();
            }
          }.bind(this)
        )
        .catch(() => { });
    }
  },


  computed: {
    inputBusca() {
      return this.form.DsBusca;
    }
  },

  mounted() {
    this.listarUsuarios();
    this.listarTodosUsuarios();
  },

  watch: {
    BtAtivo() {
      this.listarUsuarios();
      this.listarTodosUsuarios();
    },
    inputBusca() {
      if (this.inputBusca.length !== "" && this.inputBusca.length > 3) {
        this.listarUsuarios();
      }
    }
  }
};
</script>

<style scoped>
/*Definido cor das linhas pares*/
.full_table_list tr:nth-child(even) {
  background: #84825e;
}

/*Definindo cor das Linhas impáres*/
.full_table_list tr:nth-child(odd) {
  background: #a6a376;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53513b;
}
</style>