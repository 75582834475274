<template>
  <div>
    <v-dialog v-model="visible" persistent width="600">
      <v-card>
        <v-toolbar color="#53513b" dark>
          <v-row>
            <v-col cols="9" style="text-align: left; margin-top: 5px">
              Novo marcador
            </v-col>

            <v-col cols="3">
              <v-btn text @click="close"> Fechar </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form>
            <v-container>
              <v-text-field
                v-model="form.DsNome"
                type="text"
                label="Nome"
                required
                outlined
                dense
              ></v-text-field>
              <div style="text-align: left">
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-tag>Selecione uma cor</el-tag>
                  </el-col>
                  <el-col :span="18">
                    <el-color-picker v-model="form.DsCor"></el-color-picker>
                  </el-col>
                </el-row>
              </div>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close"> Cancelar </v-btn>
              <v-btn style="background: #95926a; color: white" @click="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="produto" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    type: { type: String, required: true },
    data: { type: Object, required: true },
  },

  methods: {
    initialState() {
      return {
        form: {
          DsCor: null,
          DsNome: null,
        },
      };
    },
    close() {
      this.$emit("CLOSE");
    },
    submit() {
      if (this.type !== "cadastrar") {
        this.alterarMarcador();
      } else {
        this.cadastrarMarcador();
      }
    },

    cadastrarMarcador() {
      api
        .post("marcador/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "produto",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>",
            });
            this.close();
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "produto",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },

    alterarMarcador() {
      console.log(this.form);
      api
        .put("marcador/atualizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "produto",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>",
            });
            this.close();
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "produto",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },
  },

  watch: {
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
        if (this.type !== "cadastrar") {
          this.form = this.data;
        }
      }
    },
  },
};
</script>

<style>
</style>