<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  name: "login",
  computed: {},

  data() {
    return {
      isReload: false
    };
  },

  methods: {
    tabelaInicializacao() {
      api
        .get("tabelasinicializacao/listar", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(resp) {
            localStorage.setItem("uTbi", this.$Codificar(JSON.stringify(resp.data)));
          }.bind(this)
        )
        .catch(() => {
          localStorage.removeItem("uDht");
          localStorage.removeItem("uDhs");
          localStorage.removeItem("uDhp");
          localStorage.removeItem("uDhs");
          localStorage.removeItem("uTbi");
          this.$router.push("/login").catch(() => {});
        });
    }
  },

  created(){
     setInterval(() => {
       this.tabelaInicializacao();
     }, 100000)
  }
 
};
</script>

