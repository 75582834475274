import IndexDashboard from '@/components/layout/Container'
import DashboardFiliais from '@/components/DashboardFiliais/Index'
import DashboardEmpresas from '@/components/DashboardEmpresas/Index'
import Vendas from '@/components/Vendas/Index'
import Produtos from '@/components/Produtos/Index'
import Usuarios from '@/components/Usuarios/Index'
import MapaVendedores from '@/components/MapaVendedores/Index'
import Filiais from '@/components/Filiais/Index'
import MetaEmpresa from '@/components/Metas/Empresa/Index'
import MetaVendedor from '@/components/Metas/Vendedor/Index'
import TransferirVendas from '@/components/Vendas/TransferirVendas/Index'
import RelatorioVendasPorVendedor from '@/components/Relatorios/VendasPorVendedor/Index'
import RelatorioVendasPorSupervisor from '@/components/Relatorios/VendasPorSupervisor/Index'
import RelatorioPorEmpresa from '@/components/Relatorios/PorEmpresa/Index'
import RelatorioMapaCalor from '@/components/Relatorios/MapaCalor/Index'
import Importacao from '@/components/Importacao/Index'
import ComissaoConsultor from '@/components/PagComissao/ComissaoConsultor/Index'
import ComissaoSupervisor from '@/components/PagComissao/ComissaoSupervisor/Index'
import Logs from '@/components/Logs/Index'
import EquipeChurn from '@/components/EquipeChurn/Index'
import PesquisarVendas from '@/components/PesquisarVendas/Index'
import Marcadores from '@/components/Marcadores/Index'

export default {

    path: '/', component: IndexDashboard, name: 'index',

    children: [

        { path: '/', component: DashboardEmpresas, name: 'dashboard' },
        { path: '/dashboard-filiais', component: DashboardFiliais, name: 'dashboard-filiais' },
        { path: '/nova-venda', component: Vendas, name: 'nova-venda' },
        { path: '/pesquisar-vendas', component: PesquisarVendas, name: 'pesquisar-vendas' },
        { path: '/produtos', component: Produtos, name: 'produtos' },
        { path: '/usuarios', component: Usuarios, name: 'usuarios' },
        { path: '/mapa-vendedores', component: MapaVendedores, name: 'mapa-vendedores' },
        { path: '/filiais', component: Filiais, name: 'filiais' },
        { path: '/metas-empresa', component: MetaEmpresa, name: 'metas-empresa' },
        { path: '/metas-vendedor', component: MetaVendedor, name: 'metas-vendedor' },
        { path: '/transferir-vendas', component: TransferirVendas, name: 'transferir-vendas' },
        { path: '/relatorios-vendas-vendedor', component: RelatorioVendasPorVendedor, name: 'relatorios-vendas-vendedor' },
        { path: '/relatorios-vendas-supervisor', component: RelatorioVendasPorSupervisor, name: 'relatorios-vendas-supervisor' },
        { path: '/relatorios-por-empresa', component: RelatorioPorEmpresa, name: 'relatorios-por-empresa' },
        { path: '/relatorios-mapa-calor', component: RelatorioMapaCalor, name: 'relatorios-mapa-calor' },
        { path: '/importacao', component: Importacao, name: 'importacao' },
        { path: '/comissao-consultor', component: ComissaoConsultor, name: 'comissao-consultor' },
        { path: '/comissao-supervisor', component: ComissaoSupervisor, name: 'comissao-supervisor' },
        { path: '/logs', component: Logs, name: 'logs' },
        { path: '/equipe-churn', component: EquipeChurn, name: 'equipChurn' },
        { path: '/marcadores', component: Marcadores, name: 'marcadores' },

    ]

}