<template>
  <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini" permanent>
    <v-list-item class="px-2" link style="padding: 5px">
      <v-list-item-avatar>
        <v-img :src="require(`@/assets/logo.png`)"> </v-img>
      </v-list-item-avatar>

      <v-list-item-title style="text-align: left">{{
        $store.state.aplicationName
      }}</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon> mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <DrawerRouterLink
        name="Dashboard Empresas"
        icon="mdi-chart-areaspline"
        router="/"
        v-if="this.$VerificaPermissaoUsuario(4)"
      />
      <DrawerRouterLink
        name="Dashboard Filiais"
        icon="mdi-chart-areaspline-variant"
        router="/dashboard-filiais"
        v-if="this.$VerificaPermissaoUsuario(4)"
      />
      <DrawerRouterLink
        name="Pesquisar venda"
        icon="mdi-magnify"
        router="/pesquisar-vendas"
      />
      <DrawerRouterLink
        name="Equipe Churn"
        icon="mdi-chart-timeline"
        router="/equipe-churn"
      />
      <DrawerRouterLink
        name="Cadastrar venda"
        icon="mdi-file-plus"
        router="/nova-venda"
        v-if="this.$VerificaPermissaoUsuario(20)"
      />
      <DrawerRouterLink
        name="Marcadores"
        icon="mdi-tag-multiple"
        router="/marcadores"
      />
      <DrawerRouterLink
        name="Filiais"
        icon="mdi-office-building"
        router="/filiais"
        v-if="this.$VerificaPermissaoUsuario(28)"
      />
      <DrawerRouterLink
        name="Usuários"
        icon="mdi-account"
        router="/usuarios"
        v-if="this.$VerificaPermissaoUsuario(24)"
      />
      <DrawerGroupRouterLink
        name="Metas"
        icon="mdi-trending-up"
        :data="menuGroupMetas"
        v-if="this.$VerificaPermissaoUsuario(20)"
      />
      <DrawerGroupRouterLink
        name="Relatórios"
        icon="mdi-chart-bar"
        :data="menuGroupRelatorios"
        v-if="this.$VerificaPermissaoUsuario(36)"
      />
      <DrawerGroupRouterLink
        name="Pag. Comisão"
        icon="mdi-account-cash"
        :data="menuGroupComissao"
        v-if="this.$VerificaPermissaoUsuario(36)"
      />

      <!-- <DrawerRouterLink
        name="Produtos"
        icon="mdi-hexagon-multiple"
        router="/produtos"
        v-if="this.$VerificaPermissaoUsuario(20)"
      /> -->
      <!-- <DrawerRouterLink name="Importação" icon="mdi-cloud-upload" router="/importacao" />
      <DrawerRouterLink name="Logs" icon="mdi-drag-variant" router="/logs" v-if="this.$VerificaPermissaoUsuario(28)" />
      <DrawerRouterLink name="Logs" icon="mdi-drag-variant" router="/logs" v-if="this.$VerificaPermissaoUsuario(28)" />
       <DrawerRouterLink name="Consultor no mapa" icon="mdi-map-marker-radius" router="/mapa-vendedores" v-if="this.$VerificaPermissaoUsuario(8)" />
      <DrawerGroupRouterLink name="Vendas" icon="mdi-file-tree" :data="menuGroupVendas" v-if="this.$VerificaPermissaoUsuario(20)" />
      <DrawerRouterLink name="localizar venda" icon="mdi-selection-search" router="/localizar-vendas" v-if="this.$VerificaPermissaoUsuario(20)" />
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import DrawerGroupRouterLink from "./DrawerGroupRouterLink";
import DrawerRouterLink from "./DrawerRouterLink";
export default {
  components: {
    DrawerGroupRouterLink,
    DrawerRouterLink,
  },
  data() {
    return {
      drawer: true,
      mini: false,
      menuGroupRelatorios: [
        {
          title: "➛ Empresa",
          icon: "mdi-file-excel",
          router: "/relatorios-por-empresa",
        },
        {
          title: "➛ Mapa de calor",
          icon: "mdi-file-excel",
          router: "/relatorios-mapa-calor",
        },
        {
          title: "➛ Consultor",
          icon: "mdi-file-excel",
          router: "/relatorios-vendas-vendedor",
        },
        {
          title: "➛ Supervisor",
          icon: "mdi-file-excel",
          router: "/relatorios-vendas-supervisor",
        },
      ],
      menuGroupComissao: [
        {
          title: "➛ Consultor",
          icon: "mdi-cash-usd-outline",
          router: "/comissao-consultor",
        },
        {
          title: "➛ Supervisor",
          icon: "mdi-cash-usd-outline",
          router: "/comissao-supervisor",
        },
      ],
      menuGroupMetas: [
        {
          title: "➛ Empresa",
          icon: "mdi-trending-up",
          router: "/metas-empresa",
        },
        {
          title: "➛ Consultor",
          icon: "mdi-trending-up",
          router: "/metas-vendedor",
        },
      ],
    };
  },
  methods: {
    loading() {
      this.$store.commit("setLoading", !this.$store.state.loading);
    },
  },
};
</script>