<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="ma-2"
          color="#53513b"
          text-color="white"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar left>
            <v-icon> mdi-account-circle</v-icon>
          </v-avatar>
          {{
            $HasAuthenticated() && $store.state.user != null
              ? $Decodificar($store.state.user.Us2)
                  .toUpperCase()
                  .split(" ")[0]
              : "Entrar"
          }}
        </v-chip>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="require(`@/assets/logo.png`)"> </v-img>
            </v-list-item-avatar>

            <v-list-item-content style="text-align: left">
              <v-list-item-title>{{
                $HasAuthenticated() && $store.state.user != null
                  ? $Decodificar($store.state.user.Us2)
                      .toUpperCase()
                      .split(" ")[0]
                  : "Entrar"
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $HasAuthenticated() && $store.state.user != null
                  ? $UsuarioPerfil()
                  : ""
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

         <v-list-item style="text-align: left;" @click="modalAlterarSenha = true">
             <v-list-item-icon>
                <v-icon v-text="'mdi-key'" color="#95926a"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="'Alterar senha'" style="color: #95926a" ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#95926a" text @click="logout"> Sair </v-btn>
        </v-card-actions>
         <ModalAlterarSenha :visible="modalAlterarSenha" @CLOSE="modalAlterarSenha = false"/>
      </v-card>
    </v-menu>
  
  </div>
</template>

<script>
import ModalAlterarSenha from '@/components/Usuarios/ModalAlterarSenha'
export default {
  name: "App",

  components: {
    ModalAlterarSenha,
  },

  data() {
    return {
      menu: false,
      modalAlterarSenha: false,
    };
  },

  methods: {
    logout() {
      localStorage.removeItem("uDhs");
      localStorage.removeItem("uDht");
      localStorage.removeItem("uDhp");
      localStorage.removeItem("uTbi");
      this.$router.push("/login");
    }
  }
};
</script>
