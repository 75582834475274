<template>
  <div>
    <div style="text-align: left; max-height: 200px; overflow-y: scroll">
      <div v-for="(item, index) in TbVendaVendaRegistroes" :key="index">
        <br />
        <el-descriptions
          title="Histórico de atividades"
          direction="vertical"
          :column="4"
          border
        >
          <el-descriptions-item label="Usuário">
            {{ item.TbUsuarioVendaRegistro.DsNome }}
          </el-descriptions-item>

          <el-descriptions-item label="Data de alteração">
            {{ item.DtCriacao | moment("DD/MM/YYYY") }}
          </el-descriptions-item>
          <el-descriptions-item label="Período">
            {{ $PesquisaPeriodo(item.FkTbPeriodo) }}
          </el-descriptions-item>

          <el-descriptions-item label="Status">
            {{ $PesquisaStatusNetSales(item.FkTbVendaStatusNetSales) }}
          </el-descriptions-item>

          <el-descriptions-item label="Observação">
            {{ item.DsObservacao }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TbVendaVendaRegistroes: { type: Array },
  },
};
</script>

<style>
</style>