<template>
  <router-link :to="router" class="router-link">
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>{{icon}}</v-icon>
      </v-list-item-icon>

      <v-list-item-content @click="$AwaitLoading()">
        <v-list-item-title class="router-link-item">{{name}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </router-link>
</template>

<script>
export default {
data(){
    return{

    };
},
props:{
    name: {type: String, required: true },
    icon: {type: String, required: true },
    router: {type: String, required: true },
}

};
</script>

<style>
.router-link{
text-decoration: none;
}
.router-link-item{
text-align: left
}
</style>