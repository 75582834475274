<template>
  <div>
    <v-card class="mx-auto" outlined color="indigo lighten-5" dark v-if="data != null">
      <v-list-item three-line style="text-align: left">
        <v-list-item-content>
          <div class="overline mb-1" style="color: black">
            Previsão de meta N/D
          </div>
          <v-list-item-title class="overline mb-1" style="color: black">
            Quantidade: {{ data.VlAtingido }}/ {{ Math.round(data.VlPretendido) }}
          </v-list-item-title>
          <v-list-item-title class="overline mb-1" style="color: black">
            Receita: {{ parseFloat(data.VlReceita).toFixed(2) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div id="chart">
              <apexcharts 
                type="radialBar"
                :options="montarIndicardor().chartOptions"
                :series="montarIndicardor().series"
              ></apexcharts>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts
  },
   props:{
    data:{type: Object}
  },
  data() {
    return {
      
    };
  },
  methods:{
    montarIndicardor(){
      return {series:  [parseInt(this.percentual)],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#dbdac8",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "22px"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          }
        },
        labels: ["Average Results"]
      }
      };
    }
  },
  computed:{
    percentual(){
      return this.data.NrPercentual;
    }
  }
};
</script>

<style>
</style>