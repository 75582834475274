<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step color="#95926a" :complete="step > 1" step="1">
              Dados Pessoais
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#95926a" :complete="step > 2" step="2">
              Contato
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#95926a" :complete="step > 3" step="3">
              Dados da venda
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#95926a" :complete="step > 4" step="4">
              Endereço de instalação
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#95926a" :complete="step > 4" step="4">
              Endereço de cobrança
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form
                ref="formDadosPessoais"
                v-model="validformDadosPessoais"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="3" sm="3">
                      <v-text-field
                        v-model="form.NrProtocolo"
                        type="text"
                        label="Nr. Protocolo"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2">
                      <v-text-field
                        v-model="form.NrContrato"
                        type="text"
                        label="Nr. Contrato"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2">
                      <v-text-field
                        v-model="form.NrProposta"
                        type="text"
                        label="Nr. Proposta"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="5" sm="5">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsNome"
                        :rules="DsNomeRules"
                        label="Nome do cliente"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsCpfCnpj"
                        type="text"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        max="14"
                        label="CPF/CNPJ"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsRg"
                        type="text"
                        :rules="ChRgRules"
                        label="RG"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="6" md="4">
                      <label left>Data de expedição:</label>
                      <br />
                      <div class="block">
                        <el-date-picker
                          v-model="form.TbClienteVenda.DtExpedicaoRg"
                          type="date"
                          format="dd/MM/yyyy"
                          style="width: 100%"
                          placeholder="Data expedicao"
                        ></el-date-picker>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <label left>Data de nascimento:</label>
                      <br />
                      <div class="block">
                        <el-date-picker
                          v-model="form.TbClienteVenda.DtNascimento"
                          type="date"
                          format="dd/MM/yyyy"
                          style="width: 100%"
                          placeholder="Data de nascimento"
                        ></el-date-picker>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <br />
                      <v-text-field
                        v-model="form.TbClienteVenda.DsEmail"
                        label="Email"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsNomeMae"
                        :rules="DsNomeRules"
                        label="Mãe"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsNomePai"
                        label="Pai"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -20px">
                    <v-col cols="4" sm="4">
                      <v-select
                        :items="listaSexo"
                        v-model="form.TbClienteVenda.BtSexo"
                        item-value="tipo"
                        item-text="DsNome"
                        label="Sexo"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <v-select
                        :items="$ListarEstadoCivil()"
                        v-model="form.TbClienteVenda.FkTbEstadoCivil"
                        item-value="IdTbEstadoCivil"
                        item-text="DsNome"
                        :rules="VEstadoCivil"
                        label="Estado civil"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <v-select
                        :items="$ListarNacionalidade()"
                        v-model="form.TbClienteVenda.FkTbNacionalidade"
                        label="Nacionalidade"
                        item-value="IdTbNacionalidade"
                        item-text="DsNome"
                        :rules="VNacionalidade"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.TbClienteVenda.DsProfissao"
                        type="text"
                        :rules="ChProfissaoRules"
                        label="Profissão"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="#95926a" style="color: white" @click="step = 2">
                <v-icon>mdi-account-arrow-right</v-icon>
                Proximo
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form
                @submit.prevent="adicionarContato"
                ref="formContato"
                v-model="validFormContato"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="11" sm="11">
                      <v-text-field
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="nrTelefone"
                        @keyup.enter="adicionarContato"
                        type="text"
                        label="Adicionar telefone"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="1" sm="1">
                      <v-btn
                        fab
                        dark
                        small
                        color="#95926a"
                        @click="adicionarContato"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="VContatoErro" style="margin-top: -30px">
                    <v-col cols="12" sm="12">
                      <v-alert dense outlined type="error">
                        {{ VContatoMensagem }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Telefones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in form.TbClienteVenda
                                .TbClienteContatoes"
                              :key="index"
                            >
                              <td class="text-left">
                                {{ item.DsContato }}
                                <v-btn
                                  icon
                                  color="pink"
                                  style="margin-left: 5px"
                                  @click="
                                    form.TbClienteVenda.TbClienteContatoes.splice(
                                      index,
                                      1
                                    )
                                  "
                                >
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <br />
              <v-btn color="#95926a" style="color: white" @click="step = 1">
                <v-icon>mdi-account-arrow-left</v-icon>
                Voltar
              </v-btn>

              <v-btn
                color="#95926a"
                @click="step = 3"
                style="margin-left: 10px; color: white"
              >
                <v-icon>mdi-account-arrow-right</v-icon>
                Proximo
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form
                ref="formDadosVenda"
                v-model="validformDadosPessoais"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="4" sm="4">
                      <v-select
                        :items="$ListarEmpresas()"
                        v-model="form.FkTbEmpresa"
                        item-value="IdTbEmpresa"
                        item-text="DsNomeFantasia"
                        label="Empresa"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <el-select
                        style="width: 100%"
                        v-model="form.FkTbFilial"
                        placeholder="Filial"
                      >
                        <el-option
                          v-for="item in $ListarFilialPorEmpresa(
                            form.FkTbEmpresa
                          )"
                          :key="item.IdTbFilial"
                          :label="item.DsSigla"
                          :value="item.IdTbFilial"
                        >
                          <span style="float: left">{{ item.DsSigla }}</span>
                          <span
                            style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            "
                            >{{ item.DsCidade }}</span
                          >
                        </el-option>
                      </el-select>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <el-select
                        style="width: 100%"
                        v-model="form.FkTbUsuarioVenda"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Consultor"
                        :remote-method="buscarUsuarios"
                        :loading="pesquisaVendedorloading"
                      >
                        <el-option
                          v-for="item in listarUsuarios"
                          :key="item.IdTbUsuario"
                          :label="item.DsNome"
                          :value="item.IdTbUsuario"
                        >
                        </el-option>
                      </el-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="2" sm="2">
                      <v-select
                        :items="listaOrigem"
                        v-model="form.DsOrigem"
                        item-value="DsNome"
                        item-text="DsNome"
                        label="Origem"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="2" sm="2">
                      <v-select
                        :items="$ListarPeriodo()"
                        v-model="form.FkTbPeriodo"
                        label="Período"
                        item-value="IdTbPeriodo"
                        item-text="DsNome"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-select
                        :items="$ListarTipoVenda()"
                        v-model="form.FkTbTipoVenda"
                        label="Tipo de venda"
                        item-value="IdTbTipoVenda"
                        item-text="DsNome"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-select
                        :items="$ListarProdutos()"
                        v-model="listaProdutos"
                        item-value="IdTbProduto"
                        item-text="DsNome"
                        multiple
                        label="Produto"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="6" md="6">
                      <br />
                      <v-text-field
                        v-model.lazy="form.VlVenda"
                        v-money="money"
                        type="text"
                        label="Valor"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <label left>Data da venda:</label>
                      <br />
                      <div class="block">
                        <el-date-picker
                          v-model="form.DtVenda"
                          type="date"
                          format="dd/MM/yyyy"
                          style="width: 100%"
                          placeholder="Data da venda"
                        ></el-date-picker>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="6" md="6">
                      <label left>Data de agendamento:</label>
                      <br />
                      <div class="block">
                        <el-date-picker
                          v-model="form.DtAgendamento"
                          type="date"
                          format="dd/MM/yyyy"
                          style="width: 100%"
                          placeholder="Data de agendamento"
                        ></el-date-picker>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <label left>Data Prev. instalação:</label>
                      <br />
                      <div class="block">
                        <el-date-picker
                          v-model="form.DtPrevistaInstalacao"
                          type="date"
                          format="dd/MM/yyyy"
                          style="width: 100%"
                          placeholder="Data Prev. instalação"
                        ></el-date-picker>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="#95926a" style="color: white" @click="step = 2">
                <v-icon>mdi-account-arrow-left</v-icon>
                Voltar
              </v-btn>

              <v-btn
                color="#95926a"
                @click="step = 4"
                style="color: white; margin-left: 10px"
              >
                <v-icon>mdi-account-arrow-right</v-icon>
                Proximo
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-form
                ref="formEnderecoInstalacao"
                v-model="validFormEnderecoInstalacao"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="3" sm="3">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.NrCep"
                        :rules="NrCepRules"
                        v-mask="['#####-###']"
                        @blur="buscaCepInstalacao"
                        max="9"
                        label="CEP"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="7" sm="7">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.DsLogradouro"
                        type="text"
                        label="Logradouro "
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.NrLogradouro"
                        type="text"
                        :rules="NrLogradouroRules"
                        label="Numero"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.DsBairro"
                        type="text"
                        label="Bairro"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.DsCidade"
                        type="text"
                        label="Cidade"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2">
                      <v-select
                        :items="$ListarEstado()"
                        v-model="form.TbEnderecoInstalacaoVenda.FkTbEstado"
                        label="UF"
                        item-value="IdTbEstado"
                        item-text="DsUF"
                        :rules="VEstado"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.TbEnderecoInstalacaoVenda.DsComplemento"
                        type="text"
                        label="Complemento"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="#95926a" style="color: white" @click="step = 3">
                <v-icon>mdi-account-arrow-left</v-icon>
                Voltar
              </v-btn>

              <v-btn
                color="#95926a"
                @click="step = 5"
                style="color: white; margin-left: 10px"
              >
                <v-icon>mdi-account-arrow-right</v-icon>
                Proximo
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-form
                ref="formEnderecoCobranca"
                v-model="validFormEnderecoCobranca"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="3" sm="3">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.NrCep"
                        :rules="NrCepRules"
                        v-mask="['#####-###']"
                        @blur="buscaCepCobranca"
                        max="9"
                        label="CEP"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="7" sm="7">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.DsLogradouro"
                        type="text"
                        label="Logradouro "
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.NrLogradouro"
                        type="text"
                        :rules="NrLogradouroRules"
                        label="Numero"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.DsBairro"
                        type="text"
                        label="Bairro"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.DsCidade"
                        type="text"
                        label="Cidade"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2">
                      <v-select
                        :items="$ListarEstado()"
                        v-model="form.TbEnderecoCobrancaVenda.FkTbEstado"
                        label="UF"
                        item-value="IdTbEstado"
                        item-text="DsUF"
                        :rules="VEstado"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -30px">
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="form.TbEnderecoCobrancaVenda.DsComplemento"
                        type="text"
                        label="Complemento"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="#95926a" style="color: white" @click="step = 4">
                <v-icon>mdi-account-arrow-left</v-icon>
                Voltar
              </v-btn>

              <v-btn
                color="success"
                style="margin-left: 10px"
                @click="cadastrarVenda"
              >
                <v-icon>mdi-account-check</v-icon>
                Finalizar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <notifications group="venda" />
    <notifications group="cep" />
  </div>
</template>

<script>
import apiCep from "@/services/buscaCep";
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        submitPUT: true,
        date: new Date().toISOString().substr(0, 10),
        menu: false,
        step: 1,
        validformDadosPessoais: true,
        validFormContato: true,
        validFormEnderecoInstalacao: true,
        validFormEnderecoCobranca: true,
        listarUsuarios: [],
        pesquisaVendedorloading: false,
        busca: "",
        items: [
          {
            text: "Nova Venda",
            disabled: true,
            href: "/vendas",
          },
        ],
        listaSexo: [
          {
            tipo: true,
            DsNome: "Masculino",
          },
          {
            tipo: false,
            DsNome: "Feminino",
          },
        ],
        listaOrigem: [
          {
            DsNome: "NET",
          },
          {
            DsNome: "Claro",
          },
        ],
        nrTelefone: "",
        listaProdutos: [],
        money: {
          decimal: ",",
          thousands: ".",
          prefix: "", //R$ mask to left
          suffix: "", //#$ mask to rigth
          precision: 2,
          masked: true,
        },
        formbuscaUsuario: {
          DsBusca: "",
          MdFiltroData: {},
          MdPaginacao: {
            PaginaAtual: 1,
            ItensPorPagina: 0,
            TotalPaginas: 0,
            TotalItens: 0,
          },
          MdModel: {
            BtAtivo: true,
          },
        },
        form: {
          NrProtocolo: null,
          NrContrato: null,
          NrProposta: null,
          FkTbPeriodo: 0,
          FkTipoVenda: 0,
          FkTbUsuarioVenda: "",
          FkTbEmpresa: 0,
          FkTbFilial: null,
          FkTbNacionalidade: 0,
          FkTbEstadoCivil: 0,
          FkTbTipoVenda: 0,
          BtEntradaAplicativo: false,
          BtEntradaManual: true,
          DtAgendamento: "",
          DtPrevistaInstalacao: "",
          DtVenda: "",
          DsOrigem: "",
          DsObservacao: "",
          VlVenda: 0,
          TbVendaVendaProdutoes: [],
          TbClienteVenda: {
            FkTbEstadoCivil: 0,
            FkTbNacionalidade: 0,
            DsNome: "",
            DsCpfCnpj: "",
            DsRg: "",
            DtExpedicaoRg: "",
            DtNascimento: "",
            BtSexo: true,
            DsNomeMae: "",
            DsNomePai: "",
            DsEmail: "",
            DsProfissao: "",
            TbClienteContatoes: [],
          },
          TbEnderecoInstalacaoVenda: {
            FkTbEstado: "",
            DsLogradouro: "",
            NrLogradouro: "",
            DsComplemento: "",
            NrCep: "",
            DsCidade: "",
            DsBairro: "",
          },
          TbEnderecoCobrancaVenda: {
            FkTbEstado: "",
            DsLogradouro: "",
            NrLogradouro: "",
            DsComplemento: "",
            NrCep: "",
            DsCidade: "",
            DsBairro: "",
          },
        },

        DsNomeRules: [(v) => !!v || "Nome é obrigatório"],
        // ChCpfRules: [
        //     v => !!v || "CPF é obrigatório",
        //     v => (v && v.length === 18) || "CPF deve conter 18 caracteres"
        // ],
        ChRgRules: [(v) => !!v || "RG é obrigatório"],
        ChProfissaoRules: [(v) => !!v || "Profissão é obrigatório"],
        NrCepRules: [(v) => !!v || "CEP é obrigatório"],
        NrLogradouroRules: [(v) => !!v || "Número é obrigatório"],
        VNacionalidade: [(v) => !!v || "Nacionalidade é obrigatório"],
        VEstado: [(v) => !!v || "Estado é obrigatório"],
        VEstadoCivil: [(v) => !!v || "Estado civil é obrigatório"],
        VContatoMensagem: "Deve adicionar pelo menos um telefone para contato",
        VContatoErro: false,
      };
    },

    formatarValores() {
      this.form.VlVenda = parseFloat(
        this.form.VlVenda.replace(/\./g, "").replace(",", ".")
      );
      this.form.NrProtocolo = parseInt(this.form.NrProtocolo);
      this.form.NrContrato = parseInt(this.form.NrContrato);
      this.form.NrProposta = parseInt(this.form.NrProposta);
      this.listaProdutos.forEach((item) => {
        this.form.TbVendaVendaProdutoes.push({
          FkTbProduto: item,
        });
      });
    },

    buscarUsuarios(query) {
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      this.formbuscaUsuario.DsBusca = query;
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => {});
    },

    validarFormulario() {
      if (!this.$refs.formDadosPessoais.validate()) {
        return 1;
      } else if (!this.$refs.formContato.validate()) {
        return 2;
      } else if (
        !this.$refs.formDadosVenda.validate() &&
        this.form.TbAnuncioMidia.length < 0
      ) {
        return 3;
      } else if (!this.$refs.formEnderecoInstalacao.validate()) {
        return 4;
      } else if (!this.$refs.formEnderecoCobranca.validate()) {
        return;
      }
    },

    adicionarContato() {
      if (this.nrTelefone === "") {
        return;
      }
      this.form.TbClienteVenda.TbClienteContatoes.push({
        DsContato: this.nrTelefone,
      });
      this.nrTelefone = "";
      this.VContatoErro = false;
    },

    buscaEstadoCobranca(uf) {
      this.$ListarEstado().forEach((value) => {
        if (value.DsUF === uf) {
          this.form.TbEnderecoCobrancaVenda.FkTbEstado = value.IdTbEstado;
        }
      });
    },

    buscaEstadoInstalacao(uf) {
      this.$ListarEstado().forEach((value) => {
        if (value.DsUF === uf) {
          this.form.TbEnderecoInstalacaoVenda.FkTbEstado = value.IdTbEstado;
        }
      });
    },

    buscaCepCobranca() {
      apiCep
        .get(this.form.TbEnderecoCobrancaVenda.NrCep)
        .then(
          function (response) {
            let endereco = response.data;
            this.form.TbEnderecoCobrancaVenda.DsLogradouro = endereco.street;
            this.form.TbEnderecoCobrancaVenda.DsBairro = endereco.neighborhood;
            this.form.TbEnderecoCobrancaVenda.DsCidade = endereco.city;
            this.buscaEstadoCobranca(endereco.state);
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "cep",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },

    buscaCepInstalacao() {
      apiCep
        .get(this.form.TbEnderecoInstalacaoVenda.NrCep)
        .then(
          function (response) {
            let endereco = response.data;
            this.form.TbEnderecoInstalacaoVenda.DsLogradouro = endereco.street;
            this.form.TbEnderecoInstalacaoVenda.DsBairro =
              endereco.neighborhood;
            this.form.TbEnderecoInstalacaoVenda.DsCidade = endereco.city;
            this.buscaEstadoInstalacao(endereco.state);
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "cep",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },
    cadastrarVenda() {
      this.step = this.validarFormulario();
      this.formatarValores();
      if (
        this.$refs.formDadosPessoais.validate() &&
        this.$refs.formContato.validate() &&
        this.$refs.formDadosVenda.validate() &&
        this.$refs.formEnderecoInstalacao.validate() &&
        this.$refs.formEnderecoCobranca.validate()
      ) {
        api
          .post("venda/cadastrar", this.form, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.$GetToken(),
            },
          })
          .then(
            function (response) {
              this.$notify({
                type: "success",
                duration: 1000,
                speed: 1000,
                group: "venda",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text:
                  '<p style="font-size: 12pt">' +
                  response.data.message +
                  "</p>",
              });

              Object.assign(this.$data, this.initialState());
            }.bind(this)
          )
          .catch((error) => {
            this.$notify({
              type: "error",
              duration: 1000,
              speed: 1000,
              group: "venda",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' +
                error.response.data.message +
                "</p>",
            });
          });
      }
    },
  },

  watch: {
    busca() {
      if (this.busca.length > 3 && this.busca !== null) this.buscarUsuarios();
    },
  },
};
</script>