<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="visible"
          transition="dialog-top-transition"
          max-width="400"
        >
          <v-card>
            <v-toolbar color="#53513b" dark>Alterar senha</v-toolbar>
            <v-card-text>
              <br/>
              <div>
                Nome do usuário: {{ usuario.DsNome }}
              </div>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="form.DsSenha"
                        :rules="senhaRules"
                        label="Nova senha"
                        type="password"
                        required
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model="form.confirmaSenha"
                        :rules="confirmaSenhaRules"
                        label="Confirma senha"
                         type="password"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

             <v-card-actions class="justify-center" style="margin-top: -40px">
              <v-btn text color="pink" @click="$emit('CLOSE')">Cancelar</v-btn>
              <v-btn text color="green" @click="submit">Confirmar</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <notifications group="user" />
  </div>
</template>

<script>
import axios from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    usuario: { type: Object, required: true }
  },

  methods: {
    initialState(){
       return {
      valid: true,
      form: {
        IdTbUsuario: "",
        DsSenha: "",
        confirmaSenha: ""
      },
      senhaRules: [
        v => !!v || "Senha é obrigatório",
        v => (v && v.length >= 6) || "Senha deve ser maior que 6 caracteres"
      ],

      confirmaSenhaRules: [
        v => !!v || "Confirmação da senha é obrigatório",
        v =>
          (v && v.length >= 2) ||
          "Confirmação da senha deve conter 6 caracteres"
      ]
    };
    },
    close() {
      this.$emit("CLOSE");
    },

    validarSenha(){
      if(this.form.DsSenha !== this.form.confirmaSenha){
         this.$notify({
              type: "error",
              duration: 1000,
              speed: 1000,
              group: "user",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text: '<p style="font-size: 12pt">As senhas digitadas estão divergentes</p>'
            });

            return false;
      }
      else{
        return true;
      }
    },

    submit() {
      if(this.validarSenha()){
        if (this.$refs.form.validate()) {
        axios
          .post("usuario/AdminAlterarSenha", this.form,{
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }})
          .then(resp => {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "user",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text: '<p style="font-size: 12pt">' + resp.data.message + "</p>"
            });

            this.close()
          })
          .catch(error => {
            this.$notify({
              type: "error",
              duration: 1000,
              speed: 1000,
              group: "user",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' +
                error.response.data.message +
                "</p>"
            });
          });
      }
      
      }
    }
  },

  watch: {
    visible() {
       Object.assign(this.$data, this.initialState());
       this.form.IdTbUsuario = this.usuario.IdTbUsuario;
    }
  }
};
</script>

<style>
</style>

