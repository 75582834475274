<template>
  <v-row justify="center">
    <v-dialog
      v-model="visible"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          <!-- {{$store.state.aplicationName}} -->
        </v-card-title>
        <v-card-text>
           {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink darken-1" text @click="cancelar">
            Cancelar
          </v-btn>
          <v-btn  color="green darken-1" text @click="confirmar">
            Confirmar
          </v-btn>
             <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    props:{
        visible: {type: Boolean},
        message: {type: String}
    },
    methods:{
        confirmar(){
            this.$emit('CONFIRMAR')
        },

        cancelar(){
            this.$emit('CLOSE')
        }
    }
  }
</script>