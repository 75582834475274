<template>
  <div style="width: 100%; height: 450px; margin-top: -5px" ref="mapa"></div>
</template>

<script>
export default {
  props: {
    localizacaoVendedores: { type: Array }
  },
  data() {
    return {
      listaGrupos: [],
      zoomMapa: 0,
      platform: {},
      apikey: this.$store.state.apiKeyMaps,
      map: {},
      ui: {},
      center: { lng: "-69.6996757", lat: "-13.7017905" }
    };
  },

  mounted() {
    this.platform = new window.H.service.Platform({ apikey: this.apikey });
    this.inicializarMapa();
  },

  methods: {
    inicializarMapa() {
      if (this.localizacoes != null && this.localizacoes != []) {
        this.localizacoes.forEach(element => {
          if (element != null) {
            this.center.lng = element.NrLongitude;
            this.center.lat = element.NrLatitude;
          }
        });
      }

      const mapContainer = this.$refs.mapa;
      const H = window.H;
      var maptypes = this.platform.createDefaultLayers();
      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: this.zoomMapa,
        center: this.center
      });
      addEventListener("resize", () => this.map.getViewPort().resize());
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
      this.ui = H.ui.UI.createDefault(this.map, maptypes);
    },

    dataAtualFormatada(stringData) {
      let data = new Date(stringData),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(),
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      let hora = data.getHours();
      let minutos = data.getMinutes();
      let segundos = data.getSeconds();
      return (
        diaF +
        "/" +
        mesF +
        "/" +
        anoF +
        " " +
        hora +
        ":" +
        this.padLeft("00", minutos, "l") +
        ":" +
        this.padLeft("00", segundos, "l")
      );
    },

    padLeft(pad, user_str, pad_pos) {
      if (typeof user_str === "undefined") return pad;
      else if (pad_pos == "l") return (pad + user_str).slice(-pad.length);
      else return (user_str + pad).substring(0, pad.length);
    },

    adicionarMarcadores(vendedor) {
      const H = window.H;
      let pngIcon = new H.map.Icon(require(`@/assets/pin.png`), {
        size: { w: 30, h: 30 }
      });
      let marcador = new H.map.Marker(
        { lng: vendedor.NrLongitude, lat: vendedor.NrLatitude },
        { icon: pngIcon }
      );
      marcador.setData(`<div style="width: 10vw; text-align: left">
                                <p> ${vendedor.TbUsuarioLocalizacao.DsNome}</p>
                                <p style="margin-top: -15px">${this.dataAtualFormatada(
                                  vendedor.DtCriacao
                                )}</p>
                          </div>`);

      // marcador.addEventListener("tap", event => {
      //   var bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
      //     content: event.target.getData()
      //   });
      //   this.ui.addBubble(bubble);
      // });
      let bubble;
      marcador.addEventListener("pointerenter", event => {
        bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
          content: event.target.getData()
        });
        this.ui.addBubble(bubble);
      });
      marcador.addEventListener(
        "pointerleave",
        () => {
          const interval = setInterval(() => {
            bubble.close();
            clearInterval(interval);
          }, 800);
        },
        false
      );
      this.map.addObject(marcador);
      this.listaGrupos.push(marcador);
    }
  },

  computed: {
    localizacoes() {
      return this.localizacaoVendedores;
    }
  },

  watch: {
    localizacoes() {
      this.map.removeObjects(this.map.getObjects());
      const H = window.H;
      let group = new H.map.Group();
      this.listaGrupos = [];
      if (this.localizacoes != null && this.localizacoes != []) {
        this.localizacoes.forEach(vendedor => {
          if (vendedor != null) {
            this.adicionarMarcadores(vendedor);
          }
        });
      }

      group.addObjects(this.listaGrupos);
      this.map.addObject(group);
      this.map.getViewPort().setPadding(50, 50, 50, 50);
      this.map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox()
      });
    }
  }
};
</script>

<style scoped>
#map {
  width: 75vw;
  min-width: 360px;
  text-align: center;
  margin: 5% auto;
  background-color: #ccc;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
