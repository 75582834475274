import axios from 'axios'

const buscaCep = axios.create({
    baseURL: 'https://brasilapi.com.br/api/cep/v1/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default buscaCep