<template>
  <div v-if="this.$VerificaPermissaoUsuario(4)">
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-row style="margin-top: -50px">
        <v-col cols="12" sm="8"></v-col>
        <v-col cols="12" sm="4" style="text-align: right">
          <el-radio-group v-model="form.FiltroDataVenda" fill="#53513b" size="mini">
            <el-radio-button :label="true">Data de cadastro</el-radio-button>
            <el-radio-button :label="false">Data de instalação</el-radio-button>
          </el-radio-group>
        </v-col>
      </v-row>
      <v-card class="mx-auto" color="#53513b">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="5" md="5" lg="5">
              <el-select
                v-model="form.FkTbEmpresa"
                placeholder="Empresa"
                multiple
                style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              >
                <el-option
                  v-for="item in $ListarEmpresas()"
                  :key="item.IdTbEmpresa"
                  :label="item.DsNomeFantasia"
                  :value="item.IdTbEmpresa"
                >
                  <span style="float: left">{{ item.DsNomeFantasia }}</span>
                </el-option>
              </el-select>
            </v-col>

            <v-col cols="12" sm="2" md="2" lg="2">
              <v-select
                style="width: 100%"
                background-color="white"
                :items="listaAno"
                v-model="anoSelecionado"
                item-value="DsValor"
                item-text="DsNome"
                label="Ano"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3">
              <v-select
                style="width: 100%"
                background-color="white"
                :items="meses"
                v-model="mesSelecionado"
                item-value="DsValor"
                item-text="DsNome"
                label="Mês"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2">
              <v-btn style="width: 100%; color:white" color="#95926a" @click="listar">Atualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <br />
      <v-card>
        <v-app-bar color="#53513b" dense dark>
          <v-toolbar-title>Vendas</v-toolbar-title>
        </v-app-bar>
        <el-collapse accordion>
          <el-collapse-item
            name="geral"
            v-if="MdDahboard.MdQtdVendasGeral != null && MdDahboard.MdQtdVendasGeral.length > 0"
          >
            <template slot="title">
              <div style="margin-left: 20px">GERAL</div>
            </template>
            <QuantidadeVendas :items="MdDahboard.MdQtdVendasGeral" v-loading="loading" />
          </el-collapse-item>
          <el-collapse-item v-for="(item, i) in MdDahboard.MdQtdVendas" :name="i" :key="i">
            <template slot="title">
              <div style="margin-left: 20px">{{$PesquisaEmpresa(item[0].FkTbEmpresa)}}</div>
            </template>
            <QuantidadeVendas :items="item" v-loading="loading" />
          </el-collapse-item>
        </el-collapse>
      </v-card>

      <br />
      <ChurnTable :dados="MdDahboard.MdChurn" v-loading="loading" />

      <br />
      <br />
      <v-card v-loading="loading">
        <v-app-bar color="#53513b" dense dark>
          <v-toolbar-title>Velocidade de Vendas</v-toolbar-title>
        </v-app-bar>
        <el-collapse>
          <el-collapse-item
            :name="item.FkTbEmpresa"
            v-for="item in MdDahboard.MdVelocidadeVendas"
            :key="item.FkTbEmpresa"
          >
            <template slot="title">
              <div style="margin-left: 20px">{{$PesquisaEmpresa(item.FkTbEmpresa)}}</div>
            </template>
            <VelocidadeVendas :mdVelocidadeVendas="item" />
          </el-collapse-item>
        </el-collapse>
      </v-card>
    </v-container>

    <notifications group="dashboard" />
  </div>
</template>

<script>
import api from "@/services/api";
import QuantidadeVendas from "./QuantidadeVendas";
import VelocidadeVendas from "./VelocidadeVendas";
import ChurnTable from "./ChurnTable";
export default {
  components: {
    QuantidadeVendas,
    VelocidadeVendas,
    ChurnTable
  },

  data() {
    return {
      loading: false,
      radio: 1,
      mesSelecionado: "",
      anoSelecionado: "",
      rangerDate: [],
      meses: [
        { DsNome: "Janeiro", DsValor: "01" },
        { DsNome: "Fevereiro", DsValor: "02" },
        { DsNome: "Março", DsValor: "03" },
        { DsNome: "Abril", DsValor: "04" },
        { DsNome: "Maio", DsValor: "05" },
        { DsNome: "Junho", DsValor: "06" },
        { DsNome: "Julho", DsValor: "07" },
        { DsNome: "Agosto", DsValor: "08" },
        { DsNome: "Setembro", DsValor: "09" },
        { DsNome: "Outubro", DsValor: "10" },
        { DsNome: "Novembro", DsValor: "11" },
        { DsNome: "Dezembro", DsValor: "12" }
      ],
      listaAno: [
        { DsNome: "2020", DsValor: "2020" },
        { DsNome: "2021", DsValor: "2021" },
        { DsNome: "2022", DsValor: "2022" },
        { DsNome: "2023", DsValor: "2023" },
        { DsNome: "2024", DsValor: "2024" },
        { DsNome: "2025", DsValor: "2025" },
        { DsNome: "2026", DsValor: "2026" },
        { DsNome: "2027", DsValor: "2027" },
        { DsNome: "2028", DsValor: "2028" },
        { DsNome: "2029", DsValor: "2029" },
        { DsNome: "2030", DsValor: "2030" }
      ],
      MdDahboard: {
        MdQtdVendas: null,
        MdQtdVendasGeral: null,
        MdVelocidadeVendas: null,
        MdChurn: null
      },
      form: {
        FkTbEmpresa: "",
        FiltroDataVenda: false,
        MdFiltroData: {
          DtInicio: "",
          DtFim: ""
        }
      },
      items: [
        {
          text: "Dashboard/Empresas",
          disabled: true,
          href: "/"
        }
      ]
    };
  },
  methods: {
    validarDatas() {
      let dataInicial = this.formatarData(this.rangerDate[0].split("-"));
      let dataFinal = this.formatarData(this.rangerDate[1].split("-"));
      let quatidadeDeDiasNoMes = new Date(
        dataInicial.getFullYear(),
        dataInicial.getMonth(),
        0
      ).getDate();
      let timeDiff = Math.abs(dataFinal.getTime() - dataInicial.getTime()) + 1;
      let quantidadeDeDiasSelecionado = Math.ceil(
        timeDiff / (1000 * 3600 * 24)
      );

      if (quantidadeDeDiasSelecionado <= quatidadeDeDiasNoMes) return true;
      else return false;
    },
    formatarData(date) {
      return new Date(date[0], date[1], date[2]);
    },
    getDate() {
      const today = new Date();
      const date = new Date(today.getFullYear(), this.mesSelecionado, 0);
      const ultimoDiaDoMes = date.getDate();
      const dateInicial =
        this.anoSelecionado + "-" + this.mesSelecionado + "-01";
      const dateFinal =
        this.anoSelecionado + "-" + this.mesSelecionado + "-" + ultimoDiaDoMes;
      this.dataAtual = today;
      this.rangerDate = [dateInicial, dateFinal];
    },
    listar() {
      this.getDate();

      if (this.rangerDate.length > 0) {
        this.form.MdFiltroData.DtInicio = new Date(this.rangerDate[0]);
        this.form.MdFiltroData.DtFim = new Date(this.rangerDate[1]);
      }

      this.loading = true;

      if (this.form.FkTbEmpresa === "") {
        this.form.FkTbEmpresa = 0;
      }

      if (this.form.FkTbFilial === "") {
        this.form.FkTbFilial = 0;
      }

      api
        .post("indicadores/dashboard-empresas", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(resp => {
          this.MdDahboard.MdQtdVendas = resp.data.MdQtdVendas;
          this.MdDahboard.MdQtdVendasGeral = resp.data.MdQtdVendasGeral;
          this.MdDahboard.MdVelocidadeVendas = resp.data.MdVelocidadeVendas;
          this.MdDahboard.MdChurn = resp.data.MdChurn;
          this.loading = false;
        })
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "auth",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
          this.loading = false;
        });
    }
  },

  mounted() {
    this.getDate();
    this.form.MdFiltroData.DtInicio = new Date(this.rangerDate[0]);
    this.form.MdFiltroData.DtFim = new Date(this.rangerDate[1]);
  }
};
</script>

<style scoped>
</style>
