import Vue from 'vue';

Vue.prototype.$Codificar = function (valor) {
    return GerarHash(55) + btoa(valor) + GerarHash(20);
}

Vue.prototype.$Decodificar = function (valorCodificado) {
    let posicao1 = valorCodificado.substring(0, 55);
    let posicao2 = valorCodificado.substring(valorCodificado.length - 20, valorCodificado.length)
    return atob(valorCodificado.replace(posicao1, '').replace(posicao2, ''));
}

function GerarHash(tamanho) {
    var result = '';
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = tamanho; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}
