<template>
  <v-dialog v-model="visible" persistent width="620">
    <v-card>
      <v-toolbar color="#53513b" dark>
        <v-row>
          <v-col cols="10" style="text-align: left; margin-top: 5px">
            {{ title }}
          </v-col>

          <v-col cols="2">
            <v-btn text @click="close">
              Fechar
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-card-text>
        <br />
        <br />
        <v-file-input
          v-model="arquivo"
          color="deep-purple accent-4"
          counter
          accept="image/png, image/jpeg, image/bmp"
          label="Logo da empresa"
          placeholder="Clique aqui para selecionar uma imagem"
          prepend-icon="mdi-camera-iris"
          outlined
          @change="carregarImagen"
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 15"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
        <cropper :src="imagem" @change="change" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background: #53513b; color: white;" @click="submit">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import api from "@/services/api";
export default {
  components: {
    Cropper
  },
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean },
    IdTbEmpresa: { type: String }
  },

  methods: {
    initialState() {
      return {
        title: "Logo da empresa",
        arquivo: null,
        imagem: null,
        coordinates: null,
        form: {
          logo: null
        }
      };
    },
    carregarImagen() {
      var reader = new FileReader();
      reader.readAsDataURL(this.arquivo);
      reader.onload = () => (this.imagem = reader.result);
    },
    close() {
      this.$emit("CLOSE");
    },
    change({ coordinates, canvas }) {
      this.coordinates = coordinates;
      this.form.logo = canvas.toDataURL();
    },
    submit() {
      api
        .post(
          "empresa/logoempresa",
          { IdTbEmpresa: this.IdTbEmpresa, DsArquivoLogo: this.imagem },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.$GetToken()
            }
          }
        )
        .then(
          function() {
            this.close();
          }.bind(this)
        )
        .catch(() => {});
    }
  }
};
</script>

<style>
</style>