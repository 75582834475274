<template>
  <div>
    <v-dialog v-model="visible" persistent width="400">
      <v-card>
        <v-toolbar color="#636146" dark>
          <v-row>
            <v-col cols="8" style="text-align: left; margin-top: 5px">Campanha</v-col>

            <v-col cols="2">
              <v-btn text @click="close">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    style="width: 100%"
                    v-model="form.DsNome"
                    type="text"
                    label="Nome"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -35px;">
                <v-col cols="12" sm="12">
                  <v-select
                    :items="$ListarProdutos()"
                    v-model="listaProdutos"
                    item-value="IdTbProduto"
                    item-text="DsNome"
                    multiple
                    label="Produto"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions style="margin-top: -30px">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close">Cancelar</v-btn>
              <v-btn style="background: #95926a; color: white;" @click="submit">Salvar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="meta" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true }
  },

  methods: {
    initialState() {
      return {
        listaProdutos: [],
        form: {
          DsNome: "",
          TbMetaDinamicaMetaDinamicaProdutoes: []
        }
      };
    },

    close() {
      this.$emit("CLOSE");
    },

    montarFormulario() {
      this.listaProdutos.forEach(item => {
        this.form.TbMetaDinamicaMetaDinamicaProdutoes.push({
          FkTbProduto: item
        });
      });
    },

    submit() {
      this.montarFormulario();

      api
        .post("metadinamica/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "meta",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "meta",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>

<style>
</style>