<template>
  <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          Carregando...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="#53513b"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>