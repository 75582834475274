<template>
  <div>
    <v-item-group>
      <v-container>
        <v-row>
          <v-col cols="12" sm="9" md="9" lg="9">
            <v-text-field
              style="width: 100%"
              v-model="form.DsBusca"
              @keyup.enter="listarVendas"
              label="Contrato/Proposta/Nome/Email/CPF/CNPJ/RG"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" lg="2">
            <el-select
              v-model="form.FilterType"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="item in listFilterTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </v-col>
          <v-col>
            <el-button
              type="warning"
              icon="el-icon-search"
              circle
              @click="listarVendas"
            ></el-button>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>

    <v-card v-loading="loading">
      <table style="width: 100%" v-if="listaTbVendas.length">
        <tr>
          <th style="text-align: left; width: 50%; padding: 10px 30px">
            Cliente
          </th>
          <th style="text-align: right; width: 50%; padding-right: 20px">
            Data da venda
          </th>
        </tr>
      </table>
      <el-collapse accordion v-loading="loading">
        <el-collapse-item
          v-for="(item, index) in listaTbVendas"
          :name="index"
          :key="item.IdTbVenda"
        >
          <template slot="title">
            <div style="margin-left: 20px">
              <v-btn icon>
                <v-icon>mdi-account</v-icon>
              </v-btn>
              {{ item.TbClienteVenda.DsNome }}
            </div>
            <v-spacer></v-spacer>
            <div style="margin-right: 30px">
              {{ item.DtVenda | moment("DD/MM/YYYY") }}
            </div>
          </template>
          <div style="padding: 30px">
            <Detalhes :TbVenda="item" />
          </div>
        </el-collapse-item>
      </el-collapse>
    </v-card>

    <notifications group="venda" />
  </div>
</template>

<script>
import api from "@/services/api";
import Detalhes from "./Detalhes";
export default {
  components: {
    Detalhes,
  },
  data() {
    return {
      loading: false,
      listaTbVendas: [],
      listFilterTypes: [
        { name: "Contrato", value: "NrContrato" },
        { name: "Proposta", value: "NrProposta" },
        { name: "Nome", value: "DsNome" },
        { name: "Email", value: "DsEmail" },
        { name: "CPF/CNPJ", value: "DsCpfCnpj" },
        { name: "RG", value: "DsRg" },
      ],
      form: {
        DsBusca: "",
        MdFiltroData: null,
        FilterType: "Pesquisar por",
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
        MdModel: {
          IdTbVenda: 0,
          FkTbUsuarioVenda: 0,
          DsObservacao: "",
          FkTbVendaStatus: 0,
          FkTbEmpresa: 0,
          FkTbFilial: 0,
          FkTbPeriodo: 0,
          BtAtivo: true,
        },
      },
    };
  },

  methods: {
    listarVendas() {
      this.listaTbVendas = [];
      scroll(0, 0);
      this.loading = true;
      api
        .post("venda/localizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listaTbVendas = response.data.TbVenda;
            this.form.MdPaginacao = response.data.MdPaginacao;
            this.loading = false;
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "venda",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>