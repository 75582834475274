import Vue from 'vue'
import VueRouter from 'vue-router'
import RotasAutenticadas from './RotasAutenticadas'
import Login from '../views/Login.vue'
import AppIndex from '../views/AppIndex.vue'
import ResetarSenha from '../views/ResetarSenha.vue'
import AlterarSenha from '../views/AlterarSenha.vue'
Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) =>
{
  const user_auth = localStorage.getItem("uDht");
  const user_permissions = localStorage.getItem("uDhp");
  if (user_auth !== null && user_auth !== "" && user_permissions !== null && user_permissions !== "" )
  {
    next();
    return
  }
  next('/login')
};



const routes = [
  {
    path: '/login', name: 'login', component: Login
  },
  { path: '/resetar-senha', component: ResetarSenha, name: 'resetar-senha' },
  { path: '/alterarsenha/:DsChave', component: AlterarSenha, name: 'alterarsenha', props: true },
  {
    path: '/',
    name: 'App',
    component: AppIndex,
    beforeEnter : ifAuthenticated,
    children:
      [
        RotasAutenticadas
      ]

  },
];

export default new VueRouter({
  mode: 'history',
  routes
})
