<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer
        app
        clipped
        right
        width="412"
        mobile-breakpoint="350"
      >
        <div class="form-login">
          <v-form style="padding: 50px;" ref="form" v-model="valid" lazy-validation>
            <v-img
              width="200"
              :src="require(`@/assets/logo.png`)"
              style="display: inline-block; margin-bottom: 0px"
            ></v-img>
            <h4>DIN SERVICE</h4>
            <v-text-field v-model="form.DsEmail" :rules="loginRules" label="Login" required></v-text-field>
            <v-text-field 
            v-model="form.DsSenha" 
            type="password" 
            autocomplete="new-password"
            :rules="passwordRules" 
            label="Senha"
            required
            @keyup.enter="submit"
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              style="background-color: #c0be9f; color: white; width: 100%;"
              @click="submit"
            >Entrar</v-btn>
            <br>
            <br>
             <router-link to="/resetar-senha" style="text-decoration: none; color:#a6a376">Esqueci minha senha</router-link>
          </v-form>
        </div>
      </v-navigation-drawer>

      <v-main>
        <v-card class="mx-auto" color="grey lighten-4">
          <v-img :aspect-ratio="16/9" :src="require(`@/assets/background-login.jpeg`)" style="height: 99vh">
            <v-expand-transition>
              <div
                v-if="true"
                class="d-flex v-card--reveal"
                style="height: 30ch; background-color: #636146; opacity: 0.8;"
              >
                <v-banner two-line class="text-banner" style="color: white; font-size: 16pt">
                  <v-avatar slot="icon" color="#42412f">
                    <v-icon icon="mdi-lock" color="white">mdi-lock</v-icon>
                  </v-avatar>
                    Gestão de vendas Claro e NET.
                </v-banner>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-main>
    </v-app>
    <notifications group="auth" position="bottom right"/>
  </div>
</template>

<script>
import api from '../services/api'
export default {
  data(){
    return{
  loading: false,
    selection: 1,
    valid: true,
     form: {
        DsSenha: "",
        DsEmail: ""
      },
    passwordRules: [
      v => !!v || "Senha é obrigatória",
      v => (v && v.length >= 6) || "Senha deve ser maior que 8 caracteres"
    ],
    loginRules: [
      v => !!v || "E-mail é obrigatório",
      v => /.+@.+\..+/.test(v) || "E-mail invalido"
    ]
    };
  
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
       api
          .post("usuario/logar", this.form)
          .then(resp => {
             const token = "Bearer " + resp.data.TbUsuario.DsToken;
             const user = { 
                Us1: this.$Codificar(resp.data.TbUsuario.DsEmail), 
                Us2: this.$Codificar(resp.data.TbUsuario.DsNome),
                Us3: this.$Codificar(resp.data.TbUsuario.FkTbPerfil)
               };
             this.$store.commit("setUser", { user: user });
             localStorage.setItem("uDhs", JSON.stringify(user));
             localStorage.setItem("uDht", this.$Codificar(token));
             localStorage.setItem("uDhp", this.$Codificar(JSON.stringify(resp.data.TbUsuario.TbUsuarioUsuarioPermissaos)));
             localStorage.setItem("uTbi", this.$Codificar(JSON.stringify(resp.data.TabelasInicializacao)));
             this.$router.push('/');
          })
          .catch((error) => {
             this.$notify({
                type: 'error',
                duration: 1000,
                speed: 1000,
                group: 'auth',
                title: '<p style="font-size: 14pt">' + this.$store.state.aplicationName + '</p>',
                text: '<p style="font-size: 12pt">' + error.response.data.message + '</p>'
              });
          });
      }
    }
  }
};
</script>

<style>
body {
  text-align: center;
  background-color: #805aae;
}
.text-banner {
  text-align: left;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}
</style>