<template>
  <v-list-group :prepend-icon="icon" no-action class="router-link-item">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title v-text="name"></v-list-item-title>
      </v-list-item-content>
    </template>
    <router-link :to="child.router" class="router-link" v-for="child in data" :key="child.title">
      <v-list-item class="router-link-sub" @click="$AwaitLoading()">
        <v-list-item-icon>
          <v-icon>{{ child.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="child.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </router-link>
  </v-list-group>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  props: {
    name: { type: String, required: true },
    icon: { type: String, required: true },
    data: { type: Array, required: true },
  }
};
</script>

<style>
.router-link-sub {
  text-decoration: none;
}
.router-link-sub {
  margin-left: 20px;
}
.router-link-item {
  text-align: left;
}
</style>