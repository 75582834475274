<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <Table style="margin-top: -25px" />
    </v-container>
  </div>
</template>

<script>
import Table from "./Table";
export default {
  components: {
    Table
  },
  data() {
    return {
      listaEmpresas: [],
       items: [
        {
          text: "Relatorios/Empresa",
          disabled: true,
          href: "/relatorios"
        }
      ],
      busca: "",
      form: {
        FkTbEmpresa: 0,
        Busca: "",
        BtAtivo: true
      }
    };
  },
  methods: {
  }

};
</script>

<style>
</style>