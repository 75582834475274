<template>
  <v-app id="inspire">
    <Drawer />

    <v-app-bar app color="#A6A376">
      <v-spacer></v-spacer>
      <UserMenu />
    </v-app-bar>
    
    <v-main>
     <Container v-if="!$store.state.loading"/>
     <Loading v-if="$store.state.loading"/>
    </v-main>
    
  </v-app>
</template>

<script>
import Drawer from "@/components/layout/Drawer";
import UserMenu from "@/components/layout/UserMenu";
import Container from "@/components/layout/Container";
import Loading from "@/components/layout/Loading";
export default {
  name: "App",
  components: {
    UserMenu,
    Container,
    Drawer,
    Loading
  },
  data() {
    return {
      loading: true
    };
  },
};
</script>
