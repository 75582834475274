<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-select
              :items="$ListarEmpresas()"
              v-model="form.MdModel.FkTbEmpresa"
              item-value="IdTbEmpresa"
              item-text="DsNomeFantasia"
              label="Empresa"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3">
            <el-date-picker
              style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              v-model="form.MdFiltroData.DtInicio"
              format="dd/MM/yyyy"
              type="date"
              placeholder="Data inicial"
            ></el-date-picker>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3">
            <el-date-picker
              style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
              v-model="form.MdFiltroData.DtFim"
              format="dd/MM/yyyy"
              type="date"
              placeholder="Data Fim"
            ></el-date-picker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="11" md="11">
            <v-text-field
              v-model="form.DsBusca"
              @keyup.enter="listar"
              label="Pesquisar"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="1" md="1" lg="1">
            <v-btn color="#95926a" style="color:white; width: 100%" @click="listar">
              <v-icon dark>mdi-map-search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <el-collapse accordion v-loading="loading">
        <el-collapse-item v-for="(item, index) in listaLogs" :name="index" :key="item.DtCriacao">
          <template slot="title">
            <div style="margin-left: 20px">
              <v-btn icon>
                <v-icon>mdi-account</v-icon>
              </v-btn>
              {{(item.DsUsuario === '' ? 'Usuario não registrado' : item.DsUsuario)}}
            </div>
            <v-spacer></v-spacer>
            <div style="margin-right: 20px">{{item.DtCriacao}}</div>
          </template>
          <div style="text-align: left; padding-left: 60px">
            <p>{{item.DsValor}}</p>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="text-center">
      <v-pagination
        color="#95926a"
        v-model="form.MdPaginacao.PaginaAtual"
        :length="form.MdPaginacao.TotalPaginas"
        @input="listar"
      ></v-pagination>
    </div>
    </v-card>
    
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      listaLogs: [],
      rangerDate: [],
      headers: [],
      conteudo: [],
      loading: false,
      form: {
        DsBusca: "",
        BtAtivo: true,
        MdFiltroData: {
          DtInicio: "",
          DtFim: ""
        },
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: {
          FkTbEmpresa: 0,
          FkTbFilial: null
        }
      }
    };
  },
  methods: {
    listar() {
      this.loading = true;
      this.form.DsBusca = this.inputBusca;
      api
        .post("log/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(resp => {
          this.listaLogs = resp.data.TbLog;
          this.form.MdPaginacao = resp.data.MdPaginacao;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    inputBusca() {
      return this.form.DsBusca;
    }
  }
};
</script>

<style scoped>
.btn {
  height: 35px;
  position: relative;

  display: block;
  margin: 30px auto;
  padding: 8px;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);

  background-color: #95926a;
  color: #ecf0f1;

  transition: background-color 0.3s;
}

.btn:hover,
.btn:focus {
  background-color: #a6a376;
  cursor: pointer;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.btn:before {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 0;
  padding-top: 0;

  border-radius: 100%;

  background-color: rgba(236, 240, 241, 0.3);

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;

  transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}

/* Styles, not important */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.btn.orange {
  background-color: #e67e22;
}

.btn.orange:hover,
.btn.orange:focus {
  background-color: #d35400;
}

.btn.red {
  background-color: #e74c3c;
}

.btn.red:hover,
.btn.red:focus {
  background-color: #c0392b;
}
</style>