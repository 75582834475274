<template>
  <div>
    <v-breadcrumbs :items="items" v-if="!loading">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-card class="mx-auto">
        <el-tabs type="border-card">
          <el-tab-pane label="NetSales">
            <v-row v-if="!loading">
              <v-col
                cols="12"
                sm="9"
                md="9"
                lg="9"
                v-if="form.FkTbEmpresa.length <= 1"
              >
                <v-file-input
                  label="Selecione o arquivo"
                  outlined
                  dense
                  v-model="fileContent"
                  @change="addFile"
                ></v-file-input>
              </v-col>

              <v-col cols="12" sm="3" md="3" lg="3">
                <v-btn
                  color="#95926a"
                  style="color: white; width: 100%"
                  @click="upload"
                >
                  Atualziar o sistema
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
              v-if="loading"
            >
              <v-col class="subtitle-1 text-center" cols="12">
                Atualizando o sistema, por favor aguarde.
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="#53513b"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>

            <div v-if="listaAgendamento.length > 0">
              <v-simple-table
                dark
                style="text-align: left"
                class="full_table_list"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background: #747252">
                      <th class="text-left" nowrap>Status</th>
                      <th class="text-left" nowrap>Qtd. Importadas</th>
                      <th class="text-left" nowrap>Percentual</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in listaAgendamento"
                      :key="item.IdTbTokenAcessoConexao"
                    >
                      <td nowrap>
                        {{
                          item.TbRegistroImportacao.NrPercentual !== 100
                            ? "Importando..."
                            : "Processo finalizado"
                        }}
                      </td>
                      <td nowrap>
                        {{ item.TbRegistroImportacao.NrTotalVendasImportadas }}
                      </td>
                      <td nowrap>
                        {{ item.TbRegistroImportacao.NrPercentual }}%
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </v-card>
    </v-container>
    <notifications group="UploadBaseNetsales" />
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      fileContent: null,
      loading: false,
      listaAgendamento: [],
      form: {
        FkTbEmpresa: [],
        FkTbFilial: [],
      },
      formData: new FormData(),
      items: [
        {
          text: "Importação",
          disabled: true,
          href: "/importacao",
        },
      ],
    };
  },
  methods: {
    addFile() {
      try {
        this.formData.append("file", this.fileContent, this.fileContent.name);
      } catch (error) {
        this.loading = false;
      }
    },

    listar() {
      api
        .get("importacao/list-process-netsales", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (resp) {
            this.listaAgendamento = resp.data;
          }.bind(this)
        );
    },
    upload() {
      if (!this.fileContent) {
        this.$notify({
          type: "error",
          duration: 1000,
          speed: 1000,
          group: "UploadBaseNetsales",
          title:
            '<p style="font-size: 14pt">' +
            this.$store.state.aplicationName +
            "</p>",
          text: '<p style="font-size: 12pt">É necessário selecionar o aquivo para importação</p>',
        });
        this.loading = false;
      } else {
        this.loading = true;
        const interval = setInterval(() => {
          this.listar();
          if (
            this.listaAgendamento &&
            this.listaAgendamento[0].TbRegistroImportacao.NrPercentual === 100
          ) {
            clearInterval(interval);
            this.loading = false;
          }
        }, 10000);

        this.formData.append("file", this.fileContent, this.fileContent.name);
        api
          .post("importacao/base-churn", this.formData, {
            timeout: 10000 * 5,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: this.$GetToken(),
            },
          })
          .then(
            function (response) {
              this.$notify({
                type: "success",
                duration: 1000,
                speed: 1000,
                group: "UploadBaseNetsales",
                title:
                  '<p style="font-size: 14pt">' +
                  this.$store.state.aplicationName +
                  "</p>",
                text:
                  '<p style="font-size: 12pt">' +
                  response.data.message +
                  "</p>",
              });
              this.loading = false;
            }.bind(this)
          );
      }
    },
  },
  watch: {},
  mounted() {},
};
</script>