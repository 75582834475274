<template>
<div>
    <v-breadcrumbs :items="items">
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

    <v-container>
        <v-card style="padding: 10px">
            <!-- <v-row>
            <v-col cols="12" sm="4">
              <el-radio-group v-model="entreVendedores" fill="#53513b" size="mini">
                <el-radio-button :label="true">Transferir entre vendedores</el-radio-button>
                <el-radio-button :label="false">Transferir entre empresas</el-radio-button>
              </el-radio-group>
            </v-col>
          </v-row> -->
            <v-row>
                <v-col cols="12" sm="12">
                    <div v-if="entreVendedores">
                        <el-select style="width:100%; text-align: left" v-model="FkTbUsuarioVenda" filterable remote reserve-keyword placeholder="Consultor" :remote-method="buscarUsuarios" :loading="pesquisaVendedorloading">
                            <el-option v-for="item in listarUsuarios" :key="item.IdTbUsuario" :label="item.DsNome" :value="item.IdTbUsuario">
                            </el-option>
                        </el-select>
                        <br>
                        <br>
                    </div>
                    <v-select v-if="!entreVendedores" :items="$ListarEmpresas()" v-model="FkTbEmpresa" item-value="IdTbEmpresa" item-text="DsNomeFantasia" label="Empresa" outlined dense></v-select>
                    <v-textarea solo name="input-7-4" label="Contratos/Propostas" style="border: 2px #95926a solid; height: 155px" v-model="DsLista"></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-btn class="ma-2" color="#95926a" style="color: white" @click="submit">
                        Transferir
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
    <notifications group="venda" />
</div>
</template>

<script>
import api from "@/services/api";
export default {
    components: {},
    data() {
        return {
            pesquisaVendedorloading: false,
            busca: "",
            listarUsuarios: [],
            DsLista: "",
            listaTbVenda: [],
            entreVendedores: true,
            FkTbEmpresa: '',
            FkTbUsuarioVenda: '',
            formbuscaUsuario: {
                DsBusca: "",
                MdFiltroData: {},
                MdPaginacao: {
                    PaginaAtual: 1,
                    ItensPorPagina: 0,
                    TotalPaginas: 0,
                    TotalItens: 0
                },
                MdModel: {
                    BtAtivo: true,
                }
            },
            items: [{
                text: "Transferir vendas",
                disabled: true,
                href: "/transferir-vendas"
            }]
        };
    },
    methods: {
        submit() {
            let lista = this.DsLista.split(/\r?\n/);
            lista.forEach(element => {
                this.listaTbVenda.push({
                    // FkTbEmpresa: this.FkTbEmpresa,
                    FkTbUsuarioVenda: this.FkTbUsuarioVenda,
                    NrContrato: parseInt(element)
                });
            });
            api
                .put(
                    "venda/transferir",
                    this.listaTbVenda, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: this.$GetToken()
                        }
                    }
                )
                .then(
                    function (response) {
                        this.$notify({
                            type: "success",
                            duration: 1000,
                            speed: 1000,
                            group: "venda",
                            title: '<p style="font-size: 14pt">' +
                                this.$store.state.aplicationName +
                                "</p>",
                            text: '<p style="font-size: 12pt">' + response.data.message + "</p>"
                        });

                        this.DsLista = [];
                        this.FkTbUsuarioVenda = '';
                    }.bind(this)
                )
                .catch(error => {
                    this.$notify({
                        type: "error",
                        duration: 1000,
                        speed: 1000,
                        group: "venda",
                        title: '<p style="font-size: 14pt">' +
                            this.$store.state.aplicationName +
                            "</p>",
                        text: '<p style="font-size: 12pt">' +
                            error.response.data.message +
                            "</p>"
                    });
                });
        },
        buscarUsuarios(value) {
            this.pesquisaVendedorloading = true;
            this.formbuscaUsuario.DsBusca = value;
            api
                .post(
                    "usuario/listar",
                    this.formbuscaUsuario, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: this.$GetToken()
                        }
                    }
                )
                .then(
                    function (response) {
                        this.listarUsuarios = response.data.TbUsuario;
                        this.pesquisaVendedorloading = false;
                    }.bind(this)
                )
                .catch(() => {
                    this.pesquisaVendedorloading = false;
                });
        }
    },
    mounted() {
        this.buscarUsuarios(" ");
    }
};
</script>

<style>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
