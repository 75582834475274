<template>
  <div>
    <v-dialog v-model="visible" persistent width="800">
      <v-card>
        <v-toolbar color="#636146" dark>
          <v-row>
            <v-col cols="10" style="text-align: left; margin-top: 5px">Nova meta</v-col>

            <v-col cols="2">
              <v-btn text @click="close">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row >
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="12" style="margin-top: -25px;">
                      <v-select
                        style="margin-top: 25px;"
                        :items="$ListarEmpresas()"
                        v-model="form.FkTbEmpresa"
                        item-value="IdTbEmpresa"
                        item-text="DsNomeFantasia"
                        label="Empresa"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -35px; text-align: left">
                    <v-col cols="12" sm="12">
                      <el-select style="width:100%" v-model="form.FkTbFilial" placeholder="Filial">
                        <el-option
                          v-for="item in $ListarFilialPorEmpresa(form.FkTbEmpresa)"
                          :key="item.IdTbFilial"
                          :label="item.DsSigla"
                          :value="item.IdTbFilial"
                        >
                          <span style="float: left">{{ item.DsSigla }}</span>
                          <span
                            style="float: right; color: #8492a6; font-size: 13px"
                          >{{ item.DsCidade }}</span>
                        </el-option>
                      </el-select>
                    </v-col>
                  </v-row>

                  <v-row style="margin-top: -10px; text-align: left">
                    <v-col cols="12" sm="12">
                      <v-select
                        background-color="white"
                        style="width: 100%"
                        :items="meses"
                        v-model="mesSelecionado"
                        item-value="DsValor"
                        item-text="DsNome"
                        label="Mês"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model.lazy="form.VlReceita"
                        v-money="money"
                        type="text"
                        label="Receita"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -35px;">
                    <v-col cols="6" sm="6">
                      <v-text-field
                        style="width: 100%"
                        v-model="form.NrNd"
                        type="text"
                        label="N/D"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        style="width: 100%"
                        v-model="form.NrTv"
                        type="text"
                        label="TV (%)"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -35px;">
                    <v-col cols="6" sm="6">
                      <v-text-field
                        style="width: 100%"
                        v-model="form.NrChip"
                        type="text"
                        label="MPLAY (%)"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        style="width: 100%"
                        v-model="form.NrDCC"
                        type="text"
                        label="DCC (%)"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" style="margin-top: -55px;">
                  <v-switch
                    v-model="cadastrarMetaDinamica"
                    label="Criar campanha"
                    color="orange"
                    value="orange"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row v-if="cadastrarMetaDinamica">

                 <v-row>
                 <v-col cols="4" sm="4" v-if="cadastrarMetaDinamica">
                  <v-col cols="12" sm="12" style="margin-top: -40px; margin-left: -35px">
                    <v-btn
                      style="margin-top: 25px"
                      class="mx-2"
                      dark
                      small
                      color="#95926a"
                      @click="modalFormData.isOpen = true"
                    >Nova campanha</v-btn>
                  </v-col>
                </v-col>
              </v-row>

                <v-col cols="12" sm="12" style="margin-top: -44px;">
                  <v-row>
                    <v-col cols="5" sm="5">
                      <div class="el-autocomplete" style="width: 100%;">
                        <div class="el-input el-input-group el-input-group--append">
                          <el-select
                            v-model="metaDinamicaSelecionada"
                            placeholder="Campanhas"
                            style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
                          >
                            <el-option
                              v-for="item in listaMetaDinamicaRegistro"
                              :key="item.IdTbMetaDinamica"
                              :label="item.DsNome"
                              :value="item.IdTbMetaDinamica"
                            >
                              <span style="float: left">{{ item.DsNome }}</span>
                            </el-option>
                          </el-select>
                          <div class="el-input-group__append">
                            <el-button
                              icon="el-icon-info"
                              @click="selectProdutos(metaDinamicaSelecionada)"
                            ></el-button>
                          </div>
                        </div>
                      </div>
                    </v-col>

                     <v-col cols="3" sm="3">
                      <v-row style="margin-top: -12px;">
                        <v-col cols="12" sm="12">
                          <el-input
                            @keyup.enter="adicionarMetaDinamica"
                            placeholder="Qtd de vendas (%)"
                            v-model="NrValor"
                            class="input-with-select"
                            style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
                          >
                          </el-input>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="4" sm="4">
                      <v-row style="margin-top: -12px;">
                        <v-col cols="12" sm="12">
                          <el-input
                            @keyup.enter="adicionarMetaDinamica"
                            placeholder="Comissão (%)"
                            v-model="NrPorcentagem"
                            class="input-with-select"
                            style="width: 100%; border: 1px solid #827f85; border-radius: 5px"
                          >
                            <el-button
                              slot="append"
                              icon="el-icon-plus"
                              @click="adicionarMetaDinamica"
                            ></el-button>
                          </el-input>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="cadastrarMetaDinamica" style="margin-top: -30px">
                <v-col cols="12" sm="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Qtd de vendas</th>
                          <th class="text-left">Comissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in tabelaMetaDinamica" :key="index">
                          <td class="text-left">{{ item.DsNome }}</td>
                          <td class="text-left">{{ item.NrValor }}</td>
                          <td class="text-left">
                            {{ item.NrPorcentagem }}
                            <v-btn
                              icon
                              color="error"
                              style="margin-left: 5px"
                              @click="tabelaMetaDinamica.splice(index, 1)"
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
            <br />
            <br />
            <v-card-actions style="margin-top: -30px">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close">Cancelar</v-btn>
              <v-btn style="background: #95926a; color: white;" @click="submit">Salvar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="meta" />

    <CriarTipoMeta
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :type="modalFormData.type"
      @CLOSE="
        modalFormData.isOpen = false;
        listarMetasDinamicas();
      "
    />

    <ProdutosInfo
      :visible="modalinfo.isOpen"
      :metasDinamicas="modalinfo.data"
      @CLOSE="modalinfo.isOpen = false"
      v-if="modalinfo.data.length > 0"
    />
  </div>
</template>

<script>
import api from "@/services/api";
import CriarTipoMeta from "./CriarTipoMeta";
import ProdutosInfo from "./ProdutosInfo";
export default {
  components: {
    CriarTipoMeta,
    ProdutosInfo
  },
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    type: { type: String, required: true },
    data: { type: Object, required: true }
  },

  methods: {
    initialState() {
      return {
        cadastrarMetaDinamica: false,
        mesSelecionado: "",
        NrPorcentagem: "",
        NrValor: "",
        tabelaMetaDinamica: [],
        listaMetaDinamicaRegistro: [],
        metaDinamicaSelecionada: "",
        metasDinamicasSelecionadas: [],
        modalFormData: { isOpen: false, data: {}, type: "cadastrar" },
        modalinfo: { isOpen: false, data: [] },
        meses: [
          { DsNome: "Janeiro", DsValor: "01" },
          { DsNome: "Fevereiro", DsValor: "02" },
          { DsNome: "Março", DsValor: "03" },
          { DsNome: "Abril", DsValor: "04" },
          { DsNome: "Maio", DsValor: "05" },
          { DsNome: "Junho", DsValor: "06" },
          { DsNome: "Julho", DsValor: "07" },
          { DsNome: "Agosto", DsValor: "08" },
          { DsNome: "Setembro", DsValor: "09" },
          { DsNome: "Outubro", DsValor: "10" },
          { DsNome: "Novembro", DsValor: "11" },
          { DsNome: "Dezembro", DsValor: "12" }
        ],
        money: {
          decimal: ",",
          thousands: ".",
          prefix: "", //R$ mask to left
          suffix: "", //#$ mask to rigth
          precision: 2,
          masked: true
        },
        form: {
          FkTbEmpresa: "",
          FkTbFilial: "",
          TbVendedorMetaVendedorMetaDinamicas: [],
          NrNd: "",
          NrTv: "",
          NrChip: "",
          NrDCC: "",
          VlReceita: "",
          DtInicio: "",
          DtFim: ""
        }
      };
    },

    selectProdutos(FkTbMetaDinamica) {
      if (FkTbMetaDinamica > 0) {
        this.modalinfo.data = [];
        this.modalinfo.data = this.listaMetaDinamicaRegistro.filter(
          el => el.IdTbMetaDinamica === FkTbMetaDinamica
        );
        this.modalinfo.isOpen = true;
      }
    },

    adicionarMetaDinamica() {
      if (this.metaDinamicaSelecionada > 0) {
        let table = this.listaMetaDinamicaRegistro.filter(
          el => el.IdTbMetaDinamica === this.metaDinamicaSelecionada
        );
        this.tabelaMetaDinamica.push({
          IdTbMetaDinamica: table[0].IdTbMetaDinamica,
          DsNome: table[0].DsNome,
          NrValor: this.NrValor + "%",
          NrPorcentagem: this.NrPorcentagem + "%",
        });
      }
    },

    close() {
      this.$emit("CLOSE");
    },

    validarDatas() {
      let dataInicial = this.formatarData(this.rangerDate[0].split("-"));
      let dataFinal = this.formatarData(this.rangerDate[1].split("-"));
      let quatidadeDeDiasNoMes = new Date(
        dataInicial.getFullYear(),
        dataInicial.getMonth(),
        0
      ).getDate();
      let timeDiff = Math.abs(dataFinal.getTime() - dataInicial.getTime()) + 1;
      let quantidadeDeDiasSelecionado = Math.ceil(
        timeDiff / (1000 * 3600 * 24)
      );
      if (quantidadeDeDiasSelecionado <= quatidadeDeDiasNoMes) return true;
      else return false;
    },
    formatarData(date) {
      return new Date(date[0], date[1], date[2]);
    },
    getDate() {
      const today = new Date();
      const date = new Date(today.getFullYear(), this.mesSelecionado, 0);
      const ano = today.getFullYear();
      const ultimoDiaDoMes = date.getDate();
      const dateInicial = ano + "-" + this.mesSelecionado + "-01";
      const dateFinal = ano + "-" + this.mesSelecionado + "-" + ultimoDiaDoMes;
      this.dataAtual = today;
      this.form.DtInicio = dateInicial;
      this.form.DtFim = dateFinal;
    },

    submit() {
      this.getDate();
      this.formatarCampos();
      if (this.type !== "cadastrar") {
        this.alterar();
      } else {
        this.cadastrar();
      }
    },

    formatarCampos() {
      if (this.form.FkTbEmpresa === "") {
        this.form.FkTbEmpresa = 0;
      }
      if (this.form.FkTbFilial === "") {
        this.form.FkTbFilial = 0;
      }

      this.tabelaMetaDinamica.forEach(element => {
        this.form.TbVendedorMetaVendedorMetaDinamicas.push({
          FkTbMetaDinamica: element.IdTbMetaDinamica,
          NrValor: parseInt(element.NrValor.replace("%", "")),
          NrPorcentagem: parseFloat(
            element.NrPorcentagem.replace(/\./g, "").replace(",", ".")
          )
        });
      });

      this.form.NrNd = parseInt(this.form.NrNd);
      this.form.NrTv = parseInt(this.form.NrTv);
      this.form.NrChip = parseInt(this.form.NrChip);
      this.form.NrDCC = parseInt(this.form.NrDCC);
      this.form.VlReceita = parseFloat(
        this.form.VlReceita.replace(/\./g, "").replace(",", ".")
      );
    },

    cadastrar() {
      api
        .post("metavendedor/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "meta",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "meta",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },
    listarMetasDinamicas() {
      api
        .get("metadinamica/listar", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listaMetaDinamicaRegistro = response.data;
          }.bind(this)
        )
        .catch(() => {});
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        this.listarMetasDinamicas();
        Object.assign(this.$data, this.initialState());
        if (this.type !== "cadastrar") {
          this.form = this.data;
        }
      }
    }
  }
};
</script>

<style>
</style>