<template>
  <div>
    <v-card>
      <v-toolbar flat color="#53513b" dark>
        <v-row style="margin-top: 25px">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Pesquisa"
              outlined
              dense
              v-model="form.DsBusca"
              @keyup.enter="listarEmpresas"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="2" sm="2" md="2" lg="1">
            <el-tooltip
              class="item"
              effect="light"
              content="Cadastrar nova empresa"
              placement="top"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#95926a"
                @click="modalFormData.isOpen = true"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </el-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-simple-table dark style="text-align:left" class="full_table_list" v-loading="loading">
        <template v-slot:default>
          <thead>
            <tr style="background: #747252">
              <th class="text-left" nowrap>Nome</th>
              <th class="text-left" nowrap>Responsavel</th>
              <th class="text-left" nowrap>Telefone</th>
              <th class="text-left" nowrap>Endereço</th>
              <th class="text-left" nowrap>Data de Criação</th>
              <th class="text-left" nowrap>Data de Alteração</th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listaEmpresasFilhas" :key="item.IdTbEmpresa" style="cursor: pointer">
              <td nowrap>{{ item.DsNomeFantasia }}</td>
              <td nowrap>{{ item.UsuarioResponsavel }}</td>
              <td nowrap>{{ item.DsContato }}</td>
              <td nowrap>{{ item.TbEnderecoEmpresa.DsLogradouro }}</td>
              <td nowrap>{{ item.DtCriacao | moment("DD/MM/YYYY")}}</td>
              <td nowrap>{{ item.DtAlteracao | moment("DD/MM/YYYY")}}</td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    modalDetalhes.isOpen = true;
                    modalDetalhes.data = item;"
                >
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    modalFormData.isOpen = true;
                    modalFormData.tipo = 'atualizar';
                    modalFormData.data = item;"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="modalLogoEmpresa.isOpen = true; modalLogoEmpresa.IdTbEmpresa = item.IdTbEmpresa"
                  v-if="item.FkTbEmpresaMae == null"
                >
                  <v-icon>mdi-camera-outline</v-icon>
                </v-btn>
              </td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    deletar(item);
                    listarEmpresas();
                  "
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listarEmpresas"
        ></v-pagination>
      </div>
    </v-card>

    <Modal
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :tipo="modalFormData.tipo"
      :FkTbEmpresaMae="form.MdModel.FkEmpresaMae"
      @CLOSE="
        modalFormData.isOpen = false;
        listarEmpresas();
      "
    />

    <Detalhes
      :TbEmpresa="modalDetalhes.data"
      :visible="modalDetalhes.isOpen"
      @CLOSE="modalDetalhes.isOpen = false;  
      listarEmpresas();"
    />

    <ModalLogoEmpresa
      :visible="modalLogoEmpresa.isOpen"
      :IdTbEmpresa="modalLogoEmpresa.IdTbEmpresa"
      @CLOSE="
        modalLogoEmpresa.isOpen = false;
       listarEmpresas();"
    />

    <notifications group="empresa" />
  </div>
</template>

<script>
import api from "@/services/api";
import Modal from "./Modal";
import Detalhes from "./Detalhes";
import ModalLogoEmpresa from "./ModalLogo";
export default {
  components: {
    Modal,
    Detalhes,
    ModalLogoEmpresa
  },
  data() {
    return {
      modalDetalhes: { isOpen: false, data: {} },
      modalLogoEmpresa: { isOpen: false, IdTbEmpresa: "" },
      modalFormData: { isOpen: false, data: {}, tipo: "cadastrar" },
      loading: false,
      listaEmpresasFilhas: [],
      form: {
        DsBusca: "",
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: {
          FkEmpresaMae: 0,
          BtAtivo: true
        }
      }
    };
  },

  methods: {
    deletar(item) {
      api
        .delete("empresa/deletar", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          },
          data: {
            IdTbEmpresa: item.IdTbEmpresa
          }
        })
        .then(
          function(response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "empresa",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "empresa",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },

    listarEmpresas() {
      scroll(0, 0);
      this.loading = true;
      api
        .post("empresa/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listaEmpresasFilhas = response.data.TbEmpresa;
            this.form.MdPaginacao = response.data.MdPaginacao;
            this.loading = false;
          }.bind(this)
        )
        .catch(() => {
          this.loading = false;
        });
    }
  },

  computed: {
    inputBusca() {
      return this.form.DsBusca;
    }
  },

  watch: {
    inputBusca() {
      if (this.inputBusca.length !== "" && this.inputBusca.length > 3) {
        this.form.MdPaginacao.ItensPorPagina = 999999;
        this.listarEmpresas();
      }
    }
  },

  mounted() {
    this.listarEmpresas();
  }
};
</script>

<style scoped>
/*Definido cor das linhas pares*/
.full_table_list tr:nth-child(even) {
  background: #84825e;
}

/*Definindo cor das Linhas impáres*/
.full_table_list tr:nth-child(odd) {
  background: #a6a376;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53513b;
}
</style>