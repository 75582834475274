<template>
  <div style="margin-top: -20px">
    <v-app-bar color="#53513b" dense dark>
      <v-toolbar-title>Velocidade de Vendas</v-toolbar-title>
    </v-app-bar>
    <apexcharts type="line" height="350" :options="chartOptions" :series="series"></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts
  },
  props: {
    mdVelocidadeVendas: { type: Object }
  },
  data() {
    return {
      series: [],
      chartOptions: {}
    };
  },
  methods: {
    criarIndicador() {
      this.series.push({
        name: "Vendas geradas ",
        type: "column",
        data: this.mdVelocidadeVendas.VendasGeradas
      });
      this.series.push({
        name: "Vendas Instaladas",
        type: "line",
        data: this.mdVelocidadeVendas.VendasInstaladas
      });
      this.series.push({
        name: "Necessidade de vendas P/DIA",
        type: "line",
        data: this.mdVelocidadeVendas.NecessidadeVendas
      });
      this.chartOptions = {
        chart: {
          height: 350,
          type: "line",
          stacked: false
        },
        stroke: {
          width: [0, 4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          categories: this.mdVelocidadeVendas.Dia,
          position: "bottum",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          title: {
            text: ""
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " vendas";
              }
              return y;
            }
          }
        }
      };
    }
  },
  watch: {
    mdVelocidadeVendas() {
      this.series = [];
      this.chartOptions = {};
      this.criarIndicador();
    }
  }
};
</script>

<style>
</style>