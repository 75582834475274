<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-card>
        <Table/>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Table from "./Table";
export default {
  components: {
    Table
  },

  data() {
    return {
      items: [
        {
          text: "Metas empresa",
          disabled: true,
          href: "/metas"
        }
      ]
    };
  }
};
</script>