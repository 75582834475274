import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/pt-br'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import JsonCSV from 'vue-json-csv'
import moment from 'moment'
//Omnigestor plugins
require('./extensions/omni')
require('./extensions/omniInicializacao')
require('./extensions/omniCripitografia')

Vue.use(Viewer)
Vue.use(ElementUI, { locale })
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment
Vue.use(money, {precision: 4})
Vue.use(VueTheMask)
Vue.use(Notifications)
Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
