<template>
  <div
    v-if="
      TbVenda != null &&
      TbVenda.TbClienteVenda != null &&
      TbVenda.TbUsuarioVendaVenda != null &&
      TbVenda.TbEnderecoInstalacaoVenda != null
    "
  >
    <dados-cliente-component :TbClienteVenda="TbVenda.TbClienteVenda" />
    <dados-venda-component :TbVenda="TbVenda" />
    <endereco-component
      :TbEnderecoInstalacaoVenda="TbVenda.TbEnderecoInstalacaoVenda"
    />
    <historico-component
      :TbVendaVendaRegistroes="TbVenda.TbVendaVendaRegistroes"
    />
    <acoes-component :TbVenda="TbVenda" />
    <notifications group="venda" />
  </div>
</template>

<script>
import AcoesComponent from "./AcoesComponent.vue";
import DadosClienteComponent from "./DadosClienteComponent.vue";
import DadosVendaComponent from "./DadosVendaComponent.vue";
import EnderecoComponent from "./EnderecoComponent.vue";
import HistoricoComponent from "./HistoricoComponent.vue";

export default {
  components: {
    AcoesComponent,
    DadosClienteComponent,
    DadosVendaComponent,
    EnderecoComponent,
    HistoricoComponent,
  },

  props: {
    TbVenda: { type: Object },
  },
};
</script>

<style>
</style>