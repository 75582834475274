<template>
  <div>
    <v-dialog v-model="visible" persistent width="400">
      <v-card>
        <v-toolbar color="#636146" dark>
          <v-row>
            <v-col
              cols="8"
              style="text-align: left; margin-top: 5px"
            >{{metasDinamicas[0].DsNome}}</v-col>

            <v-col cols="2">
              <v-btn text @click="close">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
         
          <div
            v-for="metaDinamica in metasDinamicas"
            :key="metaDinamica.IdTbMetaDinamica"
          >
            <div
              style="color: #8492a6; font-size: 13px"
              v-for="produto in metaDinamica.TbMetaDinamicaMetaDinamicaProdutoes"
              :key="produto.FkTbProduto"
            >
              <v-chip class="ma-2" label outlined>{{$PesquisaProduto(produto.FkTbProduto)}}</v-chip>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications group="meta" />
  </div>
</template>

<script>
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean, required: true },
    metasDinamicas: { type: Array }
  },

  methods: {
    initialState() {
      return {};
    },

    close() {
      this.visible = !this.visible;
      this.$emit("CLOSE");
    }
  }
};
</script>

<style>
</style>