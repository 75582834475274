<template>
  <IndexComponent />
</template>

<script>
import IndexComponent from "@/components/layout/Index.vue";

export default {
  components: {
    IndexComponent
  },
   data() {
    return {
      loading: true
    };
  },
};
</script>