<template>
  <div>
    <v-card>
      <v-toolbar flat color="#53513b" dark>
        <v-row style="margin-top: 25px">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="form.DsBusca"
              @keyup.enter="listarProdutos"
              label="Pesquisar"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" sm="6" md="4" lg="1">
            <el-tooltip
              class="item"
              effect="light"
              content="Cadastrar novo produto"
              placement="top"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#95926a"
                @click="modalFormData.isOpen = true"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </el-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-simple-table
        dark
        style="text-align:left"
        class="full_table_list"
        v-loading="loading"
      >
        <template v-slot:default>
          <thead>
            <tr style="background: #747252">
              <th class="text-left" nowrap>
                Nome
              </th>
              <th class="text-left" nowrap>
                Tipo
              </th>
              <th class="text-left" nowrap>
                Data de Criação
              </th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
              <th nowrap></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listaProdutos" :key="item.IdTbProduto">
              <td nowrap>{{ item.DsNome }}</td>
              <td nowrap>{{ $PesquisaProdutoTipo(item.FkTbTipoProduto) }}</td>
              <td nowrap>{{ item.DtCriacao | moment("DD/MM/YYYY") }}</td>
              <td nowrap>
                <v-btn
                  icon
                  color="white"
                  @click="
                    modalFormData.isOpen = true;
                    modalFormData.data = item;
                    modalFormData.type = 'atualizar';
                  "
                >
                  <v-icon>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </td>
              <td nowrap></td>
              <td nowrap></td>
              <td nowrap></td>
              <td nowrap></td>
              <td nowrap></td>
              <td nowrap></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listarProdutos"
        >
        </v-pagination>
      </div>
    </v-card>

    <Modal
      :visible="modalFormData.isOpen"
      :data="modalFormData.data"
      :type="modalFormData.type"
      @CLOSE="
        modalFormData.isOpen = false;
        listarProdutos();
      "
    />
  </div>
</template>

<script>
import Modal from "./Modal";
import api from "@/services/api";
export default {
  components: {
    Modal
  },
  data() {
    return {
      modalFormData: { isOpen: false, data: {}, type: "cadastrar" },
      listaProdutos: [],
      loading: false,
      form: {
        DsBusca: "",
        MdFiltroData: {
          DtInicio: null,
          DtFim: null
        },
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: {}
      }
    };
  },

  methods: {
    listarProdutos() {
      scroll(0,0);
      this.loading = true;
      api
        .post("produto/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listaProdutos = response.data.TbProduto;
            this.form.MdPaginacao = response.data.MdPaginacao;
            this.loading = false;
          }.bind(this)
        )
        .catch(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    inputBusca() {
      return this.form.DsBusca;
    }
  },
  watch: {
    inputBusca() {
      if (this.inputBusca.length !== "" && this.inputBusca.length > 3) {
        this.form.MdPaginacao.ItensPorPagina = 999999;
        this.listarProdutos();
      }
    }
  },

  mounted() {
    this.listarProdutos();
  }
};
</script>

<style scoped>
/*Definido cor das linhas pares*/
.full_table_list tr:nth-child(even) {
  background: #84825e;
}

/*Definindo cor das Linhas impáres*/
.full_table_list tr:nth-child(odd) {
  background: #a6a376;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53513b;
}
</style>