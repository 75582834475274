<template>
  <div>
    <br />
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" align="left">Status</th>
              <th class="text-left" align="left">Qtd. HC</th>
              <th class="text-left" align="left">Qtd. N/D</th>
              <th class="text-left" align="left">Receita N/D</th>
              <th class="text-left" align="left">Qtd. Base</th>
              <th class="text-left" align="left">Receita. Base</th>
              <th class="text-left" align="left">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in itemsCards" :key="i">
              <td align="left">
                <v-chip class="ma-2" label text-color="white" :color="item.TbVendaStatus.DsCor"></v-chip>
                {{ item.TbVendaStatus.DsNome }}
              </td>
              <td align="left">{{ item.NrQuantidadeHc }}</td>
              <td align="left">{{ item.NrQuantidadeNd }}</td>
              <td align="left">{{ item.VlReceitaNd }}</td>
              <td align="left">{{ item.NrQuantidadeBase }}</td>
              <td align="left">{{ item.VlReceitaBase }}</td>
              <td align="left">{{ item.VlReceitaTotal }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsCards: []
    };
  },

  props: {
    items: { type: Array }
  },
  watch: {
    itemsComputed() {
      this.itemsCards = this.itemsComputed;
    }
  },
  computed: {
    itemsComputed() {
      return this.items;
    },
    tamanho() {
      if (this.itemsComputed.length === 1) return 1200;
      else if (this.itemsComputed.length === 2) return 600;
      else if (this.itemsComputed.length === 3) return 400;
      else return 250;
    }
  }
};
</script>

<style>
</style>