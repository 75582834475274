<template>
  <div>
    <v-dialog v-model="visible" persistent width="800">
      <v-card>
        <v-toolbar color="#53513b" dark>
          <v-row>
            <v-col cols="10" style="text-align: left; margin-top: 5px">
             {{title}}
            </v-col>

            <v-col cols="2">
              <v-btn text @click="close">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <v-stepper v-model="steep">
            <v-stepper-header>
              <v-stepper-step color="#53513b" :complete="steep > 1" step="1">
                Dados da fiial
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="#53513b" :complete="steep > 2" step="2">
                Endereço
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="formDadosEmpresa"
                  v-model="validformDadosEmpresa"
                  lazy-validation
                >
                  <v-container>
                     <v-row>
                         <v-col cols="12" sm="12">
                        <v-select
                          :items="$ListarEmpresas()"
                          v-model="form.FkTbEmpresaMae"
                          item-value="IdTbEmpresa"
                          item-text="DsNomeFantasia"
                          label="Empresa"
                          outlined
                          dense
                        >
                        </v-select>
                      </v-col>
                    </v-row>

                     <v-row style="margin-top: -30px">
                       <v-col cols="12" sm="12" >
                        <v-text-field
                          v-model="form.DsNomeFantasia"
                          type="text"
                          :rules="rulesDsNomeFantasia"
                          max="14"
                          label="Nome Fantasia"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                   <v-row style="margin-top: -30px">
                       <v-col cols="12" sm="12" >
                        <v-text-field
                          v-model="form.DsRazaoSocial"
                          type="text"
                          :rules="rulesDsRazaoSocial"
                          max="14"
                          label="Razao Social"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -30px">
                      <v-col cols="6" sm="6">
                        <v-text-field
                          v-model="form.DsCpfCnpj"
                          type="text"
                          :rules="rulesCpfCnpj"
                          v-mask="['###.###.###-##', '##.###.###/####-##']"
                          max="14"
                          label="CPF/CNPJ"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                    <v-col cols="6" sm="6">
                        <v-text-field
                          v-model="form.DsEmail"
                          :rules="rulesDsEmail"
                          type="text"
                          label="E-mail"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                     <v-row style="margin-top: -30px">
                       <v-col cols="6" sm="6">
                        <v-text-field
                          v-model="form.DsContato"
                          type="text"
                          :rules="ruleDsContato"
                           v-mask="['(##) ####-####', '(##) #####-####']"
                          label="Telefone"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                     <v-col cols="6" sm="6">
                        <el-select
                        style="width: 100%; border: 1px solid rgb(118, 118, 118); border-radius: 4px"
                          v-model="form.FkTbUsuarioResponsavel"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Responsável"
                          :remote-method="buscarUsuarios"
                          :loading="loadingBusca">
                          <el-option
                            v-for="item in listarUsuarios"
                            :key="item.IdTbUsuario"
                            :label="item.DsNome"
                            :value="item.IdTbUsuario">
                          </el-option>
                        </el-select>
                      </v-col>
                    </v-row>

                   
                  </v-container>
                </v-form>

                <v-btn color="#95926a" style="color: white" @click="steep = 2">
                  <v-icon>mdi-account-arrow-right</v-icon>
                  Proximo
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form
                  ref="formEndereco"
                  v-model="validformEndereco"
                  lazy-validation
                >
                  <v-container>
                    <v-row>
                      <v-col cols="3" sm="3">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.NrCep"
                          :rules="rulesNrCep"
                          v-mask="['#####-###']"
                          @blur="buscaCep"
                          max="9"
                          label="CEP"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="7" sm="7">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.DsLogradouro"
                          type="text"
                          label="Logradouro "
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="2" sm="2">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.NrLogradouro"
                          type="text"
                          :rules="rulesNrLogradouroRules"
                          label="Numero"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -30px">
                      <v-col cols="6" sm="6">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.DsBairro"
                          type="text"
                          label="Bairro"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" sm="4">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.DsCidade"
                          type="text"
                          label="Cidade"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="2" sm="2">
                        <v-select
                          :items="$ListarEstado()"
                          v-model="form.TbEnderecoEmpresa.FkTbEstado"
                          label="UF"
                          item-value="IdTbEstado"
                          item-text="DsUF"
                          :rules="rulesFkTbEstado"
                          required
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -30px">
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="form.TbEnderecoEmpresa.DsComplemento"
                          type="text"
                          label="Complemento"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>

                <v-btn color="#95926a" style="color: white" @click="steep = 1">
                  <v-icon>mdi-account-arrow-left</v-icon>
                  Voltar
                </v-btn>

                <v-btn
                  color="success"
                  style="margin-left: 10px"
                  @click="submit"
                >
                  <v-icon>mdi-account-check</v-icon>
                  Finalizar
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
      <notifications group="empresa" />
  </div>
</template>

<script>
import api from "@/services/api";
import apiCep from "@/services/buscaCep";
export default {
  data() {
    return this.initialState();
  },

  props: {
    visible: { type: Boolean },
    tipo: { type: String },
    data: { type: Object },
  },

  methods: {
    initialState(){
    return {
      title: "",
      listarUsuarios: [],
      validformEndereco: true,
      validformDadosEmpresa: true,
      rulesDsRazaoSocial: [
        v => !!v || "Razão social é obrigatória"
      ],
      rulesDsNomeFantasia: [
        v => !!v || "Nome Fantasia é obrigatória"
      ],
      rulesCpfCnpj: [
        v => !!v || "CPF ou CNPJ é obrigatório"
      ],
       ruleDsContato: [
        v => !!v || "Telefone é obrigatório"
      ],
       rulesNrCep: [
        v => !!v || "CEP é obrigatório"
      ],
       rulesNrLogradouroRules: [
        v => !!v || "Número é obrigatório"
      ],
       rulesFkTbEstado: [
        v => !!v || "Estado é obrigatório"
      ],
      rulesFkTbUsuarioResponsavel: [
        v => !!v || "Responsavel é obrigatório"
      ],
      rulesDsEmail: [
        v => !!v || "E-mail é obrigatório"
      ],
      steep: 1,
      loadingBusca: false,
      formPUT: true,
      formbuscaUsuario: {
        DsBusca: "",
        BtAtivo: true,
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: { BtAtivo: true }
      },
      form: {
        FkTbUsuarioResponsavel: '',
        FkTbEmpresaMae: 0,
        DsRazaoSocial: "",
        DsNomeFantasia: "",
        DsCpfCnpj: "",
        DsContato: "",
        DsEmail: "",
        TbEnderecoEmpresa: {
          FkTbEstado: 0,
          DsLogradouro: "",
          NrLogradouro: "",
          DsComplemento: "",
          NrCep: "",
          DsCidade: "",
          DsBairro: ""
        },
         MdPaginacao:{
          PaginaAtual:	1,
          ItensPorPagina:	0,
          TotalPaginas:	0,
          TotalItens:	0
        }
      }
    };
    },
    submit() {
      if(this.formPUT){
        
        api
        .post("empresa/cadastrar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        }).then(
          function(response) {
             this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "empresa",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        ).catch(() => {});
      }else{
        api
        .put("empresa/atualizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        }).then(
          function(response) {
             this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "empresa",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>"
            });

            this.close();
          }.bind(this)
        ).catch(() => {});
      }
      
    },

    buscarUsuarios(query){
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      this.formbuscaUsuario.DsBusca = query;
       api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        }).then(
          function(response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        ).catch(() => {});
    },

      buscaEstado(uf) {
      this.$ListarEstado().forEach(value => {
        if (value.DsUF === uf) {
          this.form.TbEnderecoEmpresa.FkTbEstado = value.IdTbEstado;
        }
      });
    },

     buscaCep() {
      apiCep
        .get(this.form.TbEnderecoEmpresa.NrCep)
        .then(
          function(response) {
            let endereco = response.data;
            this.form.TbEnderecoEmpresa.DsLogradouro = endereco.street;
            this.form.TbEnderecoEmpresa.DsBairro = endereco.neighborhood;
            this.form.TbEnderecoEmpresa.DsCidade = endereco.city;
            this.buscaEstado(endereco.state);
          }.bind(this)
        )
        .catch(error => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "cep",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>"
          });
        });
    },


    close() {
      this.$emit("CLOSE");
    },
     validarFormulario() {
      if (!this.$refs.formDadosEmpresa.validate()) {
        return 1;
      } else if (!this.$refs.formEndereco.validate()) {
        return 2;
      }
    },
  },
  
  watch: {
    visible() {
      if (this.visible) {
        Object.assign(this.$data, this.initialState());
       
        if(this.tipo !== "cadastrar"){
          this.formPUT = false;
          this.form = this.data;
        }else{
           this.formPUT = true;
          this.buscarUsuarios(' ');
        }
      }
    }
  }
};
</script>

<style>
</style>