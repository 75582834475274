<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-row style="margin-top: -30px">
        <v-col class="d-flex" cols="6" sm="6">
          <el-select
            style="width: 100%"
            v-model="form.FkTbUsuario"
            filterable
            remote
            reserve-keyword
            placeholder="Selecione Supervisor/Consultor"
            :remote-method="buscarUsuarios"
            :loading="pesquisaVendedorloading"
          >
            <el-option
              v-for="item in listarUsuarios"
              :key="item.IdTbUsuario"
              :label="item.DsNome"
              :value="item.IdTbUsuario"
            >
              <span style="float: left; color: #42412f">{{ item.DsNome }}</span>
              <span style="float: right; color: #95926a; font-size: 13px">
                {{
                item.TbPerfilUsuario.DsNome
                }}
              </span>
            </el-option>
          </el-select>
        </v-col>
        <v-col class="d-flex" cols="2" sm="2">
          <v-btn class="mx-2" fab dark small color="#95926a" @click="consultarVendedores">
            <v-icon dark>mdi-map-search-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="4" sm="4">
          <div style="max-height: 450px; overflow: auto;">
            <el-tree
              :expand-on-click-node="false"
              default-expand-all
              :data="data"
              :props="defaultProps"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </v-col>
        <v-col class="d-flex" cols="8" sm="8">
          <Mapa :localizacaoVendedores="vendedores" v-loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "@/services/api";
import Mapa from "./Mapa";
export default {
  components: {
    Mapa
  },

  data() {
    return {
      data: [],
      loading: false,
      pesquisaVendedorloading: false,
      form: {
        FkTbUsuario: ""
      },
      formbuscaUsuario: {
        DsBusca: "",
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0
        },
        MdModel: {
          BtAtivo: true
        }
      },
      listarUsuarios: [],
      vendedores: [],
      items: [
        {
          text: "Consultor no Mapa",
          disabled: true,
          href: "/mapa-vendedores"
        }
      ]
    };
  },
  methods: {
    handleNodeClick(vendedor) {
      this.vendedores = [];
      this.vendedores.push(vendedor.data);
    },

    consultarVendedores() {
      this.loading = true;
      this.vendedores = [];
      api
        .post("localizacao/listar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.vendedores = response.data;
            this.vendedores.forEach(vendedor => {
              if (vendedor) {
                this.data.push({
                  label: vendedor.TbUsuarioLocalizacao.DsNome,
                  data: vendedor,
                  children: [
                    {
                      label: this.$moment(vendedor.DtCriacao).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    }
                  ]
                });
              }
            });

            this.loading = false;
          }.bind(this)
        )
        .catch(() => {
          this.loading = false;
        });
    },

    buscarUsuarios(query) {
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      this.formbuscaUsuario.DsBusca = query;
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken()
          }
        })
        .then(
          function(response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => {});
    }
  },
  mounted() {
    this.buscarUsuarios(" ");
  }
};
</script>