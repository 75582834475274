<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-card class="mx-auto">
        <v-row>
          <v-col cols="12">
              <Table />
          </v-col>
          </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>

import Table from "./Table";
export default {
  components: {
    Table
  },

  data() {
    return {
      items: [
        {
          text: "Filiais",
          disabled: true
        }
      ]
    };
  },
  methods:{
    
  },
};
</script>