<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="5">
        <el-alert
          :title="'Total de vendas: ' + churnQuantitativo.Total"
          type="success"
          :closable="false"
          show-icon
          v-if="churnQuantitativo.Total > 0"
        >
        </el-alert>
      </el-col>
      <el-col :span="5">
        <el-alert
          :title="'Desabilitados: ' + churnQuantitativo.Desabilitados"
          type="warning"
          :closable="false"
          show-icon
          v-if="churnQuantitativo.Total > 0"
        >
        </el-alert>
      </el-col>
      <el-col :span="5">
        <el-alert
          :title="'Desconetados: ' + churnQuantitativo.Desconetados"
          type="info"
          :closable="false"
          show-icon
          v-if="churnQuantitativo.Total > 0"
        >
        </el-alert>
      </el-col>
      <el-col :span="5">
        <el-alert
          :title="'Cancelados: ' + churnQuantitativo.Cancelados"
          type="error"
          :closable="false"
          show-icon
          v-if="churnQuantitativo.Total > 0"
        >
        </el-alert>
      </el-col>
      <el-col :span="4">
        <el-alert
          :title="'Tratativas do dia: ' + churnQuantitativo.Tratativas"
          type="info"
          :closable="false"
          show-icon
          v-if="churnQuantitativo.Total > 0"
        >
        </el-alert>
      </el-col>
    </el-row>
    <v-card>
      <v-toolbar color="#53513b" dark>
        <el-select
          v-model="form.FkTbEmpresa"
          placeholder="Empresa"
          style="width: 180px"
        >
          <el-option
            v-for="item in $ListarEmpresas()"
            :key="item.IdTbEmpresa"
            :label="item.DsNomeFantasia"
            :value="item.IdTbEmpresa"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="form.FkTbFilial"
          multiple
          placeholder="Filial"
          style="margin-left: 10px; width: 180px"
        >
          <el-option
            v-for="item in $ListarFilialPorEmpresa(this.form.FkTbEmpresa)"
            :key="item.IdTbFilial"
            :label="item.DsSigla"
            :value="item.IdTbFilial"
          >
            <span style="float: left">{{ item.DsSigla }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.DsCidade
            }}</span>
          </el-option>
        </el-select>

        <el-date-picker
          style="
            width: 150px;
            border: 1px solid #827f85;
            border-radius: 5px;
            margin-left: 10px;
          "
          v-model="form.MdFiltroData.DtInicio"
          format="dd/MM/yyyy"
          type="date"
          placeholder="Data inicial"
        ></el-date-picker>

        <el-date-picker
          style="
            width: 150px;
            border: 1px solid #827f85;
            margin-left: 10px;
            border-radius: 5px;
          "
          v-model="form.MdFiltroData.DtFim"
          format="dd/MM/yyyy"
          type="date"
          placeholder="Data Fim"
        ></el-date-picker>

        <el-select
          v-model="form.FkTbVendaMarcadores"
          placeholder="Marcador"
          style="margin-left: 10px; width: 120px"
        >
          <el-option
            v-for="item in $ListarMarcadores()"
            :key="item.IdTbVendaMarcadores"
            :label="item.DsNome"
            :value="item.IdTbVendaMarcadores"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="form.FkTbVendaStatusNetSales"
          multiple
          placeholder="Status"
          style="margin-left: 10px"
        >
          <el-option
            v-for="item in $ListarStatusNetSales()"
            :key="item.IdTbVendaStatusNetSales"
            :label="item.DsNome"
            :value="item.IdTbVendaStatusNetSales"
          >
          </el-option>
        </el-select>

        <v-btn icon color="white" @click="listar">
          <v-icon dark> mdi-file-find </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <download-csv
          v-if="listaChurn.length > 0"
          class="btn"
          :data="contentCsv"
          name="relatorio-churn.csv"
          delimiter=";"
        >
          CSV
        </download-csv>
      </v-toolbar>

      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listar"
          v-if="form.MdPaginacao.TotalItens > 0"
        ></v-pagination>
      </div>

      <el-table
        :data="listaChurn"
        stripe
        style="width: 100%"
        v-if="listaChurn.length"
        v-loading="loading"
      >
        <el-table-column label="Venda" width="400">
          <template slot-scope="scope">
            Contrato: {{ scope.row.NrContrato }}
            <br />
            Cliente: {{ scope.row.TbClienteVenda.DsNome }}
            <br />
            Status:
            {{ $PesquisaStatusNetSales(scope.row.FkTbVendaStatusNetSales) }}
            <br />
            Tipo: {{ $PesquisaTipoVenda(scope.row.FkTbTipoVenda) }}
          </template>
        </el-table-column>

        <el-table-column label="Informações" width="400">
          <template slot-scope="scope">
            Filial: {{ $PesquisaFilial(scope.row.FkTbFilial) }}
            <br />
            Cidade: {{ scope.row.TbEnderecoInstalacaoVenda.DsCidade }}
            <br />
            Consultor: {{ scope.row.TbUsuarioVendaVenda.DsNome }}
            <div v-if="scope.row.TbVendaMarcadoresVenda != null">
              Marcadores:
              <el-tag size="small">
                <v-icon
                  icon="mdi-tag-multiple"
                  :color="scope.row.TbVendaMarcadoresVenda.DsCor"
                >
                  mdi-tag-multiple
                </v-icon>
                {{ scope.row.TbVendaMarcadoresVenda.DsNome }}</el-tag
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Datas" width="260">
          <template slot-scope="scope">
            Venda: {{ scope.row.DtVenda | moment("DD/MM/YYYY") }}
            <br />
            Instalação:
            {{ scope.row.DtInstalacao | moment("DD/MM/YYYY") }}
            <br />
            Aging:
            {{ addDays(scope.row.DtInstalacao, 120) | moment("DD/MM/YYYY") }}
            <br />
            Desconexão:
            {{ scope.row.DtDesconexao | moment("DD/MM/YYYY") }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="Ações" width="100">
          <template slot-scope="scope">
            <el-button
              @click="openModal(scope.row)"
              type="primary"
              icon="el-icon-edit"
              size="small"
              circle
            ></el-button>
            <el-button
              @click="openModalHistorico(scope.row)"
              type="info"
              icon="el-icon-time"
              size="small"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <v-pagination
          color="#95926a"
          v-model="form.MdPaginacao.PaginaAtual"
          :length="form.MdPaginacao.TotalPaginas"
          @input="listar"
        ></v-pagination>
      </div>
    </v-card>
    <div
      v-if="
        SelectedVenda != null &&
        SelectedVenda.TbClienteVenda != null &&
        SelectedVenda.TbUsuarioVendaVenda != null &&
        SelectedVenda.TbEnderecoInstalacaoVenda != null
      "
    >
      <el-dialog
        title="Efetuar tratativa"
        :visible.sync="showModal"
        :close-on-click-modal="false"
        width="90%"
      >
        <dados-cliente-component
          :TbClienteVenda="SelectedVenda.TbClienteVenda"
        />
        <dados-venda-component :TbVenda="SelectedVenda" />
        <endereco-component
          :TbEnderecoInstalacaoVenda="SelectedVenda.TbEnderecoInstalacaoVenda"
        />
        <div class="text-center">
          <br />
          <strong> </strong>
          <el-button
            v-if="enableFormEdit"
            type="warning"
            @click="enableFormEdit = !enableFormEdit"
            icon="el-icon-edit"
            >Editar</el-button
          >
          <el-button
            v-if="!enableFormEdit"
            type="success"
            @click="alterarVenda"
            icon="el-icon-check"
            >Salvar</el-button
          >
          <!-- INICIO DADOS DO CLIENTE -->

          <div style="text-align: left">
            <v-row style="margin-top: 20px">
              <v-col cols="3" sm="3">
                <v-select
                  :items="$ListarStatus()"
                  v-model="SelectedVenda.FkTbVendaStatus"
                  label="Status Din"
                  item-value="IdTbVendaStatus"
                  item-text="DsNome"
                  required
                  outlined
                  dense
                  :disabled="enableFormEdit"
                ></v-select>
              </v-col>
              <v-col cols="3" sm="3">
                <v-select
                  :items="$ListarStatusNetSales()"
                  v-model="SelectedVenda.FkTbVendaStatusNetSales"
                  label="Status NetSales"
                  item-value="IdTbVendaStatusNetSales"
                  item-text="DsNome"
                  required
                  outlined
                  dense
                  :disabled="enableFormEdit"
                ></v-select>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                style="margin-top: -20px; text-align: left"
              >
                <label>Data de agendamento:</label>
                <br />
                <div class="block">
                  <el-date-picker
                    :disabled="enableFormEdit"
                    v-model="SelectedVenda.DtAgendamento"
                    type="date"
                    format="dd/MM/yyyy"
                    style="width: 100%"
                    placeholder="Data de agendamento"
                  ></el-date-picker>
                </div>
              </v-col>

              <v-col
                cols="2"
                sm="2"
                style="margin-top: -20px; text-align: left"
              >
                <label>Data de instalação:</label>
                <br />
                <div class="block">
                  <el-date-picker
                    :disabled="enableFormEdit"
                    v-model="SelectedVenda.DtInstalacao"
                    type="date"
                    format="dd/MM/yyyy"
                    style="width: 100%"
                    placeholder="Data de instalação"
                  ></el-date-picker>
                </div>
              </v-col>

              <v-col
                cols="2"
                sm="2"
                style="margin-top: -20px; text-align: left"
              >
                <label>Data de desconexão:</label>
                <br />
                <div class="block">
                  <el-date-picker
                    :disabled="enableFormEdit"
                    v-model="SelectedVenda.DtDesconexao"
                    type="date"
                    format="dd/MM/yyyy"
                    style="width: 100%"
                    placeholder="Data de instalação"
                  ></el-date-picker>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" sm="2" style="margin-top: -30px">
                <v-select
                  :disabled="enableFormEdit"
                  :items="$ListarPeriodo()"
                  v-model="SelectedVenda.FkTbPeriodo"
                  label="Período"
                  item-value="IdTbPeriodo"
                  item-text="DsNome"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col cols="2" sm="2" style="margin-top: -30px">
                <v-text-field
                  :disabled="enableFormEdit"
                  v-model="SelectedVenda.NrProposta"
                  type="text"
                  label="Nr. Proposta"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" style="margin-top: -30px">
                <v-text-field
                  :disabled="enableFormEdit"
                  v-model="SelectedVenda.NrContrato"
                  type="text"
                  label="Nr. Contrato"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="2"
                sm="2"
                style="margin-top: -30px; margin-bottom: 30px"
              >
                <v-select
                  :disabled="enableFormEdit"
                  :items="$ListarMarcadores()"
                  v-model="SelectedVenda.FkTbVendaMarcadores"
                  item-value="IdTbVendaMarcadores"
                  item-text="DsNome"
                  label="Marcador"
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col
                cols="2"
                sm="2"
                style="margin-top: -30px; margin-bottom: 30px"
              >
                <v-select
                  :disabled="enableFormEdit"
                  :items="$ListarEmpresas()"
                  v-model="SelectedVenda.FkTbEmpresa"
                  item-value="IdTbEmpresa"
                  item-text="DsNomeFantasia"
                  label="Empresa"
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col
                cols="2"
                sm="2"
                style="margin-top: -30px; margin-bottom: 30px"
              >
                <el-select
                  :disabled="enableFormEdit"
                  style="width: 100%"
                  v-model="SelectedVenda.FkTbFilial"
                  placeholder="Filial"
                >
                  <el-option
                    v-for="item in $ListarFilialPorEmpresa(
                      SelectedVenda.FkTbEmpresa
                    )"
                    :key="item.IdTbFilial"
                    :label="item.DsSigla"
                    :value="item.IdTbFilial"
                  >
                    <span style="float: left">{{ item.DsSigla }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.DsCidade }}</span
                    >
                  </el-option>
                </el-select>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                style="margin-top: -30px; margin-bottom: 30px"
              >
                <el-select
                  style="width: 100%"
                  :disabled="enableFormEdit"
                  v-model="SelectedVenda.FkTbUsuarioVenda"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Consultor"
                  :remote-method="buscarUsuarios"
                >
                  <el-option
                    v-for="item in listarUsuarios"
                    :key="item.IdTbUsuario"
                    :label="item.DsNome"
                    :value="item.IdTbUsuario"
                  >
                  </el-option>
                </el-select>
              </v-col>

              <br />
              <v-col cols="12" sm="12">
                <v-textarea
                  :disabled="enableFormEdit"
                  outlined
                  v-model="SelectedVenda.DsObservacao"
                  name="input-7-4"
                  label="Observação"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <notifications group="venda" />
      </el-dialog>
    </div>
    <historico-component
      :TbVendaVendaRegistroes="TbVendaVendaRegistroes"
      :visible="showModalHistorico"
      @CLOSE="resetModals"
    />
  </div>
</template>

<script>
import DadosClienteComponent from "./Tratativa/DadosClienteComponent.vue";
import DadosVendaComponent from "./Tratativa/DadosVendaComponent.vue";
import EnderecoComponent from "./Tratativa/EnderecoComponent.vue";
import HistoricoComponent from "./Tratativa/HistoricoComponent.vue";
import api from "@/services/api";
export default {
  components: {
    DadosClienteComponent,
    DadosVendaComponent,
    EnderecoComponent,
    HistoricoComponent,
  },

  data() {
    return {
      enableFormEdit: true,
      listarUsuarios: [],
      contentCsv: [],
      listaChurn: [],
      churnQuantitativo: {
        Cancelados: 0,
        Desabilitados: 0,
        Desconetados: 0,
        Total: 0,
        Tratativas: 0,
      },
      meses: [
        { DsNome: "Janeiro", DsValor: "01" },
        { DsNome: "Fevereiro", DsValor: "02" },
        { DsNome: "Março", DsValor: "03" },
        { DsNome: "Abril", DsValor: "04" },
        { DsNome: "Maio", DsValor: "05" },
        { DsNome: "Junho", DsValor: "06" },
        { DsNome: "Julho", DsValor: "07" },
        { DsNome: "Agosto", DsValor: "08" },
        { DsNome: "Setembro", DsValor: "09" },
        { DsNome: "Outubro", DsValor: "10" },
        { DsNome: "Novembro", DsValor: "11" },
        { DsNome: "Dezembro", DsValor: "12" },
      ],
      listaAno: [
        { DsNome: "2020", DsValor: "2020" },
        { DsNome: "2021", DsValor: "2021" },
        { DsNome: "2022", DsValor: "2022" },
        { DsNome: "2023", DsValor: "2023" },
        { DsNome: "2024", DsValor: "2024" },
        { DsNome: "2025", DsValor: "2025" },
        { DsNome: "2026", DsValor: "2026" },
        { DsNome: "2027", DsValor: "2027" },
        { DsNome: "2028", DsValor: "2028" },
        { DsNome: "2029", DsValor: "2029" },
        { DsNome: "2030", DsValor: "2030" },
      ],
      loading: false,
      FkTbFilial: [],
      SelectedVenda: {},
      TbVendaVendaRegistroes: null,
      showModal: false,
      showModalHistorico: false,
      formbuscaUsuario: {
        DsBusca: "",
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
        MdModel: {
          BtAtivo: true,
        },
      },
      form: {
        FkTbEmpresa: null,
        FkTbFilial: [],
        FkTbVendaStatusNetSales: [],
        FkTbVendaMarcadores: null,
        BtAtivo: true,
        MdFiltroData: {
          DtInicio: null,
          DtFim: null,
        },
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
      },
    };
  },
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(pDate) {
      const date = new Date(pDate);
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    },
    createCsv(vendas) {
      this.contentCsv = [];
      vendas.forEach((element) => {
        const model = {
          Filial: this.$PesquisaFilial(element.FkTbFilial),
          Cidade: element.TbEnderecoInstalacaoVenda.DsCidade,
          Contrato: element.NrContrato,
          Cliente: element.TbClienteVenda.DsNome,
          Status: this.$PesquisaStatusNetSales(element.FkTbVendaStatusNetSales),
          TipoDeVenda: this.$PesquisaTipoVenda(element.FkTbTipoVenda),
          Consultor: element.TbUsuarioVendaVenda.DsNome,
          DataDaVenda: this.formatDate(element.DtVenda),
          DataDeInstalação: this.formatDate(element.DtInstalacao),
          DataDeDesconexão: this.formatDate(element.DtDesconexao),
          Aging: this.formatDate(this.addDays(element.DtInstalacao, 120)),
          Obs: element.TbVendaVendaRegistroes[element.TbVendaVendaRegistroes.length - 1].DsObservacao
        };
        this.contentCsv.push(model);
      });
    },
    listar() {
      this.csv();

      this.loading = true;
      if (this.form.FkTbEmpresa === "") {
        this.form.FkTbEmpresa = 0;
      }

      api
        .post("relatorio/churn", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then((resp) => {
          this.listaChurn = resp.data.Value.TbVenda;
          this.form.MdPaginacao = resp.data.Value.MdPaginacao;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    csv() {
      this.loading = true;
      if (this.form.FkTbEmpresa === "") {
        this.form.FkTbEmpresa = 0;
      }
      api
        .post("relatorio/csv-churn", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then((resp) => {
          const data = resp.data.Value;
          this.churnQuantitativo = data.ChurnQuantitativo;
          this.createCsv(data.Vendas);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openModal(row) {
      this.buscarUsuarios("" + row.FkTbUsuarioVenda);
      this.SelectedVenda = {
        NrContrato: row.NrContrato,
        NrProposta: row.NrProposta,
        DtDesconexao: row.DtDesconexao,
        DtInstalacao: row.DtInstalacao,
        DtAgendamento: row.DtAgendamento,
        FkTbEmpresa: row.FkTbEmpresa,
        FkTbFilial: row.FkTbFilial,
        FkTbUsuarioVenda: row.FkTbUsuarioVenda,
        FkTbVendaStatusNetSales: row.FkTbVendaStatusNetSales,
        FkTbVendaMarcadores: row.FkTbVendaMarcadores,
        FkTbPeriodo: row.FkTbPeriodo,
        IdTbVenda: row.IdTbVenda,
        FkTbVendaStatus: row.FkTbVendaStatus,
        TbClienteVenda: row.TbClienteVenda,
        TbUsuarioVendaVenda: row.TbUsuarioVendaVenda,
        TbEnderecoInstalacaoVenda: row.TbEnderecoInstalacaoVenda,
      };
      this.showModal = true;
    },
    openModalHistorico(row) {
      this.TbVendaVendaRegistroes = row.TbVendaVendaRegistroes;
      this.showModalHistorico = true;
    },
    resetModals() {
      this.showModal = false;
      this.showModalHistorico = false;
    },
    buscarUsuarios(query) {
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      if (query) {
        this.formbuscaUsuario.DsBusca = query;
      }
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => {});
    },
    alterarVenda() {
      this.enableFormEdit = !this.enableFormEdit;
      this.SelectedVenda.NrContrato = parseInt(this.SelectedVenda.NrContrato);
      this.SelectedVenda.NrProposta = parseInt(this.SelectedVenda.NrProposta);
      if (this.SelectedVenda.FkTbUsuarioVenda == null) {
        this.SelectedVenda.FkTbUsuarioVenda =
          this.listarUsuarios[0].IdTbUsuario;
      } else {
        this.SelectedVenda.FkTbUsuarioVenda = parseInt(
          this.SelectedVenda.FkTbUsuarioVenda
        );
      }

      api
        .put("venda/atualizar", this.SelectedVenda, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "venda",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>",
            });
            this.$emit("REFRESH");
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "venda",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },
  },
  watch: {
    rangerDate() {
      if (this.rangerDate.length > 0) {
        this.form.MdFiltroData.DtInicio = new Date(this.rangerDate[0]);
        this.form.MdFiltroData.DtFim = new Date(this.rangerDate[1]);
      }
    },
  },
};
</script>

<style>
tr {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn {
  height: 40px;
  position: relative;

  display: block;
  margin: 30px auto;
  padding: 10px;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);

  background-color: #95926a;
  color: #ecf0f1;

  transition: background-color 0.3s;
}

.btn:hover,
.btn:focus {
  background-color: #a6a376;
  cursor: pointer;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.btn:before {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 0;
  padding-top: 0;

  border-radius: 100%;

  background-color: rgba(236, 240, 241, 0.3);

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;

  transition: width 0.2s ease-out, padding-top 0.2s ease-out;
}

/* Styles, not important */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.btn.orange {
  background-color: #e67e22;
}

.btn.orange:hover,
.btn.orange:focus {
  background-color: #d35400;
}

.btn.red {
  background-color: #e74c3c;
}

.btn.red:hover,
.btn.red:focus {
  background-color: #c0392b;
}
</style>