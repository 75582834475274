<template>
  <div class="text-center">
    <br />
    <strong> </strong>
    <el-button v-if="enableFormEdit" type="warning" @click="enableFormEdit = !enableFormEdit"
      icon="el-icon-edit">Editar</el-button>
    <el-button v-if="!enableFormEdit" type="success" @click="alterarVenda" icon="el-icon-check">Salvar</el-button>
    <!-- INICIO DADOS DO CLIENTE -->

    <div style="text-align: left">
      <v-row style="margin-top: 20px">
        <v-col cols="3" sm="3">
          <v-select :items="$ListarStatus()" v-model="form.FkTbVendaStatus" label="Status Din"
            item-value="IdTbVendaStatus" item-text="DsNome" required outlined dense :disabled="enableFormEdit"></v-select>
        </v-col>
        <v-col cols="3" sm="3">
          <v-select :items="$ListarStatusNetSales()" v-model="form.FkTbVendaStatusNetSales" label="Status NetSales"
            item-value="IdTbVendaStatusNetSales" item-text="DsNome" required outlined dense
            :disabled="enableFormEdit"></v-select>
        </v-col>
        <v-col cols="2" sm="2" style="margin-top: -20px; text-align: left">
          <label>Data de agendamento:</label>
          <br />
          <div class="block">
            <el-date-picker :disabled="enableFormEdit" v-model="form.DtAgendamento" type="date" format="dd/MM/yyyy"
              style="width: 100%" placeholder="Data de agendamento"></el-date-picker>
          </div>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -20px; text-align: left">
          <label>Data de instalação:</label>
          <br />
          <div class="block">
            <el-date-picker :disabled="enableFormEdit" v-model="form.DtInstalacao" type="date" format="dd/MM/yyyy"
              style="width: 100%" placeholder="Data de instalação"></el-date-picker>
          </div>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -20px; text-align: left">
          <label>Data de desconexão:</label>
          <br />
          <div class="block">
            <el-date-picker :disabled="enableFormEdit" v-model="form.DtDesconexao" type="date" format="dd/MM/yyyy"
              style="width: 100%" placeholder="Data de instalação"></el-date-picker>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="2" style="margin-top: -30px">
          <v-select :disabled="enableFormEdit" :items="$ListarPeriodo()" v-model="form.FkTbPeriodo" label="Período"
            item-value="IdTbPeriodo" item-text="DsNome" required outlined dense></v-select>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -30px">
          <v-text-field :disabled="enableFormEdit" v-model="form.NrProposta" type="text" label="Nr. Proposta" required
            outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" sm="2" style="margin-top: -30px">
          <v-text-field :disabled="enableFormEdit" v-model="form.NrContrato" type="text" label="Nr. Contrato" required
            outlined dense></v-text-field>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -30px; margin-bottom: 30px">
          <v-select :disabled="enableFormEdit" :items="$ListarMarcadores()" v-model="form.FkTbVendaMarcadores"
            item-value="IdTbVendaMarcadores" item-text="DsNome" label="Marcador" outlined dense></v-select>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -30px; margin-bottom: 30px">
          <v-select :disabled="enableFormEdit" :items="$ListarEmpresas()" v-model="form.FkTbEmpresaAtribuida"
            item-value="IdTbEmpresa" item-text="DsNomeFantasia" label="Empresa Atribuida" outlined dense></v-select>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -30px; margin-bottom: 30px">
          <v-select :disabled="enableFormEdit" :items="$ListarEmpresas()" v-model="form.FkTbEmpresa"
            item-value="IdTbEmpresa" item-text="DsNomeFantasia" label="Empresa venda" outlined dense></v-select>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -60px; margin-bottom: 30px">
          <label>Filial:</label>
          <br />
          <el-select :disabled="enableFormEdit" style="width: 100%" v-model="form.FkTbFilial" placeholder="Filial">
            <el-option v-for="item in $ListarFilialPorEmpresa(form.FkTbEmpresa)" :key="item.IdTbFilial"
              :label="item.DsSigla" :value="item.IdTbFilial">
              <span style="float: left">{{ item.DsSigla }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.DsCidade
              }}</span>
            </el-option>
          </el-select>
        </v-col>

        <v-col cols="2" sm="2" style="margin-top: -60px; text-align: left">
          <label>Valor da venda:</label>
          <br />
          <div class="block">
            <v-text-field v-model="form.VlVenda" type="text" label="Valor" required outlined dense :disabled="enableFormEdit" v-if="enableFormEdit"></v-text-field>
            <v-text-field v-model.lazy="form.VlVenda" v-money="money" type="text" label="Valor" required outlined dense v-else></v-text-field>
          </div>
        </v-col>
        

        <v-col cols="12" sm="12" style="margin-top: -30px; margin-bottom: 30px">
          <el-select style="width: 100%" :disabled="enableFormEdit" v-model="form.FkTbUsuarioVenda" filterable remote
            reserve-keyword placeholder="Consultor" :remote-method="buscarUsuarios">
            <el-option v-for="item in listarUsuarios" :key="item.IdTbUsuario" :label="item.DsNome"
              :value="item.IdTbUsuario">
            </el-option>
          </el-select>
        </v-col>

        <br />
        <v-col cols="12" sm="12">
          <v-textarea :disabled="enableFormEdit" outlined v-model="form.DsObservacao" name="input-7-4"
            label="Observação"></v-textarea>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      enableFormEdit: true,
      listarUsuarios: [],
      formbuscaUsuario: {
        DsBusca: "",
        MdFiltroData: {},
        MdPaginacao: {
          PaginaAtual: 1,
          ItensPorPagina: 0,
          TotalPaginas: 0,
          TotalItens: 0,
        },
        MdModel: {
          BtAtivo: true,
        },
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "", //R$ mask to left
        suffix: "", //#$ mask to rigth
        precision: 2,
        masked: true
      },
      form: {
        NrContrato: null,
        NrProposta: null,
        DtDesconexao: null,
        DtInstalacao: null,
        DtAgendamento: null,
        FkTbEmpresa: null,
        FkTbEmpresaAtribuida: null,
        FkTbFilial: null,
        FkTbUsuarioVenda: null,
        FkTbVendaStatusNetSales: null,
        FkTbVendaMarcadores: null,
        FkTbPeriodo: 0,
        VlVenda: 0,
        IdTbVenda: 0,
        FkTbVendaStatus: 0,
        DsObservacao: "",
        BtAtivo: true,
      },
    };
  },
  props: {
    TbVenda: { type: Object },
  },
  methods: {
    buscarUsuarios(query) {
      this.formbuscaUsuario.MdPaginacao.ItensPorPagina = 999999;
      if (query) {
        this.formbuscaUsuario.DsBusca = query;
      }
      api
        .post("usuario/listar", this.formbuscaUsuario, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.listarUsuarios = response.data.TbUsuario;
          }.bind(this)
        )
        .catch(() => { });
    },
    alterarVenda() {
      this.enableFormEdit = !this.enableFormEdit;
      this.form.NrContrato = parseInt(this.form.NrContrato);
      this.form.NrProposta = parseInt(this.form.NrProposta);
      this.form.VlVenda = parseFloat(this.form.VlVenda.replace(/\./g, "").replace(",", "."));
      if (this.form.FkTbUsuarioVenda == null) {
        this.form.FkTbUsuarioVenda = this.listarUsuarios[0].IdTbUsuario;
      } else {
        this.form.FkTbUsuarioVenda = parseInt(this.form.FkTbUsuarioVenda);
      }

      api
        .put("venda/atualizar", this.form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.$GetToken(),
          },
        })
        .then(
          function (response) {
            this.$notify({
              type: "success",
              duration: 1000,
              speed: 1000,
              group: "venda",
              title:
                '<p style="font-size: 14pt">' +
                this.$store.state.aplicationName +
                "</p>",
              text:
                '<p style="font-size: 12pt">' + response.data.message + "</p>",
            });
            this.$emit("REFRESH");
          }.bind(this)
        )
        .catch((error) => {
          this.$notify({
            type: "error",
            duration: 1000,
            speed: 1000,
            group: "venda",
            title:
              '<p style="font-size: 14pt">' +
              this.$store.state.aplicationName +
              "</p>",
            text:
              '<p style="font-size: 12pt">' +
              error.response.data.message +
              "</p>",
          });
        });
    },
  },
  mounted() {
    this.form.IdTbVenda = this.TbVenda.IdTbVenda;
    this.form.NrContrato = this.TbVenda.NrContrato;
    this.form.NrProposta = this.TbVenda.NrProposta;
    this.form.FkTbVendaStatus = this.TbVenda.FkTbVendaStatus;
    this.form.FkTbVendaStatusNetSales = this.TbVenda.FkTbVendaStatusNetSales;
    this.form.FkTbVendaMarcadores = this.TbVenda.FkTbVendaMarcadores;
    this.form.DtInstalacao = this.TbVenda.DtInstalacao;
    this.form.DtAgendamento = this.TbVenda.DtPrevistaInstalacao;
    this.form.DtDesconexao = this.TbVenda.DtDesconexao;
    this.form.FkTbPeriodo = this.TbVenda.FkTbPeriodo;
    this.form.FkTbEmpresa = this.TbVenda.FkTbEmpresa;
    if(this.TbVenda.FkTbEmpresaAtribuida){
      this.form.FkTbEmpresaAtribuida = this.TbVenda.FkTbEmpresaAtribuida;
    }
    else{
      this.form.FkTbEmpresaAtribuida = this.TbVenda.FkTbEmpresa;
    }
    this.form.FkTbFilial = this.TbVenda.FkTbFilial;
    this.form.VlVenda = this.TbVenda.VlVenda;
    console.log( this.TbVenda.VlVenda)
    this.form.FkTbUsuarioVenda = this.TbVenda.FkTbUsuarioVenda;
    this.formbuscaUsuario.DsBusca = "" + this.TbVenda.FkTbUsuarioVenda;
    this.buscarUsuarios();
  },
};
</script>

<style></style>